<template>
	<div class="mainTem">
		<!-- <el-form ref="form" :rules="formRules" :model="form" label-width="150px" style="min-width: 1100px;width: 100%;"
			v-loading="loading" :element-loading-text="$t('i18nn_1b4525c800280581')"> -->
			<el-card v-loading="loading">
				<div slot="header">
					<h3>
						<!-- 退货管理 -->
						<TipsActionHotTable></TipsActionHotTable>
					</h3>
				</div>
				<div>
					<el-form ref="form" inline label-width="100px">
						<el-form-item :label="$t('i18nn_b4296642bcc9f428')" prop="" required>
							<whNoSelect size="" @changeData="changWhNo"></whNoSelect>
						</el-form-item>
						
						<el-form-item :label="$t('Storage.tableColumn.remark')">
							<el-input type="textarea" :placeholder="$t('FormMsg.Please_Enter')" v-model="form.remark"  :maxlength="1000" show-word-limit  style="width: 500px"></el-input>
						</el-form-item>
					</el-form>
					
					<!-- <div style="margin-bottom: 10px;">
						<span><i class="red">*</i><span>{{$t('i18nn_b4296642bcc9f428')}}</span>：</span>
						<whNoSelect @changeData="changWhNo"></whNoSelect>
					</div> -->

					<!-- <el-form-item :label="$t('i18nn_7431e76029678ec7')" prop="">
						<el-input type="text" v-model="form.title" :placeholder="$t('FormMsg.Please_Enter')" style="width: 300px;"></el-input>
					</el-form-item> -->

					<!-- <el-form-item :label="$t('i18nn_3e358d140504ab46')" prop="detail" class="">
						<HyQuillEditor ref="myTextEditor" :value="form.contentStr" @editorChange="onEditorChange" class=""></HyQuillEditor>
					</el-form-item> -->


					<!-- <el-alert :title="$t('i18nn_41413cddfa61538c')" type="info" show-icon description="">
						<div style="font-size: 14px;line-height: 150%;">
							<div>1、可直接导入Excel；也可以复制粘贴Excel中数据；</div>
							<div>2、鼠标右键可添加、删除、编辑表格；</div>
							<div>3、保存时请删除空白行；双击可选择或选中数据；</div>
						</div>
					</el-alert> -->

					<!-- <ExchangeReturnImport ref="ExchangeReturnImport" @parsingSuccess="parsingSuccess" :excelDataDto="excelDataDto">
			</ExchangeReturnImport> -->

					<uploadExcelBtn :btnText="$t('i18nn_e2ef45e045968e11')" :openTime="openTimeUpExc"
						:readHttp="$urlConfig.WhExchangeReturnExcReader" :readParm="{whNo:form.whNo}"
						:templateUrl="'https://hytx-oss-wh.oss-cn-hongkong.aliyuncs.com/modelv2/returnModelV2.xlsx'"
						@uploadSuccess="uploadSuccess">
					</uploadExcelBtn>

					<ExchangeReturnImportHotTable ref="HotTable" :selectDicOption="{}">
					</ExchangeReturnImportHotTable>


				</div>

			</el-card>

			<!-- <el-card>
				<div slot="header"><h3>{{$t('i18nn_b9b850a26aa3fc61')}}</h3></div>
				<div>
					<el-button type="primary" size="small" icon="el-icon-plus" @click="addGoodsOrder">{{$t('i18nn_24c8f46012a25c89')}}</el-button>
					<el-table ref="multipleTable" :data="form.returnDto.recordList" stripe :border="true" style="width: 100%" size="small">
						<el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50" align="center" :label="$t('Storage.tableColumn.no')"></el-table-column>

						<el-table-column prop="goodsSku" :label="'商品SKU(必填)'" width="300">
							<template slot-scope="scope">
								<el-button size="mini" type="primary" icon="el-icon-link" @click="openSelData($event, scope.row, scope.$index)">选择SKU</el-button>
								<el-input type="text" size="small" v-model="scope.row.goodsSku"></el-input>
							</template>
						</el-table-column>
						<el-table-column prop="quantity" :label="'件数(必填)'" width="160">
							<template slot-scope="scope">
								<el-input-number type="text" size="small" controls-position="right" v-model="scope.row.quantity"></el-input-number>
							</template>
						</el-table-column>

						<el-table-column prop="tracking" :label="'物流单号(非必填)'">
							<template slot-scope="scope">
								<el-input type="textarea" size="small" v-model="scope.row.tracking"></el-input>
							</template>
						</el-table-column>

						<el-table-column prop="relationOrderNo" :label="'关联单号(非必填)'">
							<template slot-scope="scope">
								<el-input type="textarea" size="small" v-model="scope.row.relationOrderNo"></el-input>
							</template>
						</el-table-column>

						<el-table-column prop="remark" :label="$t('i18nn_15b3627faddccb1d')" show-overflow-tooltip>
							<template slot-scope="scope">
								<el-input type="textarea" size="small" v-model="scope.row.remark"></el-input>
							</template>
						</el-table-column>

						<el-table-column :label="$t('Storage.tableColumn.operation')" width="120px" fixed="right" align="left">
							<template slot-scope="scope">
								<div>
									<el-button @click="delAction($event, scope.row, scope.$index)" type="danger" size="mini" icon="el-icon-minus">{{ $t('FormMsg.Delete') }}</el-button>
								</div>
							</template>
						</el-table-column>
					</el-table>
				</div>
			</el-card> -->

			<!-- <el-card>
				<div slot="header">
					<span>{{ $t('i18nn_8200a25ab641ba97') }}</span>
				</div>
				<div v-if="hasEdit">
					<HyElWhUpLoad
						ref="HyElWhUpLoad"
						:auto_upload="true"
						:listType="''"
						:watchKey="fileWatchKey"
						:fileListShow="fileList"
						:fileId="fileId"
						:folder="'storage/attachment/'"
						:fileKey="'workOrder'"
						:bucket="'hytx-oss-wh'"
						:accept="'all'"
						:maxSizeMB="50"
						:limit="10"
						v-on:UploadSuccessUrl="changeFileUrl"
					></HyElWhUpLoad>
				</div>
				<div v-else>
					<el-table ref="multipleTable" :data="fileList" stripe :border="true" style="width: 100%" size="small">
					  <el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50" align="center" :label="$t('Storage.tableColumn.no')"></el-table-column>
					  
						<el-table-column prop="name" :label="$t('i18nn_1aa9b398f37906a4')"></el-table-column>
						
					  <el-table-column prop="url" :label="$t('i18nn_d54012286fece209')">
					    <template slot-scope="scope">
					      <a :href="scope.row.url" target="_blank">{{ scope.row.url }}</a>
					    </template>
					  </el-table-column>
					</el-table>
				</div>
			</el-card> -->

			<!-- <el-card>
				<div slot="header">
					<h3>{{ $t('Storage.packing_list.Other') }}</h3>
				</div>
				<div>
					<el-form-item :label="$t('Storage.tableColumn.remark')">
						<el-input type="textarea" :placeholder="$t('FormMsg.Please_Enter')" v-model="form.remark" maxlength="" show-word-limit></el-input>
					</el-form-item>
				</div>
			</el-card> -->

		<!-- </el-form> -->

		<!-- <div style="margin: 10px;" v-if="hasEdit">
			<el-button type="warning" @click="submitForm('form')" style="width: 200px;">{{$t('i18nn_d552aa9f1c14363f')}}
			</el-button>
			<span class="red">编辑保存时，显示数据仅供参考，建议重新上传模板</span>
		</div> -->
		<div  class="submit-footer">
			<el-button type="warning" icon="el-icon-tickets" @click="submitForm()" style="width: 200px;">{{ $t('FormMsg.Save') }}
			</el-button>
		</div>

		<!--选择sku-->
		<!-- <el-dialog :title="$t('FormMsg.Select')" append-to-body :close-on-click-modal="false" :visible.sync="dialogSelVisible" width="1200px" top="0">
			<div style=""><WSkuInfo :isSel="true" :status="'10'" @selectRow="selWhGoodsData"></WSkuInfo></div>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" plain @click="dialogSelVisible = false">{{ $t('FormMsg.Close') }}</el-button>
			</div>
		</el-dialog> -->
	</div>
</template>
<script>
	// import ExchangeReturnImport from '@/components/StorageCenter/workOrder/workOrderTem/ExchangeReturnImport.vue';
	import TipsActionHotTable from '@/components/StorageCenter/workOrder/TipsActionHotTable.vue';
	import uploadExcelBtn from '@/components/StorageCenter/components/uploadExcelBtn.vue';
	import ExchangeReturnImportHotTable from '@/components/StorageCenter/workOrder/workOrderTem/ExchangeReturnImportHotTable.vue';

	// import HyQuillEditor from '@/components/Common/HyQuillEditor.vue';
	// import HyElWhUpLoad from '@/components/Common/HyElWhUpLoad.vue';
	// import WSkuInfo from '@/components/StorageCenter/WSkuProduct/WSkuInfo.vue';
	import whNoSelect from '@/components/StorageCenter/components/whNoSelect.vue';
	export default {
		// name: 'BuyerOrder',
		//meta信息seo用
		// metaInfo: {
		//   title: '互易天下-厂家共享平台-买家中心-收货账单', // set a title
		// },

		// props: {
		//   // mobile:"",
		//   isSel: {
		//     default: function() {
		//       return false;
		//     },
		//     type: Boolean
		//   },
		//   status: {
		//     default: function() {
		//       return '';
		//     },
		//     type: String
		//   }
		// },
		components: {
			TipsActionHotTable,
			uploadExcelBtn,
			ExchangeReturnImportHotTable,
			// WSkuInfo,
			whNoSelect
		},
		data() {
			return {
				// UserInfo: this.$store.getters.getUserInfo,
				// dialogFormVisible: false,
				dialogFormStatus: 0, //0-新增，1-修改

				loading: false,

				loading_det: false,
				//是否可编辑
				hasEdit: false,

				// excelData: [],
				// excelFileName: '',

				openTimeUpExc: "",

				// excelDataDto: [],
				//附件
				// fileWatchKey: '1',
				// fileList: [],
				// fileId: this.$store.getters.getUserInfo.id,
				//打开SKU选择框
				// dialogSelVisible: false,
				// openRowData: {},
				// openRowIndex: {},

				form: {
					// id: '', //"数据ID",
					whNo: '',
					remark:""
				},

				// form: {
				// 	id: '', //"数据ID",
				// 	title: '', //this.$t('i18nn_7431e76029678ec7'),
				// 	// orderType: '50', //this.$t('i18nn_f566f50ef680920c'),
				// 	remark: '', //
				// 	recordList: [
				// 		{
				// 			// id: '', //"数据ID",
				// 			goodsSku: '', //this.$t('i18nn_74ed28f373ea14da'),
				// 			quantity: '1', //this.$t('i18nn_3775920d44088b96'),
				// 			relationOrderNo: '', //this.$t('i18nn_158db9252e21d1a6'),
				// 			tracking: '', //this.$t('i18nn_4531476fa35570f0'),
				// 			remark: '' //this.$t('i18nn_15b3627faddccb1d')
				// 		}
				// 	],
				// 	serviceList: [
				// 		// {
				// 		// 	// id: '', //"数据ID",
				// 		// 	serviceParentType: '', //this.$t('i18nn_2e5e43583197a181'),
				// 		// 	serviceType: '', //this.$t('i18nn_bf371176126571cf'),
				// 		// 	remark: '' //this.$t('i18nn_15b3627faddccb1d')
				// 		// }
				// 	]
				// 	// contentStr: '', //this.$t('i18nn_3e358d140504ab46'),
				// 	// attachments: [
				// 	// 	{
				// 	// 		id: '', //"数据ID",
				// 	// 		url: '', //"文件URL",
				// 	// 		fileName: '' //this.$t('i18nn_89221ad15d2ec113')
				// 	// 	}
				// 	// ]
				// },

				formRules: {
					// title: [{
					// 	required: true,
					// 	message: this.$t('FormMsg.Please_Enter'),
					// 	trigger: 'blur'
					// }],
					// contentStr: [{
					// 	required: true,
					// 	message: this.$t('FormMsg.Please_Enter'),
					// 	trigger: 'blur'
					// }],
					// orderType: [{
					// 	required: true,
					// 	message: this.$t('FormMsg.Please_Enter'),
					// 	trigger: 'change'
					// }]
				},
				// loading_load: false,

				selectOption: {
					wh_work_order_type: [],
					// wh_no: []
				}
			};
		},

		//创建时
		created() {
			// this.getPageData();
			this.initData();
		},
		//编译挂载前
		mounted() {
			// this.getDicData();
		},
		methods: {
			initData() {
				this.initExcel();
				
				// if (this.$route.query.id) {
				// 	//编辑
				// 	this.hasEdit = true;
				// 	this.getPageDetData(this.$route.query.id);
				// } else {
				// 	//新增
				// 	this.hasEdit = false;
				// }
			},
			changWhNo(data) {
				console.log('changWhNo', data);
				this.form.whNo = data.code;
				// this.filterData.userId = data.userId;
				// this.initData();
				this.initExcel();
			},
			// parsingSuccess(data) {
			// 	// this.form.packingExcelDtos = data;
			// },
			//上传数据回调
			uploadSuccess(data) {
				this.$nextTick(() => {
					// this.excelData = data;
					this.$refs.HotTable.loadData(data);
					// this.$emit('parsingSuccess', this.excelData);
				});
			},
			// changeAgentUser(val){
			// 	// this.filterData.agentUser = val;
			// 	// this.initData();
			// },
			//跳转页面
			// toPageUrl(name){
			//   this.$router.push({'name':name});
			// },
			//打开新增编辑，弹窗
			// openDioalog(formParm, msg) {
			// 	// console.log(formParm);
			// 	this.dialogFormVisible = true;
			// 	let form = Object.assign({}, formParm);
			// 	console.log('form', form);
			// 	this.dialogFormMsg = msg;
			// 	// // 重置
			// 	this.resetForm('form');
			// 	if (null === formParm) {
			// 		//新增

			// 		// this.form.weightUnit = '1';
			// 		// this.form.volumeUnit = '1';
			// 		// this.form.whFeeType = '1';

			// 		this.dialogFormStatus = 0;
			// 		// form.subUserId = null;
			// 		// form.userSubUserId = null;
			// 		// form.state = true;
			// 		//浅拷贝、对象属性的合并
			// 		this.form = form;
			// 	} else {
			// 		//修改
			// 		this.dialogFormStatus = 1;
			// 		// form.state = form.state === '0' ? true : false;

			// 		//浅拷贝、对象属性的合并
			// 		this.form = form;
			// 	}

			// 	this.$forceUpdate();
			// 	// this.$alert('确定要确认通过该笔账单！', this.$t('tips.tipsTitle'), {
			// 	//       type: 'warning',
			// 	//       //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			// 	//     });
			// },
			// 富文本
			// onEditorChange(html) {
			// 	this.form.contentStr = html;
			// },
			// addGoodsOrder() {
			// 	// event.stopPropagation();
			// 	this.form.returnDto.recordList.push({
			// 		// id: '', //"数据ID",
			// 		goodsSku: '', //this.$t('i18nn_74ed28f373ea14da'),
			// 		quantity: 1, //this.$t('i18nn_3775920d44088b96'),
			// 		relationOrderNo: '', //this.$t('i18nn_158db9252e21d1a6'),
			// 		tracking: '', //this.$t('i18nn_4531476fa35570f0'),
			// 		remark: '' //this.$t('i18nn_15b3627faddccb1d')
			// 	});
			// },
			// delAction(event, row, index) {
			// 	event.stopPropagation();
			// 	this.form.returnDto.recordList.splice(index, 1);
			// },

			//打开选择数据
			// openSelData(event, row, index) {
			// 	event.stopPropagation();

			// 	this.dialogSelVisible = true;
			// 	this.openRowData = row;
			// 	this.openRowIndex = index;
			//  // this.$nextTick(() => {
			// 	//   this.$refs.hyUpLoadImg1.openFile();
			// 	// });
			// },
			//选择数据后回调
			// selWhGoodsData(selRow) {
			// 	this.dialogSelVisible = false;
			// 	console.log('selRow', selRow);
			// 	let row = this.openRowData;
			// 	// row.putWhNo = selRow.putWhNo;
			// 	// row.plPlaceNo = selRow.placeNo;
			// 	row.goodsSku = selRow.goodsSku;
			// 	// row.sendAcount = 0;
			// 	// row.mySkuDto = selRow;

			// 	this.$set(this.form.returnDto.recordList, this.openRowIndex, row);
			// },

			//打开编辑
			// openEdit(event, row, index) {
			// 	event.stopPropagation();
			// 	this.openDioalog(row, this.$t('FormMsg.Edit'));
			// 	this.getPageDetData(row.id);
			// },
			//明细
			//请求分页数据
			// getPageDetData(id) {
			// 	// let _this = this;
			// 	this.loading = true;

			// 	this.$http
			// 		.get(this.$urlConfig.WhWorkOrderEditDet + '/' + id, {})
			// 		.then(({
			// 			data
			// 		}) => {
			// 			// console.log(this.$store.getters.getUserInfo);
			// 			console.log('分页，请求成功');
			// 			console.log(data);
			// 			
			// 			this.loading = false;
			// 			//是否可编辑
			// 			// if ('0' == data.data.status) {
			// 			// 	this.hasEdit = true;
			// 			// }
			// 			//表格显示数据
			// 			this.form = data.data;

			// 			//表格显示数据
			// 			this.form.id = data.data.id;
			// 			this.form.whNo = data.data.whNo;

			// 			// this.fileList = data.data.attachments.map(item => {
			// 			// 	return {
			// 			// 		id: item.id,
			// 			// 		name: item.fileName,
			// 			// 		url: item.url
			// 			// 	};
			// 			// });
			// 			// this.fileWatchKey = new Date().getTime();

			// 			// this.form.returnDto = data.data.returnDto;

			// 			this.excelData = data.data.returnDto;

			// 			// this.fileList = data.data.attachments.map(item => {
			// 			// 	return {
			// 			// 		id: item.id,
			// 			// 		name: item.fileName,
			// 			// 		url: item.url
			// 			// 	};
			// 			// });
			// 			// this.fileWatchKey = new Date().getTime();
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('分页，请求失败');
			// 			this.loading = false;
			// 		});
			// },

			//上传回调
			// changeFileUrl(val) {
			// 	console.log('changeFileUrl', val);
			// 	let listUrl = val.map(item => {
			// 		// "filePath":"","fileName"：“”
			// 		return {
			// 			url: item.url,
			// 			fileName: item.name
			// 		};
			// 	});

			// 	this.form.attachments = listUrl;
			// },
			//打开导入弹窗
			initExcel() {
				// this.dialogUploadVisible = true;
				this.openTimeUpExc = new Date().getTime();
				// this.excelData = [];
				
				this.$nextTick(() => {
					this.$refs.HotTable.clear();
				});
				// this.clearFile();
			},
			//提交信息
			submitForm() {
				// this.$nextTick(() => {
				// 	try {
				// 		this.$refs.HyElWhUpLoad.submitUpload();
				// 	} catch (err) {
				// 		console.log(err);
				// 	}
				// });

				// this.$refs[formName].validate(valid => {
				// if (valid) {
				// let formData = Object.assign({}, this.form);
				this.$nextTick(() => {
					let excelData = this.$refs.HotTable.getData();
					let formData = excelData.map(item=>{
						item.whNo = this.form.whNo;
						return item;
					});
					//浅拷贝、对象属性的合并
					// if (formData.id = null) {
					// formData.id = null;
					// formData.userId = this.UserInfo.id;

					this.postData(this.$urlConfig.WhExchangeReturnExcSave, formData, () => {
						// this.$emit('submitSuccess');
						// this.$router.go(-1);
						this.$router.push({name:"WarehouseExchangeReturn"});
					});
				});
				// } else {
				// 	formData.userId = this.UserInfo.id;
				// 	this.postData(this.$urlConfig.WhWorkOrderAdd, formData);
				// }
				// } else {
				// 	console.log('error submit!!');
				// 	this.$alert(this.$t('tips.checkSubmitData'), this.$t('tips.tipsTitle'), {
				// 		type: 'warning'
				// 		//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
				// 	});
				// 	return false;
				// }

				// });
			},
			//重置输入框
			// resetForm(formName) {
			// 	console.log(formName);
			// 	// console.log(this.$refs[formName]);

			// 	this.$nextTick(() => {
			// 		if (this.$refs[formName]) {
			// 			this.$refs[formName].resetFields();
			// 			// if ('form' === formName) {
			// 			// 	this[formName]['packRecordList'] = [];
			// 			// }
			// 		} else {
			// 			console.log('this.$refs[formName]', this.$refs[formName]);
			// 		}
			// 	});
			// 	// this.$nextTick(() => {
			// 	// 	try {
			// 	// 		this.$refs.HyElWhUpLoad.initData();
			// 	// 		this.$refs.HyElWhUpLoad.clearFileList();
			// 	// 	} catch (err) {
			// 	// 		console.log(err);
			// 	// 	}
			// 	// });
			// 	// this.fileList = [];
			// },

			//提交信息
			postData(url, formData, callback) {
				// HttpTypelet _this = this;
				this.loading = true;
				// this.loading_load = true;
				let HttpType = {};
				// if ('delete' == type) {
				// 	HttpType = this.$http.delete(url, formData);
				// } else {
				HttpType = this.$http.put(url, formData);
				// }
				HttpType.then(({
					data
				}) => {
					console.log(this.$t('i18nn_bc868e024b80d2e3'));
					console.log(data);
					
					this.loading = false;
					// this.loading_load = false;
					if (200 == data.code) {
						// this.dialogFormVisible = false;
						// this.getPageData();

						// if ('delete' == type) {
						// 	this.$message({
						// 		type: 'success',
						// 		message: this.$t('tips.successfullyDelete')
						// 	});
						// } else {
						this.$alert(this.$t('tips.submitSuccess'), this.$t('tips.tipsTitle'), {
							type: 'success'
						});
						callback();
						// }
					} else {
						// if (!data.msg) {
						//   data.msg = this.$t('tips.submitError');
						// }
						this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
							type: 'warning'
							//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
						});
					}
				}).catch(error => {
					console.log(error);
					console.log(this.$t('tips.submitError'));
					this.loading = false;
					// this.loading_load = false;
					this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
						type: 'warning'
						//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
					});
				});
			},

			//查询数据字典
			// getDicData() {
			// 	// this.loading_load = true;
			// 	this.$http
			// 		.put(this.$urlConfig.HyDicQueryList, ['wh_no'])
			// 		.then(({ data }) => {
			// 			console.log('查询数据字典，请求成功');
			// 			console.log(data);
			// 			if (200 == data.code && data.data) {
			// 				// this.selectOption.wh_weight_unit = data.data['wh_weight_unit'];
			// 				// this.selectOption.wh_vol_unit = data.data['wh_vol_unit'];
			// 				// this.selectOption.wh_goods_fee_type = data.data['wh_goods_fee_type'];
			// 				this.selectOption.wh_no = data.data['wh_no'];
			// 				// this.selectOption.wh_packing_type = data.data['wh_packing_type'];
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = this.$t('tips.submitError');
			// 				}
			// 				this.$message.warning(data.msg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('查询数据字典接口，请求失败');
			// 			this.$message.error(this.$t('tips.submitRequestError'));
			// 		});
			// }
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less"></style>
