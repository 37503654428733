export default {
	data:[
		{
			stateCN:"亚拉巴马",
			stateUS:"Alabama",
			stateCode:"AL",
			statePostCode:""
		},
		{
			stateCN:"阿拉斯加",
			stateUS:"Alaska",
			stateCode:"AK",
			statePostCode:""
		},
		{
			stateCN:"亚利桑那",
			stateUS:"Arizona",
			stateCode:"AZ",
			statePostCode:""
		},
		{
			stateCN:"阿肯色",
			stateUS:"Arkansas",
			stateCode:"AR",
			statePostCode:""
		},
		{
			stateCN:"加利福尼亚",
			stateUS:"California",
			stateCode:"CA",
			statePostCode:""
		},
		{
			stateCN:"科罗拉多",
			stateUS:"Colorado",
			stateCode:"CO",
			statePostCode:""
		},
		{
			stateCN:"康涅狄格",
			stateUS:"Connecticut",
			stateCode:"CT",
			statePostCode:""
		},
		{
			stateCN:"特拉华",
			stateUS:"Delaware",
			stateCode:"DE",
			statePostCode:""
		},
		{
			stateCN:"哥伦比亚特区",
			stateUS:"District of Columbia",
			stateCode:"DC",
			statePostCode:""
		},
		{
			stateCN:"佛罗里达",
			stateUS:"Florida",
			stateCode:"FL",
			statePostCode:""
		},
		{
			stateCN:"乔治亚",
			stateUS:"Georgia",
			stateCode:"GA",
			statePostCode:""
		},
		
		{
			stateCN:"夏威夷",
			stateUS:"Hawaii",
			stateCode:"HI",
			statePostCode:""
		},{
			stateCN:"爱达荷",
			stateUS:"Idaho",
			stateCode:"ID",
			statePostCode:""
		},
		{
			stateCN:"伊利诺伊",
			stateUS:"Illinois",
			stateCode:"IL",
			statePostCode:""
		},
		{
			stateCN:"印第安纳",
			stateUS:"Indiana",
			stateCode:"IN",
			statePostCode:""
		},
		{
			stateCN:"艾奥瓦",
			stateUS:"Iowa",
			stateCode:"IA",
			statePostCode:""
		},
		{
			stateCN:"堪萨斯",
			stateUS:"Kansas",
			stateCode:"KS",
			statePostCode:""
		},
		{
			stateCN:"肯塔基",
			stateUS:"Kentucky",
			stateCode:"KY",
			statePostCode:""
		},
		{
			stateCN:"路易斯安那",
			stateUS:"Louisiana",
			stateCode:"LA",
			statePostCode:""
		},
		{
			stateCN:"缅因",
			stateUS:"Maine",
			stateCode:"ME",
			statePostCode:""
		},
		{
			stateCN:"马里兰",
			stateUS:"Maryland",
			stateCode:"MD",
			statePostCode:""
		},
		{
			stateCN:"马萨诸塞",
			stateUS:"Massachusetts",
			stateCode:"MA",
			statePostCode:""
		},
		{
			stateCN:"密歇根",
			stateUS:"Michigan",
			stateCode:"MI",
			statePostCode:""
		},
		{
			stateCN:"明尼苏达",
			stateUS:"Minnesota",
			stateCode:"MN",
			statePostCode:""
		},
		{
			stateCN:"密西西比",
			stateUS:"Mississippi",
			stateCode:"MS",
			statePostCode:""
		},
		{
			stateCN:"密苏里",
			stateUS:"Missouri",
			stateCode:"MO",
			statePostCode:""
		},
		{
			stateCN:"蒙大拿",
			stateUS:"Montana",
			stateCode:"MT",
			statePostCode:""
		},
		{
			stateCN:"内布拉斯加",
			stateUS:"Nebraska",
			stateCode:"NE",
			statePostCode:""
		},
		{
			stateCN:"内华达",
			stateUS:"Nevada",
			stateCode:"NV",
			statePostCode:""
		},
		{
			stateCN:"新罕布什尔",
			stateUS:"New Hampshire",
			stateCode:"NH",
			statePostCode:""
		},
		{
			stateCN:"新泽西",
			stateUS:"New Jersey",
			stateCode:"NJ",
			statePostCode:""
		},
		{
			stateCN:"新墨西哥",
			stateUS:"New Mexico",
			stateCode:"NM",
			statePostCode:""
		},
		{
			stateCN:"纽约",
			stateUS:"New York",
			stateCode:"NY",
			statePostCode:""
		},
		{
			stateCN:"北卡罗来纳",
			stateUS:"North Carolina",
			stateCode:"NC",
			statePostCode:""
		},
		{
			stateCN:"北达科他",
			stateUS:"North Dakota",
			stateCode:"ND",
			statePostCode:""
		},
		{
			stateCN:"俄亥俄",
			stateUS:"Ohio",
			stateCode:"OH",
			statePostCode:""
		},
		{
			stateCN:"俄克拉荷马",
			stateUS:"Oklahoma",
			stateCode:"OK",
			statePostCode:""
		},
		{
			stateCN:"俄勒冈州",
			stateUS:"Oregon",
			stateCode:"OR",
			statePostCode:""
		},
		{
			stateCN:"宾夕法尼亚",
			stateUS:"Pennsylvania",
			stateCode:"PA",
			statePostCode:""
		},
		{
			stateCN:"罗得岛",
			stateUS:"Rhode Island",
			stateCode:"RI",
			statePostCode:""
		},
		{
			stateCN:"南卡罗来纳",
			stateUS:"South Carolina",
			stateCode:"SC",
			statePostCode:""
		},
		{
			stateCN:"南达科他",
			stateUS:"South Dakota",
			stateCode:"SD",
			statePostCode:""
		},
		{
			stateCN:"田纳西",
			stateUS:"Tennessee",
			stateCode:"TN",
			statePostCode:""
		},
		{
			stateCN:"得克萨斯",
			stateUS:"Texas",
			stateCode:"TX",
			statePostCode:""
		},
		{
			stateCN:"犹他",
			stateUS:"Utah",
			stateCode:"UT",
			statePostCode:""
		},
		{
			stateCN:"佛蒙特",
			stateUS:"Vermont",
			stateCode:"VT",
			statePostCode:""
		},
		{
			stateCN:"弗吉尼亚",
			stateUS:"Virginia",
			stateCode:"VA",
			statePostCode:""
		},
		{
			stateCN:"华盛顿",
			stateUS:"Washington",
			stateCode:"WA",
			statePostCode:""
		},
		{
			stateCN:"西弗吉尼亚",
			stateUS:"West Virginia",
			stateCode:"WV",
			statePostCode:""
		},
		{
			stateCN:"威斯康星",
			stateUS:"Wisconsin",
			stateCode:"WI",
			statePostCode:""
		},
		{
			stateCN:"怀俄明",
			stateUS:"Wyoming",
			stateCode:"WY",
			statePostCode:""
		},
		{
			stateCN:"美属萨摩亚",
			stateUS:"American Samoa",
			stateCode:"AS",
			statePostCode:""
		},
		{
			stateCN:"关岛",
			stateUS:"Guam",
			stateCode:"GU",
			statePostCode:""
		},
		{
			stateCN:"北马里亚纳群岛",
			stateUS:"Northern Mariana Islands",
			stateCode:"MP",
			statePostCode:""
		},
		{
			stateCN:"波多黎各",
			stateUS:"Puerto Rico",
			stateCode:"PR",
			statePostCode:""
		},
		{
			stateCN:"维尔京群岛",
			stateUS:"U.S. Virgin Islands",
			stateCode:"VI",
			statePostCode:""
		},
		{
			stateCN:"美国本土外小岛屿",
			stateUS:"U.S. Minor Outlying Islands",
			stateCode:"UM",
			statePostCode:""
		},
		{
			stateCN:"马绍尔群岛",
			stateUS:"Marshall Islands",
			stateCode:"MH",
			statePostCode:""
		},
		{
			stateCN:"密克罗尼西亚联邦",
			stateUS:"Micronesia",
			stateCode:"FM",
			statePostCode:""
		},
		
		{
			stateCN:"帕劳",
			stateUS:"Palau",
			stateCode:"PW",
			statePostCode:""
		},
	]
}