<template>
	<div class="mainTem">
		<el-form ref="form" inline :rules="formRules" :model="form" label-width="100px" style="min-width: 1100px;width: 100%;"
			v-loading="loading">

			<!-- <el-tabs type="border-card"> -->
			<!-- <el-tab-pane label="入库单-导入"> -->
			<el-card shadow="">
				<div slot="header">
					<h3>
						<TipsActionHotTable></TipsActionHotTable>
					</h3>
				</div>
				<div>
					<el-form-item :label="$t('i18nn_a0f2924544ff3ad8')" prop="" required>
						<!-- <el-select filterable clearable v-model="form.whNo" :placeholder="$t('FormMsg.Please_select')" style="width: 300px;">
									<el-option v-for="item in selectOption.wh_no" :key="item.code" :label="$Utils.i18nCodeText(item)" :value="item.code"><div class="sel_option_s1" style="">
				<span class="sel_option_name">{{ $Utils.i18nCodeText(item) }}</span>
				<span class="sel_option_code" style="">{{ item.code }}</span>
			</div></el-option>
								</el-select> -->
						<whNoSelect size="" @changeData="changWhNo"></whNoSelect>
					</el-form-item>
					<el-form-item prop="">
						<span slot="label">
							<span>{{$t('i18nn_fc554f12d8734681')}}</span><br />SKU&nbsp;<span>{{$t('i18nn_f085596674018f3e')}}</span>
						</span>
						<el-switch v-model="form.relationFlag" :active-value="'1'" :inactive-value="'0'"
							:active-text="$t('i18nn_02ccd2cf723f9272')" :inactive-text="$t('i18nn_e76d885ae1c74d4f')">
						</el-switch>
					</el-form-item>
					
					<el-form-item :label="$t('Storage.tableColumn.remark')">
						<el-input type="textarea" :placeholder="$t('FormMsg.Please_Enter')" :rows="2" v-model="form.remark"
							:maxlength="1000" show-word-limit style="width: 500px"></el-input>
					</el-form-item>
					
					<!-- </div> -->
					<!-- </el-card> -->
					<!-- <el-card> -->

					<!-- <el-tabs v-model="activeName" @tab-click="handleClick"> -->
					<!-- <el-tab-pane :label="$t('i18nn_c996cb376f253cb5')" name="first"> -->
					<!-- <span v-if="hasEdit" class="red">编辑时，显示数据仅供参考，建议重新上传模板</span> -->

					<!-- <el-alert :title="$t('i18nn_41413cddfa61538c')" type="info" show-icon description="">
								<div style="font-size: 14px;line-height: 150%;" >
									<div>1、可直接导入Excel；也可以复制粘贴Excel中数据；</div>
									<div>2、鼠标右键可添加、删除、编辑表格；</div>
									<div>3、保存时请删除空白行；双击可选择或选中数据；</div>
								</div>
							</el-alert> -->

					<PackingWarehousingImport ref="PackingWarehousingImport" @parsingSuccess="parsingSuccess"
						:excelDataDto="excelDataDto">
					</PackingWarehousingImport>
					<!-- </el-tab-pane> -->
					<!-- <el-tab-pane :label="$t('i18nn_a1ef1454c217a90d')" name="second">{{$t('i18nn_a1ef1454c217a90d')}}</el-tab-pane> -->

					<!-- </el-tabs> -->
				</div>

			</el-card>
			<!-- </el-tab-pane> -->
			<!-- <el-tab-pane :label="$t('i18nn_032abee95f72da18')">
					<el-card>
						<div slot="header">
							<span>{{ $t('Storage.packing_list.basic_information') }}</span>
						</div>
						<div>
							<el-form-item :label="$t('i18nn_7431e76029678ec7')" prop="">
								<el-input type="text" v-model="form.title" :placeholder="$t('FormMsg.Please_Enter')" style="width: 300px;"></el-input>
							</el-form-item>
					
							<el-form-item :label="$t('i18nn_3e358d140504ab46')" prop="detail" class="">
								<HyQuillEditor ref="myTextEditor" :value="form.contentStr" @editorChange="onEditorChange" class=""></HyQuillEditor>
							</el-form-item>
						</div>
					</el-card>
					
					<el-card>
						<div slot="header">
							<span>{{ $t('i18nn_8200a25ab641ba97') }}</span>
						</div>
						<div>
							<HyElWhUpLoad
								ref="HyElWhUpLoad"
								:auto_upload="true"
								:listType="''"
								:watchKey="fileWatchKey"
								:fileListShow="fileList"
								:fileId="fileId"
								:folder="'storage/attachment/'"
								:fileKey="'workOrder'"
								:bucket="'hytx-oss-wh'"
								:accept="'all'"
								:maxSizeMB="50"
								:limit="10"
								v-on:UploadSuccessUrl="changeFileUrl"
							></HyElWhUpLoad>
						</div>
					</el-card>
					
					<el-card>
						<div slot="header">
							<span>{{ $t('Storage.packing_list.Other') }}</span>
						</div>
						<div>
							<el-form-item :label="$t('Storage.tableColumn.remark')">
								<el-input type="textarea" :placeholder="$t('FormMsg.Please_Enter')" v-model="form.remark" maxlength="" show-word-limit></el-input>
							</el-form-item>
						</div>
					</el-card>
				</el-tab-pane> -->
			<!-- </el-tabs> -->


		</el-form>

		<!-- <div style="margin: 10px;" v-if="hasEdit"> -->
			<!-- <el-button type="warning" @click="submitForm('form')" style="width: 200px;">{{$t('i18nn_d552aa9f1c14363f')}}
			</el-button> -->
			<!-- <span class="red">编辑保存时，显示数据仅供参考，建议重新上传模板</span> -->
		<!-- </div> -->
		<div  class="submit-footer" >
			<el-button type="warning" icon="el-icon-tickets" @click="submitForm('form')" style="width: 200px;">{{$t('i18nn_e186babc2e9cadf4')}}</el-button>
		</div>
	</div>
</template>
<script>
	import PackingWarehousingImport from '@/components/StorageCenter/workOrder/workOrderTem/PackingWarehousingImport.vue';
	import TipsActionHotTable from '@/components/StorageCenter/workOrder/TipsActionHotTable.vue';
	
	// import HyQuillEditor from '@/components/Common/HyQuillEditor.vue';
	// import HyElWhUpLoad from '@/components/Common/HyElWhUpLoad.vue';
	import whNoSelect from '@/components/StorageCenter/components/whNoSelect.vue';
	export default {
		// name: 'BuyerOrder',
		//meta信息seo用
		// metaInfo: {
		//   title: '互易天下-厂家共享平台-买家中心-收货账单', // set a title
		// },

		// props: {
		//   // mobile:"",
		//   isSel: {
		//     default: function() {
		//       return false;
		//     },
		//     type: Boolean
		//   },
		//   status: {
		//     default: function() {
		//       return '';
		//     },
		//     type: String
		//   }
		// },
		components: {
			// HyElWhUpLoad,
			// HyQuillEditor,
			TipsActionHotTable,
			PackingWarehousingImport,
			whNoSelect
		},
		data() {
			return {

				// activeName: "first",
				// UserInfo: this.$store.getters.getUserInfo,
				// dialogFormVisible: false,
				dialogFormStatus: 0, //0-新增，1-修改

				loading: false,

				//是否可编辑
				hasEdit: false,
				// loading_det: false,

				//附件
				// fileWatchKey: '1',
				// fileList: [],
				// fileId: this.$store.getters.getUserInfo.id,

				excelDataDto: [],

				form: {
					id: '', //"数据ID",
					whNo: '',
					relationFlag:'0',
					remark:'',
					// title: '', //this.$t('i18nn_7431e76029678ec7'),
					// orderType: '60', //this.$t('i18nn_f566f50ef680920c'),
					// contentStr: '', //this.$t('i18nn_3e358d140504ab46'),
					// attachments: [
					// 	// {
					// 	// id: '', //"数据ID",
					// 	// url: '', //"文件URL",
					// 	// fileName: '' //this.$t('i18nn_89221ad15d2ec113')
					// 	// }
					// ],
					// packingExcelDtos:[]
				},

				formRules: {
					// title: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
					// contentStr: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
					// orderType: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'change' }]
				},
				// loading_load: false,

				selectOption: {
					wh_work_order_type: [],
					// wh_no: [],
				}
			};
		},

		//创建时
		created() {
			// this.getPageData();
			
		},
		//编译挂载前
		mounted() {
			// this.getDicData();
			this.initData();

		},
		methods: {
			initData() {
				if (this.$route.query.id) {
					//编辑
					this.hasEdit = true;
					// this.getPageDetData(this.$route.query.id);
				} else {
					//新增
					this.hasEdit = false;
				}
			},
			changWhNo(data) {
				console.log('changWhNo', data);
				this.form.whNo = data.code;
				// this.filterData.userId = data.userId;
				// this.initData();
			},
			parsingSuccess(data) {
				// this.form.packingExcelDtos = data;
			},
			// handleClick(tab, event) {
			//         console.log(tab, event);
			//       },
			// changeAgentUser(val){
			// 	// this.filterData.agentUser = val;
			// 	// this.initData();
			// },
			//跳转页面
			// toPageUrl(name){
			//   this.$router.push({'name':name});
			// },
			//打开新增编辑，弹窗
			// openDioalog(formParm, msg) {
			// 	// console.log(formParm);
			// 	this.dialogFormVisible = true;
			// 	let form = Object.assign({}, formParm);
			// 	console.log('form', form);
			// 	this.dialogFormMsg = msg;
			// 	// // 重置
			// 	this.resetForm('form');
			// 	if (null === formParm) {
			// 		//新增

			// 		// this.form.weightUnit = '1';
			// 		// this.form.volumeUnit = '1';
			// 		// this.form.whFeeType = '1';

			// 		this.dialogFormStatus = 0;
			// 		// form.subUserId = null;
			// 		// form.userSubUserId = null;
			// 		// form.state = true;
			// 		//浅拷贝、对象属性的合并
			// 		this.form = form;
			// 	} else {
			// 		//修改
			// 		this.dialogFormStatus = 1;
			// 		// form.state = form.state === '0' ? true : false;

			// 		//浅拷贝、对象属性的合并
			// 		this.form = form;
			// 	}

			// 	this.$forceUpdate();
			// 	// this.$alert('确定要确认通过该笔账单！', this.$t('tips.tipsTitle'), {
			// 	//       type: 'warning',
			// 	//       //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			// 	//     });
			// },
			// 富文本
			// onEditorChange(html) {
			// 	this.form.contentStr = html;
			// },
			//打开编辑
			// openEdit(event, row, index) {
			// 	event.stopPropagation();
			// 	this.openDioalog(row, this.$t('FormMsg.Edit'));
			// 	this.getPageDetData(row.id);
			// },
			//明细
			//请求分页数据
			// getPageDetData(id) {
			// 	// let _this = this;
			// 	this.loading = true;

			// 	this.$http
			// 		.get(this.$urlConfig.WhWorkOrderEditDet + '/' + id, {})
			// 		.then(({
			// 			data
			// 		}) => {
			// 			// console.log(this.$store.getters.getUserInfo);
			// 			console.log('分页，请求成功');
			// 			console.log(data);
			// 			
			// 			this.loading = false;
			// 			//是否可编辑
			// 			// if('0'==data.data.status){
			// 			// 	this.hasEdit = true;
			// 			// }
			// 			//表格显示数据
			// 			this.form.id = data.data.id;
			// 			this.form.whNo = data.data.whNo;

			// 			// this.fileList = data.data.attachments.map(item => {
			// 			// 	return {
			// 			// 		id: item.id,
			// 			// 		name: item.fileName,
			// 			// 		url: item.url
			// 			// 	};
			// 			// });
			// 			// this.fileWatchKey = new Date().getTime();

			// 			// let excelDataDto = [];
			// 			let packingDtos = data.data.packingDtos.map(item => {
			// 				// item.packRecordList.map(item2=>{
			// 				// 	excelDataDto.push(Object.assign(item2,item));
			// 				// });
			// 				if (item.packRecordList && item.packRecordList[0]) {
			// 					return Object.assign(item, item.packRecordList[0]);
			// 				} else {
			// 					return item;
			// 				}

			// 			});
			// 			// let packingDtos = data.data.packingDtos;
			// 			// console.log('packingDtos',packingDtos);
			// 			this.excelDataDto = packingDtos;
			// 			this.form.packingExcelDtos = packingDtos;
			// 			// console.log('this.form',this.form);
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('分页，请求失败');
			// 			this.loading = false;
			// 		});
			// },

			//上传回调
			// changeFileUrl(val) {
			// 	console.log('changeFileUrl', val);
			// 	let listUrl = val.map(item => {
			// 		// "filePath":"","fileName"：“”
			// 		return {
			// 			url: item.url,
			// 			fileName: item.name
			// 		};
			// 	});

			// 	this.form.attachments = listUrl;
			// },

			//提交信息
			submitForm(formName) {

				this.$nextTick(() => {
					this.form.packingExcelDtos = this.$refs.PackingWarehousingImport.getData();

					console.log("this.form", this.form);
					// return;

					this.$refs[formName].validate(valid => {
						if (valid) {
							let formData = Object.assign({}, this.form);

							this.postData(this.$urlConfig.WhPackingListExSubmit, formData, () => {
								this.$emit('submitSuccess');
							});

						} else {
							console.log('error submit!!');
							this.$alert(this.$t('tips.checkSubmitData'), this.$t('tips.tipsTitle'), {
								type: 'warning'
							});
							return false;
						}
					});
				});
			},
			//重置输入框
			// resetForm(formName) {
			// 	console.log(formName);
			// 	// console.log(this.$refs[formName]);

			// 	this.$nextTick(() => {
			// 		if (this.$refs[formName]) {
			// 			this.$refs[formName].resetFields();
			// 			// if ('form' === formName) {
			// 			// 	this[formName]['packRecordList'] = [];
			// 			// }
			// 		} else {
			// 			console.log('this.$refs[formName]', this.$refs[formName]);
			// 		}
			// 	});
			// 	this.$nextTick(() => {
			// 		try {
			// 			this.$refs.HyElWhUpLoad.initData();
			// 			this.$refs.HyElWhUpLoad.clearFileList();
			// 		} catch (err) {
			// 			console.log(err);
			// 		}
			// 	});
			// 	this.fileList = [];
			// },

			//提交信息
			postData(url, formData, callback) {
				// HttpTypelet _this = this;
				this.loading = true;
				// this.loading_load = true;
				let HttpType = {};
				// if ('delete' == type) {
				// 	HttpType = this.$http.delete(url, formData);
				// } else {
				HttpType = this.$http.put(url, formData);
				// }
				HttpType.then(({
					data
				}) => {
					console.log(this.$t('i18nn_bc868e024b80d2e3'));
					console.log(data);
					
					this.loading = false;
					// this.loading_load = false;
					if (200 == data.code) {
						// this.dialogFormVisible = false;
						// this.getPageData();

						// if ('delete' == type) {
						// 	this.$message({
						// 		type: 'success',
						// 		message: this.$t('tips.successfullyDelete')
						// 	});
						// } else {
						this.$alert(this.$t('tips.submitSuccess'), this.$t('tips.tipsTitle'), {
							type: 'success'
						});
						callback();
						// }
					} else {
						// if (!data.msg) {
						//   data.msg = this.$t('tips.submitError');
						// }
						this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
							type: 'warning'
							//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
						});
					}
				}).catch(error => {
					console.log(error);
					console.log(this.$t('tips.submitError'));
					this.loading = false;
					// this.loading_load = false;
					this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
						type: 'warning'
						//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
					});
				});
			},

			//查询数据字典
			// getDicData() {
			// 	this.loading_load = true;
			// 		this.$http
			// 			.put(this.$urlConfig.HyDicQueryList, ['wh_no'])
			// 			.then(({ data }) => {
			// 				console.log('查询数据字典，请求成功');
			// 				console.log(data);
			// 				if (200 == data.code && data.data) {
			// 					// this.selectOption.wh_weight_unit = data.data['wh_weight_unit'];
			// 					// this.selectOption.wh_vol_unit = data.data['wh_vol_unit'];
			// 					// this.selectOption.wh_goods_fee_type = data.data['wh_goods_fee_type'];
			// 					this.selectOption.wh_no = data.data['wh_no'];
			// 					// this.selectOption.wh_packing_type = data.data['wh_packing_type'];
			// 				} else {
			// 					if (!data.msg) {
			// 						data.msg = this.$t('tips.submitError');
			// 					}
			// 					this.$message.warning(data.msg);
			// 				}
			// 			})
			// 			.catch(error => {
			// 				console.log(error);
			// 				console.log('查询数据字典接口，请求失败');
			// 				this.$message.error(this.$t('tips.submitRequestError'));
			// 			});
			// }
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>
