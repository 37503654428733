<template>
	<!--  -->
	<!-- <el-dialog :title="$t('i18nn_0d81640498b8d063')" append-to-body :close-on-click-modal="false" :visible.sync="dialogFile" width="1200px"
		top="0"> -->
	<el-drawer :wrapperClosable="false" :title="'美国州简称二字码参考'" append-to-body :visible.sync="dialogFile" :direction="'rtl'"
		size="1000px">
		
		<div class="filterCon" style="">
			<div class="filterAction">
				
				<ul class="filterConList">
					
						<span>{{ $t('i18nn_f353b1ebbdc25be4') }}</span>
						<el-input :placeholder="$t('i18nn_3ee4c9b76289e93a')" size="small" v-model="filterData.keyword"
							maxlength="50" :clearable="true" @keyup.enter.native="initData()" style="width: 250px;"></el-input>
					</li>
					<!-- <li>
						<span>{{ $t('i18nn_6235565b185f0725') }}</span>
						<el-input :placeholder="$t('FormMsg.Please_Enter')" size="small" v-model="filterData.workNo"
							maxlength="50" :clearable="true" style="width: 220px;"></el-input>
					</li> -->
			
					<li>
						<el-button icon="el-icon-search" size="small" type="primary" @click="initData()"></el-button>
					</li>
				</ul>
			</div>
		</div>
		<el-table ref="multipleTable" :data="tableData" stripe style="width: 100%" :max-height="$store.state.frameConHeight1" size="small" v-loading="loadingUpload">

			<el-table-column type="index" fixed="left" width="50" align="center" :label="$t('Storage.tableColumn.no')">
			</el-table-column>
			<el-table-column prop="stateCN" :label="'州中文名称'"></el-table-column>
			<el-table-column prop="stateUS" :label="'州英文名称'"></el-table-column>
			<el-table-column prop="stateCode" :label="'州二字码'"></el-table-column>
			<el-table-column prop="statePostCode" :label="'州邮编'"></el-table-column>

		</el-table>

		<!-- <div slot="footer" class="dialog-footer"> -->
		<!-- <el-button type="primary" plain @click="dialogFile = false">{{$t('i18nn_4e9fc68608c60999')}}</el-button> -->
		<!-- <el-button type="warning" plain icon="el-icon-refresh" @click="ChangeSelOrderRateExpressData()">{{$t('i18nn_a9e1d1c2c5eb6525')}}</el-button> -->
		<!-- </div> -->
	</el-drawer>
	<!-- </el-dialog> -->

</template>
<script>
	
	import usStateCode from '@/utils/us-state-code.js';
	
	export default {
		props: {
			openTime: {},
			// row: {},
			// title:{},
			// typeCode:{}
		},
		components: {},
		data() {
			return {
				dialogFile: false,
				loadingUpload: false,
				// loading: false,
				filterData: {
					keyword: ''
				},
				// loading_load: false,
				tableData: [],
			};
		},
		watch: {
			openTime: function(newVal, oldVal) {
				console.log('openTime');
				this.initData();
				//初始化
				this.dialogFile = true;
			}
		},
		//创建时
		created() {

		},
		//编译挂载前
		mounted() {

		},
		methods: {
			initData() {
				this.tableData = [];
				let tableData = usStateCode.data;
				let fil_tableData = [];
				// this.getListData();
				if(!!this.filterData.keyword){
					tableData.forEach(item=>{
						if(
							(item.stateCN.indexOf(this.filterData.keyword)>-1)||
							(item.stateUS.indexOf(this.filterData.keyword)>-1)||
							(item.stateCode.indexOf(this.filterData.keyword)>-1)||
							(item.statePostCode.indexOf(this.filterData.keyword)>-1)
						){
							fil_tableData.push(item);
						}
					})
					this.tableData = fil_tableData;
				} else {
					fil_tableData = tableData;
					this.tableData = fil_tableData;
				}
				
			},

			//请求分页数据
			// getListData() {
			// 	this.loadingUpload = true;
			// 	this.$http
			// 		.get(this.$urlConfig.WhFileUploadList + '/' + this.row.id + '/'+this.typeCode, {})
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log(this.$t('i18nn_4195755cd53e871a'));
			// 			console.log(data);
			// 			this.loadingUpload = false;
			// 			if (200 == data.code) {
			// 				this.tableData = data.rows;
			// 			} else {
			// 				this.$alert(this.$t('tips.queryErrorFailed'), this.$t('tips.tipsTitle'), {
			// 					type: 'warning'
			// 				});
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			this.loadingUpload = false;
			// 			this.$alert(this.$t('tips.queryRequestFailed'), this.$t('tips.tipsTitle'), {
			// 				type: 'warning'
			// 			});
			// 		});
			// },
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>