<template>
	<div class="mainTem" :style="{'height':$store.state.frameConHeight1+'px','overflow':'auto'}">
		<el-form ref="form" inline :disabled="hasOnlyView" :rules="formRules" :model="form" label-width="110px"
			style="min-width: 1100px;width: 100%;" v-loading="loading">
			<el-card>
				<div slot="header">
					<!-- form.status:{{form.status}} -->
					<!-- <h3>{{$t('i18nn_d7aef993e73ebf60')}}</h3> -->
					<h3>
						<span>{{$t('i18nn_d7aef993e73ebf60')}}</span>
						<span class="red">{{form.sysOrderNo}}</span>
						<el-tag type="primary" effect="dark" size="small" v-if="hasCopy">{{$t('i18nn_29f67f0644a78bf3')}}</el-tag>
					</h3>
				</div>
				<div>
					
					<div>
						<el-form-item required>
							<template slot="label">
								<span>
									<span>{{$t('i18nn_e1d76a34da86da92')}}</span><span>FBA</span>
								</span>
							</template>
							<!-- <el-radio-group v-model="form.isFba">
								<el-radio :label="'0'">{{$t('i18nn_e76d885ae1c74d4f')}}</el-radio>
								<el-radio :label="'1'">{{$t('i18nn_02ccd2cf723f9272')}}</el-radio>
							</el-radio-group> -->
							<el-switch
							  v-model="form.isFba"
								active-value="1"
								inactive-value="0"
							  active-text="YES"
							  inactive-text="NO"
								@change="clearAddrCode()">
							</el-switch>
						</el-form-item>
						
						<el-form-item prop="" required v-if="'1'==form.isFba">
							<template slot="label">
								<span>
									FBA<span>{{$t('i18nn_b5b5d2437dca1e76')}}</span>
								</span>
							</template>
							
							<el-input type="text" v-model.trim="form.toWhNo" :placeholder="$t('FormMsg.Please_Enter')" style="width: 150px;">
							</el-input>
							<el-button type="primary" size="small" @click="searchFbaAddress()"
								icon="el-icon-search">{{$t('i18nn_829a74d12dc6905b')}}</el-button>
							<el-button type="warning" size="small" @click="clearAddrCode()"
								icon="el-icon-close">{{$t('i18nn_4c4e546aa3683ff5')}}</el-button>
							<span>
								{{form.trAddrInfo}}
							</span>
						</el-form-item>
						
						
						<el-form-item :label="$t('i18nn_eca5c29ad1a845c1')"  v-if="'1'!=form.isFba">
							<el-input type="text" disabled v-model="form.toWhNo" :placeholder="$t('FormMsg.Please_select')"
								style="width: 150px;"></el-input>
							<el-button type="primary" size="small" @click="openSelAddr()"
								icon="el-icon-magic-stick">{{$t('i18nn_7fb6ada66a1fccca')}}</el-button>
							<el-button type="warning" size="small" @click="clearAddrCode()"
								icon="el-icon-close">{{$t('i18nn_4c4e546aa3683ff5')}}</el-button>
							<span>
								{{form.trAddrInfo}}
							</span>
						</el-form-item>
					</div>
					
					
					<el-form-item :label="'FBA NO.'" prop="" required v-if="'1'==form.isFba">
						<span v-if="form.id">
							{{form.fbaNo}}
						</span>
						<el-input type="text" v-model.trim="form.fbaNo" :placeholder="$t('FormMsg.Please_Enter')" v-else>
						</el-input>
					</el-form-item>
					
					<el-form-item :label="$t('i18nn_a2df50399ce1bf10')" prop="" required v-if="'1'!=form.isFba">
						<span v-if="form.id">
							{{form.sendNo}}
						</span>
						<el-input type="text" v-model.trim="form.sendNo" :placeholder="$t('FormMsg.Please_Enter')" v-else>
						</el-input>
					</el-form-item>
					
					<el-form-item :label="$t('i18nn_f6283c46ba925ab4')" required>
						<whNoSelect ref="whNoSelect" @changeData="changWhNo"  size="" :width="200"></whNoSelect>
					</el-form-item>
					
					<!-- <el-form-item :label="'SKU'" prop="" required>
						<el-input type="text" v-model="form.goodsSku" :placeholder="$t('FormMsg.Please_Enter')">
						</el-input>
					</el-form-item> -->
					<!-- <el-form-item :label="$t('i18nn_2ec0f364e8bbaa4c')" prop="" required>
						<el-input-number size="" v-model="form.sendAcount" controls-position="right" style="width: 200px;">
						</el-input-number>
					</el-form-item> -->
					<!-- <el-form-item :label="$t('i18nn_bdc361ba04506136')" prop="" required>
						<el-input-number size="" v-model="form.sendCTN" controls-position="right" style="width: 200px;">
						</el-input-number>
					</el-form-item> -->
					<!-- <el-form-item :label="$t('i18nn_4b2b4820019ef897')" prop="" required>
						<el-input-number size="" v-model="form.ctnPCS" controls-position="right" style="width: 200px;">
						</el-input-number>
					</el-form-item> -->
					<el-form-item :label="$t('i18nn_b19f7906dab47f9c')" prop="" required>
						<!-- <el-input type="text" v-model="form.carTmsTypeName" :placeholder="$t('FormMsg.Please_Enter')">
						</el-input> -->
						<el-select filterable clearable v-model="form.carTmsType" :placeholder="$t('FormMsg.Please_select')"
							size="" style="width: 200px;">
							<el-option v-for="item in selectOption.wh_car_tms_type" :key="item.code" :label="$Utils.i18nCodeText(item)"
								:value="item.code"></el-option>
						</el-select>
					</el-form-item>
					
					
					
					<el-form-item :label="$t('Storage.tableColumn.remark')">
						<el-input type="textarea" :placeholder="$t('FormMsg.Please_Enter')" v-model="form.remark"
							:maxlength="2000" show-word-limit style="width: 800px"></el-input>
					</el-form-item>
				</div>
			</el-card>
			
			<el-card>
				<div slot="header" class=""><h3>{{$t('i18nn_e6bdd31025bb4f9f')}}</h3></div>
				<div>
					<div style="margin-bottom: 10px;" >
						<el-button type="primary" size="small" icon="el-icon-plus"
							@click="addRow">{{$t('i18nn_24c8f46012a25c89')}}</el-button>
						<el-button type="danger" size="small" icon="el-icon-delete"
							@click="clearRow">{{$t('i18nn_4c4e546aa3683ff5')}}</el-button>
					</div>
					
					<div>
						
						<el-table border :data="form.sendRecordDtoList" size="small" style=" width: 100%;">
							<el-table-column type="index" fixed="left" width="50" align="center" :label="$t('Storage.tableColumn.no')"></el-table-column>
			
							<!-- <el-table-column prop="sendNo" label="shipmentNo"></el-table-column> -->
							<el-table-column prop="goodsSku" label="SKU" width="200" label-class-name="red-required-table">
								<template slot-scope="scope">
									<!-- <el-input size="mini" v-model="scope.row.goodsSku" maxlength=""></el-input> -->
									{{scope.row.goodsSku}}
								</template>
							</el-table-column>
							<el-table-column prop="sendAcount" :label="$t('i18nn_2ec0f364e8bbaa4c')" width="160" label-class-name="red-required-table">
								<template slot-scope="scope">
									<el-input-number size="mini" :disabled="!!scope.row.id" v-model="scope.row.sendAcount" maxlength=""></el-input-number>
								</template>
							</el-table-column>
							<el-table-column prop="sendCTN" :label="$t('i18nn_bdc361ba04506136')" width="160" label-class-name="red-required-table">
								<template slot-scope="scope">
									<el-input-number size="mini" :disabled="!!scope.row.id" v-model="scope.row.sendCTN" maxlength=""></el-input-number>
								</template>
							</el-table-column>
							<el-table-column prop="ctnPCS" :label="$t('i18nn_4b2b4820019ef897')" width="160" label-class-name="red-required-table">
								<template slot-scope="scope">
									<el-input-number size="mini" :disabled="!!scope.row.id" v-model="scope.row.ctnPCS" maxlength=""></el-input-number>
								</template>
							</el-table-column>
							
							<!-- <el-table-column prop="ctnLength" label="外箱长(cm)" width="160" label-class-name="red-required-table">
								<template slot-scope="scope">
									<el-input-number size="mini" v-model="scope.row.ctnLength" maxlength=""></el-input-number>
								</template>
							</el-table-column>
							<el-table-column prop="ctnWidth" label="外箱宽(cm)" width="160" label-class-name="red-required-table">
								<template slot-scope="scope">
									<el-input-number size="mini" v-model="scope.row.ctnWidth" maxlength=""></el-input-number>
								</template>
							</el-table-column>
							<el-table-column prop="ctnHeight" label="外箱高(cm)" width="160" label-class-name="red-required-table">
								<template slot-scope="scope">
									<el-input-number size="mini" v-model="scope.row.ctnHeight" maxlength=""></el-input-number>
								</template>
							</el-table-column>
							<el-table-column prop="ctnWeight" label="箱毛重(kg)	" width="160" label-class-name="red-required-table">
								<template slot-scope="scope">
									<el-input-number size="mini" v-model="scope.row.ctnWeight" maxlength=""></el-input-number>
								</template>
							</el-table-column> -->
							
							<!-- <el-table-column prop="toWhNo" label="FBA仓库代码" width="160">
								<template slot-scope="scope">
									<el-input size="mini" v-model="scope.row.toWhNo" maxlength=""></el-input>
								</template>
							</el-table-column>
							<el-table-column prop="fbaNo" label="FBA NO." width="160">
								<template slot-scope="scope">
									<el-input size="mini" v-model="scope.row.fbaNo" maxlength=""></el-input>
								</template>
							</el-table-column> -->
							
							<el-table-column prop="shipmentCode" :label="$t('i18nn_fa6ae0aebfee7efa')" width="160">
								<template slot-scope="scope">
									<el-input size="mini" :disabled="!!scope.row.id" v-model="scope.row.shipmentCode" maxlength=""></el-input>
								</template>
							</el-table-column>
							<el-table-column prop="markNo" :label="$t('i18nn_32df9cb3d1681477')" width="160">
								<template slot-scope="scope">
									<el-input size="mini" :disabled="!!scope.row.id" v-model="scope.row.markNo" maxlength=""></el-input>
								</template>
							</el-table-column>
							
							
							
							<!-- <el-table-column prop="country" :label="$t('i18nn_b166e4e8fe9513fa')">
								<template slot-scope="scope">
									<el-input size="mini" v-model="scope.row.country" maxlength=""></el-input>
								</template>
							</el-table-column>
							<el-table-column prop="state" label="州/省" width="160">
								<template slot-scope="scope">
									<el-input size="mini" v-model="scope.row.state" maxlength=""></el-input>
								</template>
							</el-table-column>
							<el-table-column prop="city" :label="$t('i18nn_e05b5d049b64b040')" width="160">
								<template slot-scope="scope">
									<el-input size="mini" v-model="scope.row.city" maxlength=""></el-input>
								</template>
							</el-table-column>
							<el-table-column prop="postalCode" :label="$t('i18nn_c4913ab43009b365')" width="160">
								<template slot-scope="scope">
									<el-input size="mini" v-model="scope.row.postalCode" maxlength=""></el-input>
								</template>
							</el-table-column>
							<el-table-column prop="addr" :label="$t('i18nn_8758fd50c87d6c9c')" width="160">
								<template slot-scope="scope">
									<el-input size="mini" v-model="scope.row.addr" maxlength=""></el-input>
								</template>
							</el-table-column>
							<el-table-column prop="phone" :label="$t('i18nn_2d13b6f878f30be3')" width="160">
								<template slot-scope="scope">
									<el-input size="mini" v-model="scope.row.phone" maxlength=""></el-input>
								</template>
							</el-table-column>
							<el-table-column prop="name" :label="$t('i18nn_e44e9a291d80c593')" width="160">
								<template slot-scope="scope">
									<el-input size="mini" v-model="scope.row.name" maxlength=""></el-input>
								</template>
							</el-table-column> -->
							
							<!-- <el-table-column prop="remark" :label="$t('i18nn_5fb84776e29f5b72')" width="160" show-overflow-tooltip>
								<template slot-scope="scope">
									<el-input size="mini" v-model="scope.row.remark" maxlength=""></el-input>
								</template>
							</el-table-column> -->
							<el-table-column :label="$t('Storage.tableColumn.operation')" min-width="150" fixed="right">
								<template slot-scope="scope">
									<div>
										<el-button @click="delRow($event, scope.row,scope.$index)" type="danger" size="mini"
											icon="el-icon-minus">{{ $t('FormMsg.Delete') }}</el-button>
									</div>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</div>
			</el-card>
			
			<!-- <el-card>
				<div slot="header">
					<h3><span>{{$t('i18nn_3e8b33480ff7e0f7')}}</span><span>(CM/KG)</span></h3>
				</div>
				<div>
					<el-form-item :label="$t('i18nn_eb8af1b7e16e2069')" prop="" required>
						<el-input-number size="" v-model="form.ctnLength" controls-position="right" style="width: 200px;">
						</el-input-number>
					</el-form-item>
					<el-form-item :label="$t('i18nn_64e6eb209a8a2cff')" prop="" required>
						<el-input-number size="" v-model="form.ctnWidth" controls-position="right" style="width: 200px;">
						</el-input-number>
					</el-form-item>
					<el-form-item :label="$t('i18nn_2a02dbcab12c9ee8')" prop="" required>
						<el-input-number size="" v-model="form.ctnHeight" controls-position="right" style="width: 200px;">
						</el-input-number>
					</el-form-item>
					<el-form-item :label="$t('i18nn_55ed30102ca735c1')" prop="" required>
						<el-input-number size="" v-model="form.ctnWeight" controls-position="right" style="width: 200px;">
						</el-input-number>
					</el-form-item>
				</div>
			</el-card> -->
			<!-- <el-card>
				<div slot="header">
					<h3>FBA</h3>
				</div>
				<div>
					<el-form-item :label="'FBA仓库'" prop="">
						<el-input type="text" v-model="form.toWhNo" :placeholder="$t('FormMsg.Please_Enter')">
						</el-input>
					</el-form-item>
					<el-form-item :label="'FBA NO.'" prop="">
						<el-input type="text" v-model="form.fbaNo" :placeholder="$t('FormMsg.Please_Enter')"></el-input>
					</el-form-item>
				</div>
			</el-card> -->
			<!-- <el-card>
				<div slot="header">
					<h3>{{$t('i18nn_97e49d00bcc4c17c')}}</h3>
				</div>
				<div>
					<el-form-item :label="$t('i18nn_fa6ae0aebfee7efa')" prop="">
						<el-input type="text" v-model="form.shipmentCode" :placeholder="$t('FormMsg.Please_Enter')">
						</el-input>
					</el-form-item>
					<el-form-item :label="$t('i18nn_32df9cb3d1681477')" prop="">
						<el-input type="text" v-model="form.markNo" :placeholder="$t('FormMsg.Please_Enter')">
						</el-input>
					</el-form-item>
				</div>
			</el-card> -->
			<!-- <el-card>
				<div slot="header">
					<h3>{{$t('i18nn_e366dc49c78c8e3d')}}</h3>
				</div>
				<div>
					<el-form-item :label="$t('i18nn_b166e4e8fe9513fa')" prop="">
						<el-input type="text" v-model="form.country" :placeholder="$t('FormMsg.Please_Enter')">
						</el-input>
					</el-form-item>
					<el-form-item :label="'州/省'" prop="">
						<el-input type="text" v-model="form.state" :placeholder="$t('FormMsg.Please_Enter')">
						</el-input>
					</el-form-item>
					<el-form-item :label="$t('i18nn_e05b5d049b64b040')" prop="">
						<el-input type="text" v-model="form.city" :placeholder="$t('FormMsg.Please_Enter')">
						</el-input>
					</el-form-item>
					<el-form-item :label="$t('i18nn_c4913ab43009b365')" prop="">
						<el-input type="text" v-model="form.postalCode" :placeholder="$t('FormMsg.Please_Enter')">
						</el-input>
					</el-form-item>
					<el-form-item :label="$t('i18nn_8758fd50c87d6c9c')" prop="">
						<el-input type="text" v-model="form.addr" :placeholder="$t('FormMsg.Please_Enter')">
						</el-input>
					</el-form-item>
					<el-form-item :label="$t('i18nn_2d13b6f878f30be3')" prop="">
						<el-input type="text" v-model="form.phone" :placeholder="$t('FormMsg.Please_Enter')">
						</el-input>
					</el-form-item>
					<el-form-item :label="$t('i18nn_e44e9a291d80c593')" prop="">
						<el-input type="text" v-model="form.name" :placeholder="$t('FormMsg.Please_Enter')">
						</el-input>
					</el-form-item>
				</div>
			</el-card> -->
			<!-- <el-form-item :label="$t('i18nn_3e358d140504ab46')" prop="detail" class="">
						<HyQuillEditor ref="myTextEditor" :value="form.contentStr" @editorChange="onEditorChange" class=""></HyQuillEditor>
					</el-form-item> -->



			<!-- <el-card>
				<div slot="header">
					<h3>{{$t('i18nn_15b3627faddccb1d')}}</h3>
				</div>
				<div>
					<el-form-item :label="$t('Storage.tableColumn.remark')">
						<el-input type="textarea" :placeholder="$t('FormMsg.Please_Enter')" v-model="form.remark"
							maxlength="" show-word-limit style="width: 800px"></el-input>
					</el-form-item>
				</div>
			</el-card> -->
			
			<el-card>
				<div slot="header">
					<h3>{{$t('i18nn_f5d43732e3f6cf4d')}}</h3>
				</div>
				<div>
					<div style="padding: 10px 0; ">
						<el-radio-group v-model="wh_file_type" size="medium" style="margin-bottom: 10px;">
							<el-radio border v-for="item in selectOption.wh_file_type" :key="item.code" :label="item.code">
								{{ $Utils.i18nCodeText(item) }}
							</el-radio>
						</el-radio-group>
					</div>
					<div v-loading="loadingUploadFile">
						<!-- <el-form-item :label="$t('i18nn_f5d43732e3f6cf4d')" prop=""> -->
							<el-button type="primary" size="small" icon="el-icon-paperclip" @click="openAddFile()">{{$t('i18nn_ad36057ff6a7ce6b')}}</el-button>
						
							<ul>
								<li v-for="(item,index) in form.attachments" :key="index">
									<el-image style="width: 50px; height: 50px;vertical-align: middle;"
										:z-index="9999" :fit="'contain'" :src="item.url"
										:preview-src-list="form.attachments.map(itemPre=> { return itemPre.url})">
										<div slot="error" class="image-slot">
											<i class="el-icon-document"></i>
										</div>
									</el-image>
									<el-tag type="danger" effect="plain" size="mini">
										{{item.typeName}}
									</el-tag>
									<a :href="item.url" :title="item.url"
										target="_blank">{{ item.fileName }}</a>
						
									<!-- <el-button @click="delFile($event, item)" type="text" size="mini" icon="el-icon-delete"
									style="padding: 0 10px;">{{$t('i18nn_e33c9b93c36fd250')}}</el-button> -->
									
									<el-button @click="delFileAction($event, item,index)" type="text" size="mini"
										icon="el-icon-delete" style="padding: 0 10px;" v-if="item.id">{{$t('i18nn_e33c9b93c36fd250')}}</el-button>
									<el-button @click="delFile($event, index)" type="text" size="mini" icon="el-icon-delete"
										style="padding: 0 10px;" v-else>{{$t('i18nn_e33c9b93c36fd250')}}</el-button>
										
								</li>
							</ul>
						<!-- </el-form-item> -->
					</div>
				</div>
				
			</el-card>
			
		</el-form>
		<div class="submit-footer" v-if="!hasOnlyView">
			<!-- <div  class="submit-footer" v-if="hasEdit"> -->
				<el-button type="warning" icon="el-icon-tickets" @click="submitForm('form')" style="width: 200px;">{{$t('i18nn_e186babc2e9cadf4')}}</el-button>
			<!-- </div> -->
			<!-- <div  class="submit-footer" v-else>
				<el-button type="warning" @click="submitForm('form')" style="width: 200px;">{{$t('i18nn_e186babc2e9cadf4')}}</el-button>
			</div> -->
		</div>
		<!-- 选择已有库存的SKU -->
		<dialogWSkuInventories :openTime="skuInvOpenTime" :whNo="form.whNo" @sure="sureSel"></dialogWSkuInventories>
		
		<!-- 附件 -->
		<dialogFileUpload ref="dialogFileUpload" :folder="'storage/SingleAddAttaFile/'" :fileKey="'pickUp'"
			:openTime="FileUploadOpenTime" @success="FileUploadSuccess"></dialogFileUpload>
		
		<!-- 地址 -->
		<TransportAddressListDialog :openTime="TransportAddressOpenTime" @selSuccess="addrSelSuccess">
		</TransportAddressListDialog>
		
	</div>
</template>
<script>
	import {
		getDicData
	} from '@/axios/common.js';
	// import HyQuillEditor from '@/components/Common/HyQuillEditor.vue';
	// import HyElWhUpLoad from '@/components/Common/HyElWhUpLoad.vue';
	import whNoSelect from '@/components/StorageCenter/components/whNoSelect.vue';
	import dialogWSkuInventories from '@/components/StorageCenter/WSkuProduct/dialogWSkuInventories.vue';
	import dialogFileUpload from '@/components/StorageCenter/components/dialogFileUpload.vue';
	import TransportAddressListDialog from '@/components/StorageCenter/Transport/TransportAddressListDialog.vue';
	export default {
		// name: 'BuyerOrder',
		//meta信息seo用
		// metaInfo: {
		//   title: '互易天下-厂家共享平台-买家中心-收货账单', // set a title
		// },

		// props: {
		//   // mobile:"",
		//   isSel: {
		//     default: function() {
		//       return false;
		//     },
		//     type: Boolean
		//   },
		//   status: {
		//     default: function() {
		//       return '';
		//     },
		//     type: String
		//   }
		// },
		components: {
			whNoSelect,
			dialogWSkuInventories,
			dialogFileUpload,
			TransportAddressListDialog
			// HyElWhUpLoad,
			// HyQuillEditor
		},
		data() {
			return {
				// UserInfo: this.$store.getters.getUserInfo,
				// dialogFormVisible: false,
				dialogFormStatus: 0, //0-新增，1-修改

				loading: false,

				loading_det: false,
				//是否可编辑
				hasEdit: false,
				//是否复制数据
				hasCopy: false,
				//是否仅预览
				hasOnlyView: false,
				//附件
				// fileWatchKey: '1',
				// fileList: [],
				// fileId: this.$store.getters.getUserInfo.id,
				// skuInvWhNo:"",
				skuInvOpenTime: '',
				//
				TransportAddressOpenTime:'',
				//附件
				FileUploadOpenTime: '',
				loadingUploadFile: false,
				wh_file_type: "90",
				
				form: {
					// id: '', //"数据ID",
					whNo: '',
					sendNo: "",
					isFba:'0',
					toWhNo: "",
					trAddrInfo:"",
					fbaNo: "",
					// goodsSku: "",
					// sendAcount: undefined,
					// sendCTN: undefined,
					// ctnPCS: undefined,
					carTmsTypeName: "",
					// ctnLength: undefined,
					// ctnWidth: undefined,
					// ctnHeight: undefined,
					// ctnWeight: undefined,
					// toWhNo: "",
					// fbaNo: "",
					// shipmentCode: "",
					// markNo: "",
					// country: "",
					// state: "",
					// city: "",
					// postalCode: "",
					// addr: "",
					// phone: "",
					// name: "",
					remark: "",
					sendRecordDtoList:[
						// {
						// 	goodsSku: "",
						// 	sendAcount: undefined,
						// 	sendCTN: undefined,
						// 	ctnPCS: undefined,
						// 	// carTmsTypeName: "",
						// 	// ctnLength: undefined,
						// 	// ctnWidth: undefined,
						// 	// ctnHeight: undefined,
						// 	// ctnWeight: undefined,
						// 	// toWhNo: "",
						// 	// fbaNo: "",
						// 	shipmentCode: "",
						// 	markNo: "",
						// 	// country: "",
						// 	// state: "",
						// 	// city: "",
						// 	// postalCode: "",
						// 	// addr: "",
						// 	// phone: "",
						// 	// name: "",
						// }
					],
					attachments:[],
				},

				formRules: {
					// title: [{
					// 	required: true,
					// 	message: this.$t('FormMsg.Please_Enter'),
					// 	trigger: 'blur'
					// }],
					// contentStr: [{
					// 	required: true,
					// 	message: this.$t('FormMsg.Please_Enter'),
					// 	trigger: 'blur'
					// }],
					// orderType: [{
					// 	required: true,
					// 	message: this.$t('FormMsg.Please_Enter'),
					// 	trigger: 'change'
					// }]
				},
				// loading_load: false,
				
				
				selectOption: {
					wh_car_tms_type: [],
					wh_file_type: []
				}
			};
		},

		//创建时
		created() {
			// this.getPageData();
			this.initData();

		},
		//编译挂载前
		mounted() {
			// this.getDicData();
			//数据字典
			getDicData(['wh_car_tms_type','wh_file_type'],
				(data)=>{
					this.selectOption.wh_car_tms_type = data['wh_car_tms_type'];
					this.selectOption.wh_file_type = data['wh_file_type'];
			});

		},
		methods: {
			initData() {
				this.hasCopy = false;
				this.hasEdit = false;
				// this.wh_file_type = "90";
				this.resetForm('form');
				
				if (this.$route.query.id) {
					//编辑
					// this.hasEdit = true;
					if ('1' == this.$route.query.copy) {
						this.hasCopy = true;
					} else {
						//编辑
						this.hasEdit = true;
					}
					
					this.getPageDetData(this.$route.query.id);
				} else {
					//新增
					this.hasEdit = false;
				}
			},
			changWhNo(data) {
				console.log('changWhNo', data);
				this.form.whNo = data.code;
				// this.filterData.userId = data.userId;
				// this.initData();
				this.form.sendRecordDtoList = [];
			},
			
			// 新建-表格
			addRow() {
				// this.form.sendRecordDtoList.push({
				// 	goodsSku: "",
				// 	sendAcount: undefined,
				// 	sendCTN: undefined,
				// 	ctnPCS: undefined,
				// 	shipmentCode: "",
				// 	markNo: "",
				// });
				// this.skuInvWhNo = this.form.whNo;
				if(!this.form.whNo){
					this.$message.warning(this.$t('FormMsg.Select_long_wh'))
					return;
				}
				this.skuInvOpenTime = new Date().getTime();
			},
			// selSkuInv(val){
			// 	val.forEach(item=>{
			// 		let hasSku = this.form.sendRecordDtoList.some(item2=> item.goodsSku == item2.goodsSku);
			// 		//是否已经存在此SKU
			// 		if(!hasSku){//不存在
			// 			this.form.sendRecordDtoList.push({
			// 				goodsSku: item.goodsSku,
			// 				sendAcount: undefined,
			// 				sendCTN: undefined,
			// 				ctnPCS: undefined,
			// 				shipmentCode: "",
			// 				markNo: "",
			// 			});
			// 		}
			// 	})
			// },
			sureSel(val){
				val.forEach(item=>{
					// let hasSku = this.form.sendRecordDtoList.some(item2=> item.goodsSku == item2.goodsSku);
					//是否已经存在此SKU
					// if(!hasSku){//不存在
						this.form.sendRecordDtoList.push({
							goodsSku: item.goodsSku,
							sendAcount: undefined,
							sendCTN: undefined,
							ctnPCS: undefined,
							shipmentCode: "",
							markNo: "",
						});
					// }
				})
			},
			
			clearRow(){
				this.form.sendRecordDtoList = [];
			},
			
			//删除-表格
			delRow(event, row, index) {
				this.form.sendRecordDtoList.splice(index, 1);
			},
			
			//添加附件
			openAddFile() {
				// this.FileUploadOpenIndex = index;
				this.FileUploadOpenTime = new Date().getTime();
			},
			FileUploadSuccess(data) {
				console.log('FileUploadSuccess', data);
				let typeName = '';
				if(this.selectOption.wh_file_type){
					let typeNameObj = this.selectOption.wh_file_type.find(item=>item.code==this.wh_file_type);
					if(typeNameObj){
						typeName = typeNameObj.codeText;
					}
				}
				
				let fileList = data.map(item => {
					return {
						fileName: item.fileName, //this.$t('i18nn_89221ad15d2ec113'),
						url: item.filePath, //this.$t('i18nn_8758fd50c87d6c9c')
						// type: "90"
						type: this.wh_file_type ? this.wh_file_type : '90',
						typeName: typeName,
					}
				});
				if(!this.form.attachments){
					this.form.attachments = [];
				}
				this.form.attachments = this.form.attachments.concat(fileList);
				console.log('attachments', this.form.attachments);
				this.$forceUpdate();
			},
			//删除附件
			delFile(event, index) {
				event.stopPropagation();
				this.form.attachments.splice(index, 1);
				this.$forceUpdate();
			},
			//后端删除
			delFileAction(event,row,index) {
				event.stopPropagation();
			  this.loadingUploadFile = true;
			  this.$http
			    .delete(this.$urlConfig.WhFileUploadDel + '/' + row.id, {})
			    .then(({ data }) => {
			      this.loadingUploadFile = false;
			      if (200 == data.code) {
							this.form.attachments.splice(index, 1);
							this.$forceUpdate();
							this.$message.success(this.$t('i18nn_de017dafc266aa03'));
			      } else {
							this.$message.warning(data.msg ? data.msg : this.$t('i18nn_54dc58d9d0377bb5'));
			      }
			    })
			    .catch(error => {
			      console.log(error);
						this.loadingUploadFile = false;
						this.$message.warning(data.msg ? data.msg : '删除失败!');
			    });
			},
			// changeAgentUser(val){
			// 	// this.filterData.agentUser = val;
			// 	// this.initData();
			// },
			//跳转页面
			// toPageUrl(name){
			//   this.$router.push({'name':name});
			// },
			//打开新增编辑，弹窗
			// openDioalog(formParm, msg) {
			// 	// console.log(formParm);
			// 	this.dialogFormVisible = true;
			// 	let form = Object.assign({}, formParm);
			// 	console.log('form', form);
			// 	this.dialogFormMsg = msg;
			// 	// // 重置
			// 	this.resetForm('form');
			// 	if (null === formParm) {
			// 		//新增

			// 		// this.form.weightUnit = '1';
			// 		// this.form.volumeUnit = '1';
			// 		// this.form.whFeeType = '1';

			// 		this.dialogFormStatus = 0;
			// 		// form.subUserId = null;
			// 		// form.userSubUserId = null;
			// 		// form.state = true;
			// 		//浅拷贝、对象属性的合并
			// 		this.form = form;
			// 	} else {
			// 		//修改
			// 		this.dialogFormStatus = 1;
			// 		// form.state = form.state === '0' ? true : false;

			// 		//浅拷贝、对象属性的合并
			// 		this.form = form;
			// 	}

			// 	this.$forceUpdate();
			// 	// this.$alert('确定要确认通过该笔账单！', this.$t('tips.tipsTitle'), {
			// 	//       type: 'warning',
			// 	//       //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			// 	//     });
			// },
			// 富文本
			// onEditorChange(html) {
			// 	this.form.contentStr = html;
			// },
			//打开编辑
			// openEdit(event, row, index) {
			// 	event.stopPropagation();
			// 	this.openDioalog(row, this.$t('FormMsg.Edit'));
			// 	this.getPageDetData(row.id);
			// },
			//明细
			//请求分页数据
			getPageDetData(id) {
				// let _this = this;
				this.loading = true;

				this.$http
					.get(this.$urlConfig.WhTransferOrderEditSer + '/' + id, {})
					.then(({
						data
					}) => {
						// console.log(this.$store.getters.getUserInfo);
						console.log('分页，请求成功');
						console.log(data);
						
						this.loading = false;
						//是否可编辑
						// if ('0' == data.data.status) {
						// 	this.hasEdit = true;
						// }

						//表格显示数据
						let form = data.data;
						this.form = form;
						//是否仅仅预览
						// if (null != this.form.status && '' != this.form.status && '0' != this.form.status) {
						// 	this.hasOnlyView = true;
						// }
						
						//批量导入，查询的fba仓库代码会在明细里面
						if('1'==this.form.isFba && ''==this.form.toWhNo){
							this.form.toWhNo = this.form.sendRecordDtoList.map(item=>{
								return item.toWhNo
							}).join(',');
						}
						
						//复制数据
						if (this.hasCopy) {
							// this.form.id = null;
						
							this.form = {
								// id: '', //"数据ID",
								whNo: form.whNo,
								sendNo: form.sendNo,
								isFba: form.isFba,
								toWhNo: form.toWhNo,
								fbaNo: form.fbaNo,
						
								carTmsType: form.carTmsType,
						
								remark: form.remark,
								sendRecordDtoList: [],
								attachments: [],
							}
							if (form.sendRecordDtoList && form.sendRecordDtoList.length > 0) {
								this.form.sendRecordDtoList = form.sendRecordDtoList.map(item => {
									item.id = null;
									return item;
								});
							}
							if (form.attachments && form.attachments.length > 0) {
								this.form.attachments = form.attachments.map(item => {
									item.id = null;
									return item;
								});
							}
						}

						this.$nextTick(() => {
							this.$refs.whNoSelect.init(this.form.whNo);
						})

						// this.fileList = data.data.attachments.map(item => {
						// 	return {
						// 		id: item.id,
						// 		name: item.fileName,
						// 		url: item.url
						// 	};
						// });
						// this.fileWatchKey = new Date().getTime();

					})
					.catch(error => {
						console.log(error);
						console.log('分页，请求失败');
						this.loading = false;
					});
			},

			//上传回调
			// changeFileUrl(val) {
			// 	console.log('changeFileUrl', val);
			// 	let listUrl = val.map(item => {
			// 		// "filePath":"","fileName"：“”
			// 		return {
			// 			url: item.url,
			// 			fileName: item.name
			// 		};
			// 	});

			// 	this.form.attachments = listUrl;
			// },

			//提交信息
			submitForm(formName) {
				// this.$nextTick(() => {
				// 	try {
				// 		this.$refs.HyElWhUpLoad.submitUpload();
				// 	} catch (err) {
				// 		console.log(err);
				// 	}
				// });

				this.$refs[formName].validate(valid => {
					if (valid) {
						let formData = Object.assign({}, this.form);
						
						console.log(formData.id);
						
						if (!formData.id) {
							// formData.id = null;
							// formData.userId = this.UserInfo.id;
							
							
							this.postData(this.$urlConfig.WhShipmentsOrderAdd, formData, () => {
								console.log('submitSuccess');
								this.$emit('submitSuccess');
							});
						} else {
							// formData.userId = this.UserInfo.id;
							this.postData(this.$urlConfig.WhTransferOrderEditUpdate, formData, () => {
								this.$emit('submitSuccess');
							});
						}
					} else {
						console.log('error submit!!');
						this.$alert(this.$t('tips.checkSubmitData'), this.$t('tips.tipsTitle'), {
							type: 'warning'
							//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
						});
						return false;
					}
				});
			},
			//重置输入框
			resetForm(formName) {
				console.log(formName);
				// console.log(this.$refs[formName]);

				this.$nextTick(() => {
					if (this.$refs[formName]) {
						this.$refs[formName].resetFields();
						// if ('form' === formName) {
						// 	this[formName]['packRecordList'] = [];
						// }
					} else {
						console.log('this.$refs[formName]', this.$refs[formName]);
					}
				});
				// this.$nextTick(() => {
				// 	try {
				// 		this.$refs.HyElWhUpLoad.initData();
				// 		this.$refs.HyElWhUpLoad.clearFileList();
				// 	} catch (err) {
				// 		console.log(err);
				// 	}
				// });
				// this.fileList = [];
			},

			//提交信息
			postData(url, formData, callback) {
				// HttpTypelet _this = this;
				this.loading = true;
				// this.loading_load = true;
				let HttpType = {};
				// if ('delete' == type) {
				// 	HttpType = this.$http.delete(url, formData);
				// } else {
				HttpType = this.$http.put(url, formData);
				// }
				HttpType.then(({
					data
				}) => {
					console.log(this.$t('i18nn_bc868e024b80d2e3'));
					console.log(data);
					
					this.loading = false;
					// this.loading_load = false;
					if (200 == data.code) {
						// this.dialogFormVisible = false;
						// this.getPageData();

						// if ('delete' == type) {
						// 	this.$message({
						// 		type: 'success',
						// 		message: this.$t('tips.successfullyDelete')
						// 	});
						// } else {
						this.$alert(this.$t('tips.submitSuccess'), this.$t('tips.tipsTitle'), {
							type: 'success'
						});
						callback();
						// }
					} else {
						// if (!data.msg) {
						//   data.msg = this.$t('tips.submitError');
						// }
						this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
							type: 'warning'
							//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
						});
					}
				}).catch(error => {
					console.log(error);
					console.log(this.$t('tips.submitError'));
					this.loading = false;
					// this.loading_load = false;
					this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
						type: 'warning'
						//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
					});
				});
			},
			
			//清空地址
			clearAddrCode() {
				this.form.toWhNo = "";
				this.form.trAddrInfo = "";
			},
			//打开地址选择
			openSelAddr(index) {
				// this.TransportOpenIndex = index;
				this.TransportAddressOpenTime = new Date().getTime();
			},
			addrSelSuccess(data) {
				this.form.toWhNo = data.code;
				this.form.trAddrInfo = [data.address, data.city, data.state, data.country, data.zipCode].join(',');
			},
			//查询FBA地址
			searchFbaAddress() {
				// console.log('searchFbaAddress');
				if ('' == this.form.toWhNo) {
					this.$message.warning("请输入FBA仓库号");
					return;
				}
				//清空上次查询数据
				this.form.trAddrInfo = "";
				this.loading = true;
				this.$http
					.get(this.$urlConfig.WhTransferQueryFba + '?fbaCode=' + this.form.toWhNo, {})
					.then(({
						data
					}) => {
						// console.log(this.$t('i18nn_776496ec446f21f9'));
						// console.log(data);
						this.loading = false;
						if (200 == data.code && data.data) {
							let addrData = data.data;
							// this.form.country = addrData.country;
							// this.form.state = addrData.state;
							// this.form.city = addrData.city;
							// this.form.postalCode = addrData.zipCode;
							// this.form.addr = addrData.address;
							// this.form.phone = addrData.phone;
							
							// this.form.trAddrInfo = [addrData.address,addrData.phone, addrData.city, addrData.state, addrData.country, addrData.zipCode].join(',');
							this.form.trAddrInfo = addrData.name;
						}
					})
					.catch(error => {
						console.log(error);
						console.log(this.$t('tips.errorData'));
						this.loading = false;
					});
			},
			//查询数据字典
			// getDicData() {
			// 	// let _this = this;
			// 	// console.log(keyword);
			// 	this.loading_load = true;
			// 		this.$http
			// 			.put(this.$urlConfig.HyDicQueryList, ['wh_car_tms_type'])
			// 			.then(({ data }) => {
			// 				console.log('查询数据字典，请求成功');
			// 				console.log(data);
			// 				if (200 == data.code && data.data) {
			// 					// this.selectOption.wh_weight_unit = data.data['wh_weight_unit'];
			// 					// this.selectOption.wh_vol_unit = data.data['wh_vol_unit'];
			// 					// this.selectOption.wh_goods_fee_type = data.data['wh_goods_fee_type'];
			// 					this.selectOption.wh_car_tms_type = data.data['wh_car_tms_type'];
			// 					// this.selectOption.wh_packing_type = data.data['wh_packing_type'];
			// 				} else {
			// 					if (!data.msg) {
			// 						data.msg = this.$t('tips.submitError');
			// 					}
			// 					this.$message.warning(data.msg);
			// 				}
			// 			})
			// 			.catch(error => {
			// 				console.log(error);
			// 				console.log('查询数据字典接口，请求失败');
			// 				this.$message.error(this.$t('tips.submitRequestError'));
			// 			});
			// }
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>
