<template>
	<div class="mainTem" :style="{'height':$store.state.frameConHeight1+'px','overflow':'auto'}">
		<el-form ref="form" inline :disabled="hasOnlyView" :rules="formRules" :model="form" label-width="200px"
			style="min-width: 1100px;width: 100%;" v-loading="loading">
			<el-card>
				<div slot="header">
					<!-- form.status:{{form.status}} -->
					<h3>
						<span>
							{{$t('i18nn_b74bad1a7a5403b1')}}
						</span>
						<span class="red">{{form.receiptNo}}</span>
						<el-tag type="primary" effect="dark" size="small" v-if="hasCopy">{{$t('i18nn_29f67f0644a78bf3')}}</el-tag>
					</h3>
					
				</div>
				<div>
					<el-form-item :label="$t('i18nn_a0f2924544ff3ad8')" required>
						<whNoSelect ref="whNoSelect" @changeData="changWhNo" size="" :width="200"></whNoSelect>
					</el-form-item>
					<el-form-item prop="" required>
						<template slot="label">
							<span>{{$t('i18nn_3d3bc466f13154d4')}}</span>/<span>{{$t('i18nn_a31d32ac4015d196')}}</span>/<span>{{$t('i18nn_6aa78c268e87527b')}}</span>
						</template>
						<el-input type="text" v-model.trim="form.packingNo" :placeholder="$t('FormMsg.Please_Enter')">
						</el-input>
					</el-form-item>
					<el-form-item :label="$t('i18nn_643e08ba53f72f39')" prop="" required>
						<!-- <el-input type="text" v-model="form.packageTypeName" :placeholder="$t('FormMsg.Please_Enter')">
						</el-input> -->
						<el-select filterable clearable v-model="form.packageType"
							:placeholder="$t('FormMsg.Please_select')" size="" style="width: 200px;">
							<el-option v-for="item in selectOption.wh_packing_type" :key="item.code"
								:label="$Utils.i18nCodeText(item)" :value="item.code"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item :label="$t('i18nn_977ed6286b514ff6')" prop="" required v-if="'1'==form.packageType">
						<!-- <el-input type="text" v-model="form.containerSizeName" :placeholder="$t('FormMsg.Please_Enter')">
						</el-input> -->
						<el-select filterable clearable v-model="form.containerSize"
							:placeholder="$t('FormMsg.Please_select')" size="" style="width: 200px;">
							<el-option v-for="item in selectOption.wh_container_size" :key="item.code"
								:label="$Utils.i18nCodeText(item)" :value="item.code"></el-option>
						</el-select>
					</el-form-item>
					<!-- <el-form-item :label="'SKU'" prop="" required>
						<el-input type="text" v-model="form.goodsSku" :placeholder="$t('FormMsg.Please_Enter')">
						</el-input>
					</el-form-item> -->
					<!-- <el-form-item :label="$t('i18nn_bad53577db0da2d3')" prop="" required>
						<el-input-number size="" v-model="form.packCount" controls-position="right"
							style="width: 200px;">
						</el-input-number>
					</el-form-item> -->
					<el-form-item :label="$t('i18nn_9be246e60da2a0a2')" prop="" required>
						<el-date-picker v-model="form.arriveWhDate" type="date" :placeholder="$t('i18nn_68efabd029c860a9')" :clearable="false"
							value-format="yyyy-MM-dd" format="yyyy-MM-dd" size="" style="width: 200px;">
						</el-date-picker>
						</el-input>
					</el-form-item>
					<!-- <el-form-item :label="$t('i18nn_18c1c1a3ea76e9d3')" prop="">
						<el-input-number size="" v-model="form.totalPallet" controls-position="right"
							style="width: 200px;">
						</el-input-number>
					</el-form-item> -->

					<!-- <el-form-item :label="$t('i18nn_b19f7906dab47f9c')" prop="" required>
						<el-select filterable clearable v-model="form.carTmsTypeName" :placeholder="$t('FormMsg.Please_select')"
							size="" style="width: 200px;">
							<el-option v-for="item in selectOption.wh_car_tms_type" :key="item.code" :label="$Utils.i18nCodeText(item)"
								:value="item.code"></el-option>
						</el-select>
					</el-form-item> -->
					<el-form-item :label="$t('Storage.tableColumn.remark')">
						<el-input type="textarea" :placeholder="$t('FormMsg.Please_Enter')" v-model="form.remark"
							:maxlength="1000" show-word-limit style="width: 800px"></el-input>
					</el-form-item>
				</div>
			</el-card>

			<el-card>
				<div slot="header">
					<h3>{{$t('i18nn_e6bdd31025bb4f9f')}}</h3>
				</div>
				<div>
					<div style="margin-bottom: 10px;">
						<el-button type="primary" size="small" icon="el-icon-plus"
							@click="addRow">{{$t('i18nn_24c8f46012a25c89')}}</el-button>
						<el-button type="danger" size="small" icon="el-icon-delete"
							@click="clearRow">{{$t('i18nn_4c4e546aa3683ff5')}}</el-button>
					</div>
					

					<!-- "goodsSku": "",
						"packCount": "",
						"ctnSizeUnit": "",
						"totalCTN": "",
						"ctnPCS": "",
						"ctnLength": "",
						"ctnWidth": "",
						"ctnHeight": "",
						"ctnWeight": "" -->

					<el-table :data="form.packRecordList" stripe :border="true" size="small">
						<!-- <el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50" align="center" :label="$t('Storage.tableColumn.no')"></el-table-column> -->
						<el-table-column prop="" :label="$t('i18nn_b74bad1a7a5403b1')" label-class-name="table-header-style2">
							<el-table-column prop="goodsSku" :label="'SKU'" min-width="200"
								label-class-name="red-required-table">
								<template slot-scope="scope">
									<div>
										<!-- <el-input type="text" size="small" v-model="scope.row.goodsSku"
											:placeholder="$t('FormMsg.Please_Enter')"></el-input> -->
											{{scope.row.goodsSku}}
										<!-- <el-button size="mini" type="primary" icon="el-icon-link"
										@click="openSelData($event, scope.row, scope.$index)">选择SKU</el-button>
									<div>{{ scope.row.goodsSku }}</div> -->
									</div>
								</template>
							</el-table-column>
							<el-table-column prop="" :label="$t('i18nn_bad53577db0da2d3')" min-width="150"
								label-class-name="red-required-table">
								<template slot-scope="scope">
									<div>
										<el-input-number size="small" v-model="scope.row.packCount"
											controls-position="right"></el-input-number>
									</div>
								</template>
							</el-table-column>

							<el-table-column prop="" :label="$t('i18nn_18c1c1a3ea76e9d3')" min-width="150">
								<template slot-scope="scope">
									<div>
										<el-input-number size="small" v-model="scope.row.totalPallet"
											controls-position="right"></el-input-number>
									</div>
								</template>
							</el-table-column>
						</el-table-column>
						<el-table-column prop="" :label="$t('Storage.packing_list.carton_spec')">
							<el-table-column prop="" :label="$t('i18nn_bdc361ba04506136')" min-width="150">
								<template slot-scope="scope">
									<div>
										<el-input-number size="small" v-model="scope.row.totalCTN"
											controls-position="right"></el-input-number>
									</div>
								</template>
							</el-table-column>
							<el-table-column prop="" :label="$t('i18nn_85a10d3e5ea0e103')" min-width="150">
								<template slot-scope="scope">
									<div>
										<el-input-number size="small" v-model="scope.row.ctnPCS"
											controls-position="right"></el-input-number>
									</div>
								</template>
							</el-table-column>


							<el-table-column prop="" :label="$t('i18nn_b6bf0a07fe26f74f')" min-width="150">
								<template slot-scope="scope">
									<div>
										<el-select filterable clearable v-model="scope.row.ctnSizeUnit"
											:placeholder="$t('FormMsg.Please_select')" size="small"
											style="width: 100px;">
											<el-option v-for="item in selectOption.wh_size_unit" :key="item.code"
												:label="$Utils.i18nCodeText(item)" :value="item.code"></el-option>
										</el-select>
									</div>
								</template>
							</el-table-column>
							<el-table-column prop="" :label="$t('i18nn_eb8af1b7e16e2069')" min-width="150">
								<template slot-scope="scope">
									<div>
										<el-input-number size="small" v-model="scope.row.ctnLength"
											controls-position="right"></el-input-number>
									</div>
								</template>
							</el-table-column>
							<el-table-column prop="" :label="$t('i18nn_64e6eb209a8a2cff')" min-width="150">
								<template slot-scope="scope">
									<div>
										<el-input-number size="small" v-model="scope.row.ctnWidth"
											controls-position="right"></el-input-number>
									</div>
								</template>
							</el-table-column>
							<el-table-column prop="" :label="$t('i18nn_2a02dbcab12c9ee8')" min-width="150">
								<template slot-scope="scope">
									<div>
										<el-input-number size="small" v-model="scope.row.ctnHeight"
											controls-position="right"></el-input-number>
									</div>
								</template>
							</el-table-column>
							<el-table-column prop="" :label="$t('i18nn_55ed30102ca735c1')" min-width="150">
								<template slot-scope="scope">
									<div>
										<el-input-number size="small" v-model="scope.row.ctnWeight"
											controls-position="right"></el-input-number>
									</div>
								</template>
							</el-table-column>
						</el-table-column>
						<el-table-column :label="$t('Storage.tableColumn.operation')" min-width="150" fixed="right">
							<template slot-scope="scope">
								<div>
									<el-button @click="delRow($event, scope.row,scope.$index)" type="danger" size="mini"
										icon="el-icon-minus">{{ $t('FormMsg.Delete') }}</el-button>
								</div>
							</template>
						</el-table-column>
					</el-table>
				</div>
			</el-card>

			<!-- <el-card>
				<div slot="header">
					<h3><span>{{$t('i18nn_3e8b33480ff7e0f7')}}</span></h3>
				</div>
				<div>
					<el-form-item :label="$t('i18nn_bdc361ba04506136')" prop="">
						<el-input-number size="" v-model="form.totalCTN" controls-position="right"
							style="width: 200px;">
						</el-input-number>
					</el-form-item>
					<el-form-item :label="$t('i18nn_85a10d3e5ea0e103')" prop="">
						<el-input-number size="" v-model="form.ctnPCS" controls-position="right" style="width: 200px;">
						</el-input-number>
					</el-form-item>
					<el-form-item :label="$t('i18nn_b6bf0a07fe26f74f')" prop="">
						<el-select filterable clearable v-model="form.ctnSizeUnitName"
							:placeholder="$t('FormMsg.Please_select')" size="" style="width: 200px;">
							<el-option v-for="item in selectOption.wh_size_unit" :key="item.code" :label="$Utils.i18nCodeText(item)"
								:value="item.codeText"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item :label="$t('i18nn_55ed30102ca735c1')" prop="">
						<el-input-number size="" v-model="form.ctnWeight" controls-position="right"
							style="width: 200px;">
						</el-input-number>
					</el-form-item>
					<el-form-item :label="$t('i18nn_eb8af1b7e16e2069')" prop="">
						<el-input-number size="" v-model="form.ctnLength" controls-position="right"
							style="width: 200px;">
						</el-input-number>
					</el-form-item>
					<el-form-item :label="$t('i18nn_64e6eb209a8a2cff')" prop="">
						<el-input-number size="" v-model="form.ctnWidth" controls-position="right"
							style="width: 200px;">
						</el-input-number>
					</el-form-item>
					<el-form-item :label="$t('i18nn_2a02dbcab12c9ee8')" prop="">
						<el-input-number size="" v-model="form.ctnHeight" controls-position="right"
							style="width: 200px;">
						</el-input-number>
					</el-form-item>
				</div>
			</el-card>

			<el-card>
				<div slot="header">
					<h3>{{$t('i18nn_15b3627faddccb1d')}}</h3>
				</div>
				<div>
					<el-form-item :label="$t('Storage.tableColumn.remark')">
						<el-input type="textarea" :placeholder="$t('FormMsg.Please_Enter')" v-model="form.remark"
							maxlength="" show-word-limit style="width: 800px"></el-input>
					</el-form-item>
				</div>
			</el-card> -->
			
			<el-card>
				<div slot="header">
					<h3>{{$t('i18nn_f5d43732e3f6cf4d')}}</h3>
				</div>
				<div v-loading="loadingUploadFile">
					<!-- <el-form-item :label="$t('i18nn_f5d43732e3f6cf4d')" prop=""> -->
						<el-button type="primary" size="small" icon="el-icon-paperclip" @click="openAddFile()">{{$t('i18nn_ad36057ff6a7ce6b')}}</el-button>
					
						<ul>
							<li v-for="(item,index) in form.attachments" :key="index">
								<el-image style="width: 50px; height: 50px;vertical-align: middle;"
									:z-index="9999" :fit="'contain'" :src="item.url"
									:preview-src-list="form.attachments.map(itemPre=> { return itemPre.url})">
									<div slot="error" class="image-slot">
										<i class="el-icon-document"></i>
									</div>
								</el-image>
								<a :href="item.url" :title="item.url"
									target="_blank">{{ item.fileName }}</a>
					
								<!-- <el-button @click="delFile($event, item)" type="text" size="mini" icon="el-icon-delete"
								style="padding: 0 10px;">{{$t('i18nn_e33c9b93c36fd250')}}</el-button> -->
								
								<el-button @click="delFileAction($event, item,index)" type="text" size="mini"
									icon="el-icon-delete" style="padding: 0 10px;" v-if="item.id">{{$t('i18nn_e33c9b93c36fd250')}}</el-button>
								<el-button @click="delFile($event, index)" type="text" size="mini" icon="el-icon-delete"
									style="padding: 0 10px;" v-else>{{$t('i18nn_e33c9b93c36fd250')}}</el-button>
									
							</li>
						</ul>
					<!-- </el-form-item> -->
				</div>
			</el-card>
		</el-form>
		<div class="submit-footer" v-if="!hasOnlyView">
			<!-- <div  class="submit-footer" v-if="hasEdit"> -->
				<el-button type="warning" icon="el-icon-tickets" @click="submitForm('form')" style="width: 200px;">{{$t('i18nn_e186babc2e9cadf4')}}</el-button>
			<!-- </div> -->
			<!-- <div  class="submit-footer" v-else>
				<el-button type="warning" @click="submitForm('form')" style="width: 200px;">{{$t('i18nn_e186babc2e9cadf4')}}</el-button>
			</div> -->
		</div>
		
		<!-- 选择已有的SKU -->
		<dialogWSku :openTime="skuOpenTime" @sure="sureSel"></dialogWSku>
		
		<!-- 附件 -->
		<dialogFileUpload ref="dialogFileUpload" :folder="'storage/SingleAddAttaFile/'" :fileKey="'inWh'"
			:openTime="FileUploadOpenTime" @success="FileUploadSuccess"></dialogFileUpload>
	</div>
</template>
<script>
	import {
		getDicData
	} from '@/axios/common.js';
	// import HyQuillEditor from '@/components/Common/HyQuillEditor.vue';
	// import HyElWhUpLoad from '@/components/Common/HyElWhUpLoad.vue';
	import whNoSelect from '@/components/StorageCenter/components/whNoSelect.vue';
	import dialogWSku from '@/components/StorageCenter/WSkuProduct/dialogWSku.vue';
	import dialogFileUpload from '@/components/StorageCenter/components/dialogFileUpload.vue';
	
	export default {
		// name: 'BuyerOrder',
		//meta信息seo用
		// metaInfo: {
		//   title: '互易天下-厂家共享平台-买家中心-收货账单', // set a title
		// },

		// props: {
		//   // mobile:"",
		//   isSel: {
		//     default: function() {
		//       return false;
		//     },
		//     type: Boolean
		//   },
		//   status: {
		//     default: function() {
		//       return '';
		//     },
		//     type: String
		//   }
		// },
		components: {
			whNoSelect,
			dialogWSku,
			dialogFileUpload
			// HyElWhUpLoad,
			// HyQuillEditor
		},
		data() {
			return {
				// UserInfo: this.$store.getters.getUserInfo,
				// dialogFormVisible: false,
				dialogFormStatus: 0, //0-新增，1-修改

				loading: false,

				loading_det: false,
				//是否可编辑
				hasEdit: false,
				//是否仅预览
				hasOnlyView: false,
				//复制
				hasCopy: false,
				
				//附件
				FileUploadOpenTime: '',
				//附件
				// fileWatchKey: '1',
				// fileList: [],
				// fileId: this.$store.getters.getUserInfo.id,
				// {
				// 	"packingNo": this.$t('i18nn_ae61193590bd513d'),
				// 	"whNo": this.$t('i18nn_c944a6686d996ab3'),
				// 	"arriveWhDate": this.$t('i18nn_9be246e60da2a0a2'),
				// 	"packageType": this.$t('i18nn_643e08ba53f72f39'),
				// 	"containerSize": this.$t('i18nn_977ed6286b514ff6'),
				// 	"packRecordList": [{
				// 		"goodsSku": "",
				// 		"packCount": "",
				// 		"ctnSizeUnit": "",
				// 		"totalCTN": "",
				// 		"ctnPCS": "",
				// 		"ctnLength": "",
				// 		"ctnWidth": "",
				// 		"ctnHeight": "",
				// 		"ctnWeight": ""
				// 	}]
				// }
				skuOpenTime:'',
				
				loadingUploadFile: false,
				
				form: {
					// id: '', //"数据ID",
					whNo: '',
					packingNo: "",
					packageTypeName: "",
					containerSizeName: "",
					// goodsSku: "",
					// packCount: undefined,
					arriveWhDate: "",
					// totalPallet: undefined,
					// totalCTN: undefined,
					// ctnPCS: undefined,
					// ctnSizeUnitName: "",
					// ctnWeight: undefined,
					// ctnLength: undefined,
					// ctnWidth: undefined,
					// ctnHeight: undefined,
					remark: "",
					"packRecordList": [
						// {
						// 	"goodsSku": "",
						// 	"packCount": "",
						// 	"ctnSizeUnit": "",
						// 	"totalCTN": "",
						// 	"ctnPCS": "",
						// 	totalPallet: "",
						// 	"ctnLength": "",
						// 	"ctnWidth": "",
						// 	"ctnHeight": "",
						// 	"ctnWeight": ""
						// },
					],
					attachments:[],
				},

				formRules: {
					// title: [{
					// 	required: true,
					// 	message: this.$t('FormMsg.Please_Enter'),
					// 	trigger: 'blur'
					// }],
					// contentStr: [{
					// 	required: true,
					// 	message: this.$t('FormMsg.Please_Enter'),
					// 	trigger: 'blur'
					// }],
					// orderType: [{
					// 	required: true,
					// 	message: this.$t('FormMsg.Please_Enter'),
					// 	trigger: 'change'
					// }]
				},
				// loading_load: false,

				selectOption: {
					wh_size_unit: [],
					wh_packing_type: [],
					wh_container_size: []

				}
			};
		},

		//创建时
		created() {
			// this.getPageData();
			this.initData();

		},
		//编译挂载前
		mounted() {
			// this.getDicData();
			//数据字典
			getDicData(['wh_size_unit', 'wh_packing_type', 'wh_container_size'],
				(data)=>{
					this.selectOption.wh_size_unit = data['wh_size_unit'];
					this.selectOption.wh_packing_type = data['wh_packing_type'];
					this.selectOption.wh_container_size = data['wh_container_size'];
			});

		},
		methods: {
			initData() {
				this.hasCopy = false;
				this.hasEdit = false;
				this.resetForm('form');
				
				if (this.$route.query.id) {
					//编辑
					// this.hasEdit = true;
					if('1'==this.$route.query.copy){
						this.hasCopy = true;
					} else {
						//编辑
						this.hasEdit = true;
					}
					
					this.getPageDetData(this.$route.query.id);
				} else {
					//新增
					this.hasEdit = false;
				}
			},
			changWhNo(data) {
				console.log('changWhNo', data);
				this.form.whNo = data.code;
				// this.filterData.userId = data.userId;
				// this.initData();
			},
			// changeAgentUser(val){
			// 	// this.filterData.agentUser = val;
			// 	// this.initData();
			// },
			// 新建-表格
			// addRow() {
			// 	this.form.packRecordList.push({
			// 		"goodsSku": "",
			// 		"packCount": "",
			// 		"ctnSizeUnit": "",
			// 		"totalCTN": "",
			// 		"ctnPCS": "",
			// 		"ctnLength": "",
			// 		"ctnWidth": "",
			// 		"ctnHeight": "",
			// 		"ctnWeight": ""
			// 	});
			// },
			
			// 新建-表格
			addRow() {
				// this.form.sendRecordDtoList.push({
				// 	goodsSku: "",
				// 	sendAcount: undefined,
				// 	sendCTN: undefined,
				// 	ctnPCS: undefined,
				// 	shipmentCode: "",
				// 	markNo: "",
				// });
				this.skuOpenTime = new Date().getTime();
			},
			// selSku(val){
			// 	val.forEach(item=>{
			// 		let hasSku = this.form.packRecordList.some(item2=> item.goodsSku == item2.goodsSku);
			// 		//是否已经存在此SKU
			// 		if(!hasSku){//不存在
			// 			this.form.packRecordList.push({
			// 				goodsSku: item.goodsSku,
			// 				"packCount": "",
			// 				"ctnSizeUnit": "",
			// 				"totalCTN": "",
			// 				"ctnPCS": "",
			// 				"ctnLength": "",
			// 				"ctnWidth": "",
			// 				"ctnHeight": "",
			// 				"ctnWeight": ""
			// 			});
			// 		}
			// 	})
			// },
			sureSel(val){
				val.forEach(item=>{
					// let hasSku = this.form.packRecordList.some(item2=> item.goodsSku == item2.goodsSku);
					//是否已经存在此SKU
					// if(!hasSku){//不存在
						this.form.packRecordList.push({
							goodsSku: item.goodsSku,
							"packCount": "",
							"totalPallet":"",
							"ctnSizeUnit": "",
							"totalCTN": "",
							"ctnPCS": "",
							"ctnLength": "",
							"ctnWidth": "",
							"ctnHeight": "",
							"ctnWeight": ""
						});
					// }
				})
			},
			clearRow(){
				this.form.packRecordList = [];
			},
			
			//删除-表格
			delRow(event, row, index) {
				this.form.packRecordList.splice(index, 1);
			},
			
			//添加附件
			openAddFile() {
				// this.FileUploadOpenIndex = index;
				this.FileUploadOpenTime = new Date().getTime();
			},
			FileUploadSuccess(data) {
				console.log('FileUploadSuccess', data);
				let fileList = data.map(item => {
					return {
						fileName: item.fileName, //this.$t('i18nn_89221ad15d2ec113'),
						url: item.filePath, //this.$t('i18nn_8758fd50c87d6c9c')
						// type: "90"
					}
				});
				if(!this.form.attachments){
					this.form.attachments = [];
				}
				this.form.attachments = this.form.attachments.concat(fileList);
				console.log('attachments', this.form.attachments);
				this.$forceUpdate();
			},
			//删除附件
			delFile(event, index) {
				event.stopPropagation();
				this.form.attachments.splice(index, 1);
				this.$forceUpdate();
			},
			//后端删除
			delFileAction(event,row,index) {
				event.stopPropagation();
			  this.loadingUploadFile = true;
			  this.$http
			    .delete(this.$urlConfig.WhFileUploadDel + '/' + row.id, {})
			    .then(({ data }) => {
			      this.loadingUploadFile = false;
			      if (200 == data.code) {
							this.form.attachments.splice(index, 1);
							this.$forceUpdate();
							this.$message.success(this.$t('i18nn_de017dafc266aa03'));
			      } else {
							this.$message.warning(data.msg ? data.msg : this.$t('i18nn_54dc58d9d0377bb5'));
			      }
			    })
			    .catch(error => {
			      console.log(error);
						this.loadingUploadFile = false;
						this.$message.warning(data.msg ? data.msg : '删除失败!');
			    });
			},
			//跳转页面
			// toPageUrl(name){
			//   this.$router.push({'name':name});
			// },
			//打开新增编辑，弹窗
			// openDioalog(formParm, msg) {
			// 	// console.log(formParm);
			// 	this.dialogFormVisible = true;
			// 	let form = Object.assign({}, formParm);
			// 	console.log('form', form);
			// 	this.dialogFormMsg = msg;
			// 	// // 重置
			// 	this.resetForm('form');
			// 	if (null === formParm) {
			// 		//新增

			// 		// this.form.weightUnit = '1';
			// 		// this.form.volumeUnit = '1';
			// 		// this.form.whFeeType = '1';

			// 		this.dialogFormStatus = 0;
			// 		// form.subUserId = null;
			// 		// form.userSubUserId = null;
			// 		// form.state = true;
			// 		//浅拷贝、对象属性的合并
			// 		this.form = form;
			// 	} else {
			// 		//修改
			// 		this.dialogFormStatus = 1;
			// 		// form.state = form.state === '0' ? true : false;

			// 		//浅拷贝、对象属性的合并
			// 		this.form = form;
			// 	}

			// 	this.$forceUpdate();
			// 	// this.$alert('确定要确认通过该笔账单！', this.$t('tips.tipsTitle'), {
			// 	//       type: 'warning',
			// 	//       //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			// 	//     });
			// },
			// 富文本
			// onEditorChange(html) {
			// 	this.form.contentStr = html;
			// },
			//打开编辑
			// openEdit(event, row, index) {
			// 	event.stopPropagation();
			// 	this.openDioalog(row, this.$t('FormMsg.Edit'));
			// 	this.getPageDetData(row.id);
			// },
			//明细
			//请求分页数据
			getPageDetData(id) {
				// let _this = this;
				this.loading = true;

				this.$http
					.get(this.$urlConfig.WhPackingListQueryId + "/" + id, {})
					.then(({
						data
					}) => {
						// console.log(this.$store.getters.getUserInfo);
						console.log('分页，请求成功');
						console.log(data);
						
						this.loading = false;
						//是否可编辑
						// if ('0' == data.data.status) {
						// 	this.hasEdit = true;
						// }

						//表格显示数据
						let form = data.data;
						this.form = form;
						
						//复制数据
						if(this.hasCopy){
							// this.form.id = null;
							
							this.form = {
								// id: '', //"数据ID",
								whNo: form.whNo,
								packingNo: form.packingNo,
								packageType: form.packageType,
								containerSize: form.containerSize,
								arriveWhDate: form.arriveWhDate,
								remark: form.remark,
								// "packRecordList": [],
								// attachments:[],
							}
							if(form.packRecordList && form.packRecordList.length>0){
								this.form.packRecordList = form.packRecordList.map(item=>{
									item.id = null;
									return item;
								});
							}
							if(form.attachments && form.attachments.length>0){
								this.form.attachments = form.attachments.map(item=>{
									item.id = null;
									return item;
								});
							}
						}
						
						//是否仅仅预览
						// if (null != this.form.status && '' != this.form.status && '0' != this.form.status) {
						// 	this.hasOnlyView = true;
						// }

						this.$nextTick(() => {
							this.$refs.whNoSelect.init(this.form.whNo);
						})

						// this.fileList = data.data.attachments.map(item => {
						// 	return {
						// 		id: item.id,
						// 		name: item.fileName,
						// 		url: item.url
						// 	};
						// });
						// this.fileWatchKey = new Date().getTime();

					})
					.catch(error => {
						console.log(error);
						console.log('分页，请求失败');
						this.loading = false;
					});
			},

			//上传回调
			// changeFileUrl(val) {
			// 	console.log('changeFileUrl', val);
			// 	let listUrl = val.map(item => {
			// 		// "filePath":"","fileName"：“”
			// 		return {
			// 			url: item.url,
			// 			fileName: item.name
			// 		};
			// 	});

			// 	this.form.attachments = listUrl;
			// },

			//提交信息
			submitForm(formName) {
				// this.$nextTick(() => {
				// 	try {
				// 		this.$refs.HyElWhUpLoad.submitUpload();
				// 	} catch (err) {
				// 		console.log(err);
				// 	}
				// });
				//container才有柜型
				if('1'!=this.form.packageType){
					this.form.containerSize = null;
				}
				
				this.$refs[formName].validate(valid => {
					if (valid) {
						let formData = Object.assign({}, this.form);
						//浅拷贝、对象属性的合并
						if (!formData.id) {
							// formData.id = null;
							// formData.userId = this.UserInfo.id;

							this.postData(this.$urlConfig.WhPackingListUpdate, formData, () => {
								this.$emit('submitSuccess');
							});
						} else {
							// formData.userId = this.UserInfo.id;
							this.postData(this.$urlConfig.WhPackingListEditSave, formData, () => {
								this.$emit('submitSuccess');
							});
						}
					} else {
						console.log('error submit!!');
						this.$alert(this.$t('tips.checkSubmitData'), this.$t('tips.tipsTitle'), {
							type: 'warning'
							//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
						});
						return false;
					}
				});
			},
			//重置输入框
			resetForm(formName) {
				console.log(formName);
				// console.log(this.$refs[formName]);

				this.$nextTick(() => {
					if (this.$refs[formName]) {
						this.$refs[formName].resetFields();
						// if ('form' === formName) {
						// 	this[formName]['packRecordList'] = [];
						// }
					} else {
						console.log('this.$refs[formName]', this.$refs[formName]);
					}
				});
				// this.$nextTick(() => {
				// 	try {
				// 		this.$refs.HyElWhUpLoad.initData();
				// 		this.$refs.HyElWhUpLoad.clearFileList();
				// 	} catch (err) {
				// 		console.log(err);
				// 	}
				// });
				// this.fileList = [];
			},

			//提交信息
			postData(url, formData, callback) {
				// HttpTypelet _this = this;
				this.loading = true;
				// this.loading_load = true;
				let HttpType = {};
				// if ('delete' == type) {
				// 	HttpType = this.$http.delete(url, formData);
				// } else {
				HttpType = this.$http.put(url, formData);
				// }
				HttpType.then(({
					data
				}) => {
					console.log(this.$t('i18nn_bc868e024b80d2e3'));
					console.log(data);
					
					this.loading = false;
					// this.loading_load = false;
					if (200 == data.code) {
						// this.dialogFormVisible = false;
						// this.getPageData();

						// if ('delete' == type) {
						// 	this.$message({
						// 		type: 'success',
						// 		message: this.$t('tips.successfullyDelete')
						// 	});
						// } else {
						this.$alert(this.$t('tips.submitSuccess'), this.$t('tips.tipsTitle'), {
							type: 'success'
						});
						callback();
						// }
					} else {
						// if (!data.msg) {
						//   data.msg = this.$t('tips.submitError');
						// }
						this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
							type: 'warning'
							//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
						});
					}
				}).catch(error => {
					console.log(error);
					console.log(this.$t('tips.submitError'));
					this.loading = false;
					// this.loading_load = false;
					this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
						type: 'warning'
						//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
					});
				});
			},

			//查询数据字典
			// getDicData() {
			// 	// let _this = this;
			// 	// console.log(keyword);
			// 	this.loading_load = true;
			// 	this.$http
			// 		.put(this.$urlConfig.HyDicQueryList, ['wh_size_unit', 'wh_packing_type', 'wh_container_size'])
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log('查询数据字典，请求成功');
			// 			console.log(data);
			// 			if (200 == data.code && data.data) {
			// 				// this.selectOption.wh_weight_unit = data.data['wh_weight_unit'];
			// 				// this.selectOption.wh_vol_unit = data.data['wh_vol_unit'];
			// 				// this.selectOption.wh_goods_fee_type = data.data['wh_goods_fee_type'];
			// 				this.selectOption.wh_size_unit = data.data['wh_size_unit'];
			// 				this.selectOption.wh_packing_type = data.data['wh_packing_type'];
			// 				this.selectOption.wh_container_size = data.data['wh_container_size'];

			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = this.$t('tips.submitError');
			// 				}
			// 				this.$message.warning(data.msg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('查询数据字典接口，请求失败');
			// 			this.$message.error(this.$t('tips.submitRequestError'));
			// 		});
			// }
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>
