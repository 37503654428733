<template>
	<!-- <el-dialog :title="$t('i18nn_7fb6ada66a1fccca')" append-to-body :close-on-click-modal="false" :visible.sync="dialog" width="1000px"> -->
	<el-drawer :wrapperClosable="false"
	  title=""
		append-to-body
	  :visible.sync="dialog"
	  size="1000">
		<template slot="title">
			<h3><span>{{$t('i18nn_650abf9b436446ba')}}</span>SKU</h3>
		</template>
		<div>
			<!-- <WSkuInventories :isSel="true"></WSkuInventories> -->
			<!-- <div class="tableConTop">
				<el-row>
					<el-col :span="5" class="tableConTopLeft">
						<h3>
							<span>{{ $t('Storage.nav.SKU_inventories') }}</span>
						</h3>
					</el-col>
					<el-col :span="19" class="tableConTopRig">
						<el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()">
						</el-button>
					</el-col>
				</el-row>
			</div> -->
			<!-- <div class="formMsgCon">
			    <h3><i class="titleIcon el-icon-bell"></i>{{$t('i18nn_41413cddfa61538c')}}</h3>
			    <div class="formMsgDet">
			      <p>
			        此为录入仓库的SKU数据
			      </p>
			    </div>
			</div> -->
			<div class="filterCon" style="">
				<div class="filterBarList">
						
				</div>
				<div class="filterAction">
					<!-- <ul class="actionConList"> -->
						
					<!-- </ul> -->
					<ul class="filterConList">
						<li>
							<span>{{ $t('i18nn_f77f473d27f48bdc') }}</span>
							<SelAgentUser @change="changeAgentUser"></SelAgentUser>
						</li>
						<li>
							<span>{{$t('i18nn_c944a6686d996ab3')}}</span>
							<whNoSelect ref="whNoSelect" :disabled="!!whNo" @changeData="changWhNo"></whNoSelect>
						</li>
						<li>
							<span>SKU</span>
							<el-input :placeholder="$t('i18nn_50dcfc837d43b873')" size="small" v-model="filterData.goodsSku" maxlength="50" :clearable="true" style="width: 150px;" @keyup.enter.native="initData"></el-input>
						</li>
						<li>
							<span>{{ $t('i18nn_f353b1ebbdc25be4') }}</span>
							<el-input :placeholder="$t('i18nn_3ee4c9b76289e93a')" size="small" v-model="filterData.keyword" maxlength="50" :clearable="true" style="width: 150px;" @keyup.enter.native="initData"></el-input>
						</li>
						<li>
							<el-button icon="el-icon-search" size="small" type="primary" @click="initData()"></el-button>
						</li>
						<!-- <li>
							<el-button icon="el-icon-s-operation" size="small" type="primary" @click="openMoreFilter()"></el-button>
						</li> -->
					</ul>
				</div>
			</div>
			<!-- <div class="wh-filter-con">
				<div class="wh-filter-item">
					<span>{{ $t('i18nn_f77f473d27f48bdc') }}</span>
					<SelAgentUser @change="changeAgentUser"></SelAgentUser>
				</div>
				<div class="wh-filter-item">
					<span>{{$t('i18nn_c944a6686d996ab3')}}</span>
					<whNoSelect @changeData="changWhNo"></whNoSelect>
				</div>
				<div class="wh-filter-item">
					<span>{{ $t('i18nn_f353b1ebbdc25be4') }}</span>
					<el-input :placeholder="$t('i18nn_3ee4c9b76289e93a')" size="small" v-model="filterData.keyword" maxlength="50" :clearable="true" style="width: 150px;"></el-input>
				</div>
				<el-button type="primary" @click="serPageData" size="small">{{ $t('FormMsg.Search') }}</el-button>
			</div> -->
			
			<!-- <div class="wh-action-con">
			    <el-button type="success" @click="openDioalog" size="small" icon="el-icon-plus">{{$t('Storage.skuInfo.Add_SKU')}}</el-button>
			    <el-button type="warning" size="small" icon="el-icon-upload">{{$t('Storage.skuInfo.Import_SKU')}}</el-button>
			</div> -->
			
			<div class="" v-loading="1==pagination.current_page?loading_load:false" :element-loading-text="$t('tips.loading')">
				<el-table ref="multipleTable" :data="tableData" stripe :border="true" style="width: 100%" size="small" 
				:height="$store.state.frameConHeightWh"
				@selection-change="handleSelectionChange"
				@row-click="handleCurrentChange" v-el-table-infinite-scroll="load"
					:infinite-scroll-disabled="infinite_loading_disabled" :infinite-scroll-immediate="false" :infinite-scroll-delay="200">
					<!-- <el-table-column type="selection" fixed="left" align="center" width="50" label-class-name="nodra"></el-table-column> -->
					<el-table-column type="index" fixed="left" width="50" align="center" :label="$t('Storage.tableColumn.no')"></el-table-column>
					
					<el-table-column prop="goodsSku" label="SKU" min-width="200"></el-table-column>
					
					<el-table-column prop="goodsName" :label="$t('Storage.skuInfo.SKU_title')" min-width="200">
						<template slot-scope="scope">
							<div v-if="scope.row.mySku">
								<!-- <div>{{scope.row.mySku.goodsName}}</div>
								<div v-if="scope.row.mySku.goodsName!=scope.row.mySku.goodsNameEn">
								{{scope.row.mySku.goodsNameEn}}</div> -->
								<el-popover placement="top"
								  trigger="hover"
								  ><div class="pre-text">{{ scope.row.mySku.goodsName }}</div>
								  <span class="over_h120" slot="reference">{{scope.row.mySku.goodsName}}</span>
								</el-popover>
							</div>
						</template>
					</el-table-column>
					
					<!-- <el-table-column prop="goodsLength" :label="$t('Storage.skuInfo.size')+'('+$t('Storage.skuInfo.length_width_height')+')'">
						<template slot-scope="scope">
							<div v-if="scope.row.mySku">
								<span>{{scope.row.mySku.goodsLength}}</span>*
								<span>{{scope.row.mySku.goodsWidth}}</span>*
								<span>{{scope.row.mySku.goodsHeight}}</span>
							</div>
						</template>
					</el-table-column>
					
					<el-table-column prop="goodsWeight" :label="$t('Storage.skuInfo.weight')">
						<template slot-scope="scope">
							<div v-if="scope.row.mySku">{{ scope.row.mySku.goodsWeight }}</div>
						</template>
					</el-table-column>
					
					<el-table-column prop="sizeUnitName" :label="$t('Storage.skuInfo.unit')">
						<template slot-scope="scope">
							<div v-if="scope.row.mySku">{{ scope.row.mySku.sizeUnitName }}</div>
						</template>
					</el-table-column> -->
			
					<el-table-column prop="whNo" :label="$t('Storage.skuInfo.warehouse')"></el-table-column>
			
					<el-table-column prop="totalStock" :label="$t('Storage.skuInfo.on_hand_inventory')"></el-table-column>
			
					<el-table-column prop="lockStock" :label="$t('Storage.skuInfo.Current_locked_inventory')"></el-table-column>
			
					<el-table-column prop="currentStock" :label="$t('Storage.skuInfo.Current_available_stock')"></el-table-column>
					
					<el-table-column prop="outQuantity" :label="$t('i18nn_2b2633c44fbf02b8')" min-width="200">
						<template slot-scope="scope">
							<div>
								<!-- {{ scope.row.mySku.goodsWeight }} -->
								<!-- <el-input-number size="mini" v-model="scope.row.outQuantity"></el-input-number> -->
								<div>
									<el-input-number size="small" v-model="scope.row.outQuantity"  :disabled="0==scope.row.currentStock"
									:max="parseInt(scope.row.currentStock)" :min="0" :precision="0" maxlength="" :controls="false" controls-position="right" @blur="changeCtnNum($event,scope.$index)">
									</el-input-number>
								</div>
							</div>
						</template>
					</el-table-column>
					
					<!-- <el-table-column prop="remark" :label="$t('Storage.tableColumn.remark')" show-overflow-tooltip></el-table-column> -->
			
					<!-- <el-table-column :label="$t('Storage.tableColumn.operation')" width="200px" fixed="right">
						<template slot-scope="scope">
							
							<div>
								<el-button @click="selRow($event, scope.row, scope.$index)" type="warning" size="mini"
										icon="el-icon-magic-stick">{{ $t('FormMsg.Select') }}</el-button>
							</div>
							
						</template>
					</el-table-column> -->
				</el-table>
			
				<div class="" style="padding: 10px;">
					<el-button type="primary" icon="el-icon-check" @click="dialog = false">{{$t('i18nn_204ffab8a6e01870')}}</el-button>
					
					<!-- <div style="text-align: center;" v-if="loading_load"><i class="el-icon-loading"></i></div>
					<el-button type="primary" icon="el-icon-check" @click="sureAction()">{{$t('i18nn_760f75dc397a9cb8')}}</el-button>
					<span>{{$t('i18nn_824815ceb3f6c6db')}}</span>
					<strong class="red">{{multipleSelection.length}}</strong>
					<span>{{$t('i18nn_117aa3f27e5e430c')}}</span> -->
					<!-- ；
					<div v-if="pagination.page_size<pagination.total">
						<span>{{$t('i18nn_1ff0f0c0745be010')}}</span>
						<strong class="red">{{pagination.page_size>=pagination.total?pagination.total:pagination.page_size}}</strong>
						<span>{{$t('i18nn_117aa3f27e5e430c')}}</span>，
						<span>{{$t('i18nn_73bbf51487ec69e9')}}</span>
						<strong class="red">{{pagination.total}}</strong>
						<span>{{$t('i18nn_117aa3f27e5e430c')}}</span>；
						<span><span>{{$t('i18nn_f7fac56017a0ca84')}}</span><span class="red">{{$t('i18nn_1e7246dd6ccc5539')}}</span></span>
					</div> -->
					<!-- <span>{{$t('i18nn_1ff0f0c0745be010')}}</span>
					<strong class="red">{{pagination.page_size>=pagination.total?pagination.total:pagination.page_size}}</strong>
					<span>{{$t('i18nn_117aa3f27e5e430c')}}</span>，
					<span>{{$t('i18nn_73bbf51487ec69e9')}}</span>
					<strong class="red">{{pagination.total}}</strong>
					<span>{{$t('i18nn_117aa3f27e5e430c')}}</span> -->
					<!-- <span class="red" v-if="pagination.page_size<pagination.total">{{$t('i18nn_34ef906a3a954ad8')}}</span> -->
				</div>
			</div>
		</div>
		<!-- <div slot="footer" class="dialog-footer">
			<el-button type="primary" plain @click="dialog = false">{{$t('i18nn_4e9fc68608c60999')}}</el-button>
		</div> -->
		</el-drawer>
	<!-- </el-dialog> -->
</template>

<script>
	import {
		deepClone
	} from '@/utils/common.js';
	
	// import WSkuInventories from '@/components/StorageCenter/WSkuProduct/WSkuInventories.vue';
	import whNoSelect from '@/components/StorageCenter/components/whNoSelect.vue';
	import SelAgentUser from '@/components/Common/SelAgentUser.vue';
	import ElTableInfiniteScroll from '@/directive/el-table-infinite-scroll/index.js';
	export default {
		props: {
			openTime: {},
			whNo: {
				default: function() {
					return '';
				},
				type: String
			}
		},
		directives: {
			"el-table-infinite-scroll": ElTableInfiniteScroll,
		},
		components: {
			whNoSelect,
			SelAgentUser
		},
		data() {
			return {
				dialog: false,
				// loading_load: false,
				// tableData: [],
				
				loading_load: false,
				infinite_loading_disabled: false,
				tableData: [],
				multipleSelection:[],
				//分页数据
				pagination: this.$Utils.defaultPagination(),
				selectOption: {
					// wh_weight_unit: [],
					// wh_vol_unit: [],
					// wh_goods_fee_type: [],
				},
				//查询，排序方式
				filterData: {
					orderBy: 'id_', //排序字段
					sortAsc: 'desc', //desc降序，asc升序
					// "accountPeriod":"",
					// "billNo":""
					// status: '',
					// putWhNo: '',
					agentUser: '',
					whNo: '',
					goodsSku: '',
					// hashCode: '',
					// goodsName: '',
					// goodsNameEn: '',
					keyword: ''
					// declareNameCh: '',
				},
				
			};
		},
		watch: {
			openTime: function(newVal, oldVal) {
				console.log('openTime');
				if(this.whNo){
					this.filterData.whNo = this.whNo;
					this.$nextTick(()=>{
						this.$refs.whNoSelect.init(this.filterData.whNo);
					})
				} else {
					this.filterData.whNo = "";
					this.$nextTick(()=>{
						this.$refs.whNoSelect.init("");
					})
				}
				this.initData();
				//初始化
				this.dialog = true;
			}
		},
		//创建时
		created() {
	
		},
		//编译挂载前
		mounted() {
			// this.initData();
		},
		methods: {
			initData() {
				this.pagination.current_page = 1;
				// this.currentSel = {};
				this.multipleSelection = [];
				this.tableData = [];
				this.infinite_loading_disabled = false;
				this.getPageData();
				// this.getDicData();
			},
			changWhNo(data) {
				console.log('changWhNo', data);
				this.filterData.whNo = data.code;
				// this.filterData.userId = data.userId;
				this.initData();
			},
			// showDetList($event, row) {
			// 	$event.stopPropagation();
			// 	this.dialogShowDet = true;
			// 	this.DetData = [row.mySku];
			// },
			changeAgentUser(val) {
				this.filterData.agentUser = val;
				this.initData();
			},
			sureAction(){
				this.$emit('sure', this.multipleSelection);
				this.dialog = false;
			},
			changeCtnNum(v,index){
				console.log('changeCtnNum',v,index);
				// let tableData = this.tableData.filter(item => {
				// 	return item.trCtnCount > 0;
				// });
				// this.$emit("changeNum",tableData);
				this.$emit("changeNum",[this.tableData[index]]);
			},
			
			//选择行
			handleCurrentChange(row, event, column) {
			  // this.$refs.multipleTable.toggleRowSelection(row, true); //点击选中
			},
			//多选
			handleSelectionChange(val) {
			  console.log(val);
			  this.multipleSelection = val;
				this.$emit('SelChange', val);
			},
			
			//分页的筛选项数据
			pageFilterData() {
				return {
					proxyUserId: this.filterData.agentUser ? this.filterData.agentUser : null,
					// status: this.filterData.status ? this.filterData.status : null,
					// putWhNo: this.filterData.putWhNo ? this.filterData.putWhNo : null,
					zoneWhNo: this.filterData.whNo ? this.filterData.whNo : null,
					goodsSku: this.filterData.goodsSku ? this.filterData.goodsSku : null,
					// hashCode: this.filterData.hashCode ? this.filterData.hashCode : null,
					// goodsName: this.filterData.goodsName ? this.filterData.goodsName : null,
					// goodsNameEn: this.filterData.goodsNameEn ? this.filterData.goodsNameEn : null,
					keyword: this.filterData.keyword ? this.filterData.keyword : null
					// declareNameCh: this.filterData.declareNameCh ? this.filterData.declareNameCh : null,
				};
			},
			
			//请求分页数据
			getPageData() {
				let filterData = Object.assign(
					{
						offset: (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
						limit: this.pagination.page_size //当前页显示数目
					},
					this.pageFilterData()
				);
				// let _this = this;
				this.loading_load = true;
				this.$http
					.put(this.$urlConfig.WhMyGoodsSkuStockPageList, filterData)
					.then(({ data }) => {
						// console.log(this.$store.getters.getUserInfo);
						console.log('分页，请求成功');
						console.log(data);
						
						this.loading_load = false;
						//表格显示数据
						if(1==this.pagination.current_page){
							this.tableData = data.rows;
						} else {
							this.tableData = this.tableData.concat(data.rows);
						}
						//当前数据总条数
						this.pagination.total = parseInt(data.total);
						//加载完成，不再加载
						if (this.tableData.length >= this.pagination.total) {
							this.infinite_loading_disabled = true;
						}
						this.$nextTick(()=>{
							if(this.$refs.multipleTable){
								this.$refs.multipleTable.doLayout();
							}
						});
						//当前页数
						// this.pagination.current_page = parseInt(data.current);
						//当前页条数
						// this.pagination.page_size = parseInt(data.size);
					})
					.catch(error => {
						console.log(error);
						console.log('分页，请求失败');
						this.loading_load = false;
					});
			},
			load() {
				console.log('load');
				
				if (this.infinite_loading_disabled) return;
				if (this.tableData.length < this.pagination.total) {
					// this.data = this.data.concat(dataTemplate);
					this.pagination.current_page++;
					this.getPageData();
				}
			
				// if (this.tableData.length >= this.pagination.total) {
				// 	this.infinite_loading_disabled = true;
				// }
			},
			//选择数据后回调
			// selRow(event, row) {
			// 	event.stopPropagation();
			// 	this.$emit('selectRow', row);
			// },
			//请求分页数据
			// getListData() {
			// 	this.loading_load = true;
			// 	this.$http
			// 		.put(this.$urlConfig.WhMyGoodsSkuPageList, {
			// 			offset: "0",
			// 			limit: "100",
			// 			goodsSku: this.goodsSku ? this.goodsSku : null,
			// 		})
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log(this.$t('i18nn_4195755cd53e871a'));
			// 			console.log(data);
			// 			this.loading_load = false;
			// 			if (200 == data.code) {
			// 				this.tableData = data.rows;
			// 			} else {
			// 				this.$alert(this.$t('tips.queryErrorFailed'), this.$t('tips.tipsTitle'), {
			// 					type: 'warning'
			// 				});
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			this.loading_load = false;
			// 			this.$alert(this.$t('tips.queryRequestFailed'), this.$t('tips.tipsTitle'), {
			// 				type: 'warning'
			// 			});
			// 		});
			// },
		}
	};
</script>

<style>
</style>