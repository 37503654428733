<template>
	<div class="mainTem">
		<!-- <el-form ref="form" :disabled="!hasEdit" :rules="formBaseRules" :model="formBase" label-width="150px" style="width: 1100px;" v-loading="loading" :element-loading-text="$t('i18nn_1b4525c800280581')"> -->
		<el-card :body-style="{padding:'15px 20px'}">
			<div slot="header">
				<h3>
					<!-- {{$t('i18nn_5d77e5949de8eef9')}} -->
					<TipsActionHotTable></TipsActionHotTable>
				</h3>
			</div>
			<div>
				<!-- <el-tabs type="border-card"> -->
				<!-- <el-tab-pane :label="$t('i18nn_5d77e5949de8eef9')"> -->
				<!-- <el-card> -->
				<el-form ref="form" :rules="formRules" :model="form" inline label-width="100px"
					style="min-width: 1100px; width: 100%;" v-loading="loading">
					<div>

					<!-- <h3 class="titSty1">{{$t('Storage.packing_list.basic_information')}}</h3> -->
					<!-- <el-card> -->
					<!-- <div slot="header" class="">
							<span>{{ $t('Storage.packing_list.basic_information') }}</span>
						</div>
						<div> -->
					<el-form-item :label="$t('i18nn_f6283c46ba925ab4')" required>
						<!-- <el-select filterable clearable v-model="form.whNo" :placeholder="$t('FormMsg.Please_select')" style="width: 300px;">
									<el-option v-for="item in selectOption.wh_no" :key="item.code" :label="$Utils.i18nCodeText(item)" :value="item.code"><div class="sel_option_s1" style="">
				<span class="sel_option_name">{{ $Utils.i18nCodeText(item) }}</span>
				<span class="sel_option_code" style="">{{ item.code }}</span>
			</div></el-option>
								</el-select> -->
						<whNoSelect size="" @changeData="changWhNo"></whNoSelect>
					</el-form-item>

					
					
					<el-form-item prop="">
						<span slot="label">
							<span>{{$t('i18nn_fc554f12d8734681')}}</span><br />SKU&nbsp;<span>{{$t('i18nn_f085596674018f3e')}}</span>
						</span>
						<el-switch v-model="form.relationFlag" :active-value="'1'" :inactive-value="'0'"
							:active-text="$t('i18nn_02ccd2cf723f9272')" :inactive-text="$t('i18nn_e76d885ae1c74d4f')">
						</el-switch>
					</el-form-item>
					
					
					<el-form-item :label="$t('Storage.tableColumn.remark')">
						<el-input type="textarea" :placeholder="$t('FormMsg.Please_Enter')" :rows="2" v-model="form.remark"
							:maxlength="2000" show-word-limit style="width: 500px"></el-input>
					</el-form-item>
				
				</div>
				<div>
					<el-form-item required>
						<template slot="label">
							<span>
								<span>{{$t('i18nn_e1d76a34da86da92')}}</span><span>FBA</span>
							</span>
						</template>
						<el-switch
						  v-model="form.isFba"
							active-value="1"
							inactive-value="0"
						  :active-text="$t('i18nn_02ccd2cf723f9272')"
						  :inactive-text="$t('i18nn_e76d885ae1c74d4f')"
							@change="clearAddrCode()">
						</el-switch>
					</el-form-item>
					
					<el-form-item :label="'FBA仓库代码'" v-if="'1'==form.isFba">
						<el-input type="text" clearable v-model="form.toWhNo"
							:placeholder="$t('i18nn_5a9aefbc03c778f7')" style="width: 150px;"></el-input>
						<el-button type="primary" size="small" @click="searchFbaAddress()"
							icon="el-icon-search">{{$t('i18nn_829a74d12dc6905b')}}</el-button>
						<el-button type="warning" size="small" @click="clearAddrCode()"
							icon="el-icon-close">{{$t('i18nn_4c4e546aa3683ff5')}}</el-button>
						<span>
							{{form.trAddrInfo}}
						</span>
					</el-form-item>
					<el-form-item :label="$t('i18nn_eca5c29ad1a845c1')" v-else>
						<el-input type="text" disabled v-model="form.toWhNo" :placeholder="$t('FormMsg.Please_select')"
							style="width: 150px;"></el-input>
						<el-button type="primary" size="small" @click="openSelAddr()"
							icon="el-icon-magic-stick">{{$t('i18nn_7fb6ada66a1fccca')}}</el-button>
						<el-button type="warning" size="small" @click="clearAddrCode()"
							icon="el-icon-close">{{$t('i18nn_4c4e546aa3683ff5')}}</el-button>
						<span>
							{{form.trAddrInfo}}
						</span>
					</el-form-item>
				</div>

					<!-- <div>
								<el-form-item :label="$t('Storage.invoice_list.delivery_number')" prop="">
									<el-input type="text" v-model="form.sendNo" :placeholder="$t('FormMsg.Please_Enter')" style="width: 300px;"></el-input>
								</el-form-item>
								<el-form-item :label="$t('Storage.invoice_list.out_car_Type')" prop="">
									<el-select filterable clearable v-model="form.carTmsType" :placeholder="$t('FormMsg.Please_select')" style="width: 300px;">
										<el-option v-for="item in selectOption.wh_car_tms_type" :key="item.code" :label="$Utils.i18nCodeText(item)" :value="item.code"></el-option>
									</el-select>
								</el-form-item>
								<el-form-item :label="$t('Storage.invoice_list.Expected_delivery_date')" prop="">
									<el-date-picker
										v-model="form.carPlanDate"
										type="datetime"
										value-format="yyyy-MM-dd HH:mm:ss"
										:placeholder="$t('FormMsg.Select_date_time')"
										style="width: 300px;"
									></el-date-picker>
								</el-form-item>
							</div> -->
					<!-- </div>
					</el-card> -->

					<!-- <el-card> -->
					<!-- <div slot="header" class=""><span>导入EXCEL</span></div> -->
					<div>
						<!-- <span v-if="hasEdit" class="red">编辑时，显示数据仅供参考，建议重新上传模板</span> -->
						<!-- <div>
								<input
									style="display: none;"
									type="file"
									id=""
									ref="file"
									@change="fileChange($event)"
									:accept="'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'"
							/>	
								<el-button type="success" size="small" icon="el-icon-plus" @click="openExcel">{{ $t('module.upload.Select_Excel') }}</el-button>
								<span style="display: inline-block; width: 200px;">
									{{ excelFileName }}
								</span>
								<el-button type="primary" size="small" icon="el-icon-upload" @click="uploadExcel">{{ $t('module.upload.Upload_preview') }}</el-button>
								<span style="padding-left: 100px;">
									<el-link 
										:href="'https://huyi-oss-wh.oss-cn-hangzhou.aliyuncs.com/newModel/transferOrder.xlsx?random=' + Math.random()"
										target="_blank"
										type="primary"
										icon="el-icon-download"
									>
										{{ $t('module.upload.Download_template') }}
									</el-link>
								</span>
							</div> -->
						<uploadExcelBtn :openTime="openTimeUpExc" :readHttp="$urlConfig.WhTransferOrderExcelRead"
							:templateUrl="'https://hytx-oss-wh.oss-cn-hongkong.aliyuncs.com/modelv2/transferOrderV2.xlsx'"
							@uploadSuccess="uploadSuccess">
						</uploadExcelBtn>

						<transferOrderHotTable ref="HotTable" :selectDicOption="{}">
						</transferOrderHotTable>

						<!-- <div>
							<el-table border :data="form.transferOrders" :max-height="$store.state.contenHeight - 250"
								size="small" v-loading="loading" style=" width: 100%;">
								<el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50" align="center"
									:label="$t('Storage.tableColumn.no')"></el-table-column>

								<el-table-column prop="sendNo" label="shipmentNo"></el-table-column>
								<el-table-column prop="goodsSku" label="SKU"></el-table-column>
								<el-table-column prop="sendAcount" :label="$t('i18nn_2ec0f364e8bbaa4c')">
								</el-table-column>
								<el-table-column prop="sendCTN" :label="$t('i18nn_bdc361ba04506136')"></el-table-column>
								<el-table-column prop="ctnPCS" :label="$t('i18nn_4b2b4820019ef897')"></el-table-column>
								<el-table-column prop="carTmsTypeName" :label="$t('i18nn_b19f7906dab47f9c')">
								</el-table-column>
								<el-table-column prop="ctnLength" label="外箱长(cm)"></el-table-column>
								<el-table-column prop="ctnWidth" label="外箱宽(cm)"></el-table-column>
								<el-table-column prop="ctnHeight" label="外箱高(cm)"></el-table-column>
								<el-table-column prop="ctnWeight" label="箱毛重(kg)"></el-table-column>
								<el-table-column prop="toWhNo" label="FBA仓库代码"></el-table-column>
								<el-table-column prop="fbaNo" label="FBA NO."></el-table-column>
								<el-table-column prop="shipmentCode" :label="$t('i18nn_fa6ae0aebfee7efa')">
								</el-table-column>
								<el-table-column prop="markNo" :label="$t('i18nn_32df9cb3d1681477')"></el-table-column>
								<el-table-column prop="country" :label="$t('i18nn_b166e4e8fe9513fa')"></el-table-column>
								<el-table-column prop="state" label="州/省"></el-table-column>
								<el-table-column prop="city" :label="$t('i18nn_e05b5d049b64b040')"></el-table-column>
								<el-table-column prop="postalCode" :label="$t('i18nn_c4913ab43009b365')">
								</el-table-column>
								<el-table-column prop="addr" :label="$t('i18nn_8758fd50c87d6c9c')"></el-table-column>
								<el-table-column prop="phone" :label="$t('i18nn_2d13b6f878f30be3')"></el-table-column>
								<el-table-column prop="name" :label="$t('i18nn_e44e9a291d80c593')"></el-table-column>
								<el-table-column prop="remark" :label="$t('i18nn_5fb84776e29f5b72')"
									show-overflow-tooltip></el-table-column>

							</el-table>
						</div> -->
					</div>

				</el-form>
			</div>
		</el-card>
		<!-- </el-tab-pane> -->
		<!-- </el-tabs> -->
		<!-- </el-form> -->

		<!-- <div style="margin: 10px;" v-if="hasEdit">
			<el-button type="warning" @click="submitForm('form')" style="width: 200px;">{{$t('i18nn_d552aa9f1c14363f')}}
			</el-button>
		</div> -->
		<div  class="submit-footer">
			<el-button type="warning" icon="el-icon-tickets" @click="submitForm('form')" style="width: 200px;">{{$t('i18nn_e186babc2e9cadf4')}}</el-button>
		</div>

		<!--查看图片-->
		<!-- <el-dialog :title="$t('FormMsg.picture')" append-to-body :close-on-click-modal="false"
			:visible.sync="dialogImgVisible" width="1000px" top="0">
			<div style="overflow: auto; width: 100%; height: 80%;"><img :src="imgUrlBigShow" width="auto"
					height="auto" /></div>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" plain @click="dialogImgVisible = false">{{ $t('FormMsg.Close') }}</el-button>
			</div>
		</el-dialog> -->
		
		<!-- 地址 -->
		<TransportAddressListDialog :openTime="TransportAddressOpenTime" @selSuccess="addrSelSuccess">
		</TransportAddressListDialog>
		
	</div>
</template>
<script>
	import {
		getDicData
	} from '@/axios/common.js';
	
	import TipsActionHotTable from '@/components/StorageCenter/workOrder/TipsActionHotTable.vue';
	import uploadExcelBtn from '@/components/StorageCenter/components/uploadExcelBtn.vue';
	import transferOrderHotTable from '@/components/StorageCenter/workOrder/workOrderTem/transferOrderHotTable.vue';
	// import HyQuillEditor from '@/components/Common/HyQuillEditor.vue';
	// import HyElWhUpLoad from '@/components/Common/HyElWhUpLoad.vue';
	import whNoSelect from '@/components/StorageCenter/components/whNoSelect.vue';
	
	import TransportAddressListDialog from '@/components/StorageCenter/Transport/TransportAddressListDialog.vue';
	
	export default {
		// name: 'BuyerOrder',
		//meta信息seo用
		// metaInfo: {
		//   title: '互易天下-厂家共享平台-买家中心-收货账单', // set a title
		// },

		// props: {
		//   // mobile:"",
		//   isSel: {
		//     default: function() {
		//       return false;
		//     },
		//     type: Boolean
		//   },
		//   status: {
		//     default: function() {
		//       return '';
		//     },
		//     type: String
		//   }
		// },
		components: {
			TipsActionHotTable,
			uploadExcelBtn,
			transferOrderHotTable,
			whNoSelect,
			TransportAddressListDialog
			// HyElWhUpLoad,
			// HyQuillEditor,
			// TransshipmentImport
		},
		data() {
			return {
				// UserInfo: this.$store.getters.getUserInfo,
				// dialogFormVisible: false,
				// dialogFormStatus: 0, //0-新增，1-修改
				// dialogFormMsg: '',
				//导入excel
				// dialogUploadVisible: false,
				// loadingExcel: false,
				// fileExcel: '',
				// // excelData: [],
				// excelFileName: '',

				openTimeUpExc: "",

				//图片放大
				// dialogImgVisible: false,
				// imgUrlBigShow: '',

				loading: false,

				loading_det: false,
				
				//选择地址
				TransportAddressOpenTime: "",

				form: {
					isFba:'0',
					// sendNo: '', //this.$t('i18nn_a2df50399ce1bf10'),
					// // recevieName: '', //this.$t('i18nn_e44e9a291d80c593'),
					whNo: '', //this.$t('i18nn_5acbec83efb27445'),
					relationFlag:'0',
					remark:"",
					toWhNo:"",
					trAddrInfo:"",
					// // recevieMobile: '', //this.$t('i18nn_b29bf013aaec9ce6'),
					// // postCode: '', //this.$t('i18nn_c4913ab43009b365'),
					// // country: '', //this.$t('i18nn_b166e4e8fe9513fa'),
					// // province: '', //"省/州",
					// // city: '', //this.$t('i18nn_e05b5d049b64b040'),
					// // address1: '', //this.$t('i18nn_8db45b615b72b7a8'),
					// carTmsType: '', //this.$t('i18nn_5165f3ee9377af83'),
					// carPlanDate: '', //this.$t('i18nn_99b88de52e382ca4'),
					transferOrders: [
						// 	// {
						// 	// 	goodsSku: '', //this.$t('i18nn_74ed28f373ea14da'),
						// 	// 	toWhNo: '', //this.$t('i18nn_0f859b9b45732564'),
						// 	// 	fbaNo: '', //fba编号
						// 	// 	sendAcount: '', //this.$t('i18nn_2ec0f364e8bbaa4c'),
						// 	// 	remark: '' //this.$t('i18nn_15b3627faddccb1d')
						// 	// }
					]
				},

				formRules: {
					// sendNo: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
					// recevieName: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
					// whNo: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
					// recevieMobile: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
					// address1: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
					// carTmsType: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'change' }],
					// postCode: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
					// country: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }], //this.$t('i18nn_b166e4e8fe9513fa'),
					// province: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }], //"省/州",
					// city: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }], //this.$t('i18nn_e05b5d049b64b040'),
					// address1: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }], //this.$t('i18nn_8db45b615b72b7a8'),
					// carTmsType: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }], //this.$t('i18nn_5165f3ee9377af83'),
					// carPlanDate: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }] //this.$t('i18nn_99b88de52e382ca4'),
				},
				loading_load: false,
				selectOption: {
					wh_size_unit: [],
					wh_car_tms_type: [],
					// wh_no: []
				},

				// UserInfo: this.$store.getters.getUserInfo,
				// dialogFormVisible: false,
				dialogFormStatus: 0, //0-新增，1-修改

				// loading: false,

				//是否可编辑
				hasEdit: false,
				// loading_det: false,

				//附件
				// fileWatchKey: '1',
				// fileList: [],
				// fileId: this.$store.getters.getUserInfo.id,

				// excelDataDto:[],

				// formBase: {
				// 	id: '', //"数据ID",
				// 	title: '', //this.$t('i18nn_7431e76029678ec7'),
				// 	orderType: '30', //this.$t('i18nn_f566f50ef680920c'),
				// 	contentStr: '', //this.$t('i18nn_3e358d140504ab46'),
				// 	attachments: [
				// 		// {
				// 		// id: '', //"数据ID",
				// 		// url: '', //"文件URL",
				// 		// fileName: '' //this.$t('i18nn_89221ad15d2ec113')
				// 		// }
				// 	]
				// },

				// formBaseRules: {
				// 	// title: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				// 	// contentStr: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				// 	// orderType: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'change' }]
				// }
				// loading_load: false,

				// selectOption: {
				// 	wh_work_order_type: []
				// }
			};
		},

		//创建时
		created() {
			// this.getPageData();

		},
		//编译挂载前
		mounted() {
			this.initData();
			// this.getDicData();
			//数据字典
			getDicData(['wh_car_tms_type', 'wh_size_unit'],
				(data)=>{
					this.selectOption.wh_size_unit = data['wh_size_unit'];
					this.selectOption.wh_car_tms_type = data['wh_car_tms_type'];
			});
		},
		methods: {
			initData() {
				this.openTimeUpExc = new Date().getTime();

				if (this.$route.query.id) {
					//编辑
					this.hasEdit = true;
					// this.getPageDetData(this.$route.query.id);
				} else {
					//新增
					this.hasEdit = false;
				}
			},
			changWhNo(data) {
				console.log('changWhNo', data);
				this.form.whNo = data.code;
				// this.filterData.userId = data.userId;
				// this.initData();
			},
			// parsingSuccess(data){
			// 	this.formBase.sendDto = data;
			// },
			// changeAgentUser(val){
			// 	// this.filterData.agentUser = val;
			// 	// this.initData();
			// },
			//跳转页面
			// toPageUrl(name){
			//   this.$router.push({'name':name});
			// },
			//打开新增编辑，弹窗
			// openDioalog(formParm, msg) {
			// 	// console.log(formParm);
			// 	this.dialogFormVisible = true;
			// 	let form = Object.assign({}, formParm);
			// 	console.log('form', form);
			// 	this.dialogFormMsg = msg;
			// 	// // 重置
			// 	this.resetForm('form');
			// 	if (null === formParm) {
			// 		//新增

			// 		// this.form.weightUnit = '1';
			// 		// this.form.volumeUnit = '1';
			// 		// this.form.whFeeType = '1';

			// 		this.dialogFormStatus = 0;
			// 		// form.subUserId = null;
			// 		// form.userSubUserId = null;
			// 		// form.state = true;
			// 		//浅拷贝、对象属性的合并
			// 		this.form = form;
			// 	} else {
			// 		//修改
			// 		this.dialogFormStatus = 1;
			// 		// form.state = form.state === '0' ? true : false;

			// 		//浅拷贝、对象属性的合并
			// 		this.form = form;
			// 	}

			// 	this.$forceUpdate();
			// 	// this.$alert('确定要确认通过该笔账单！', this.$t('tips.tipsTitle'), {
			// 	//       type: 'warning',
			// 	//       //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			// 	//     });
			// },
			// 富文本
			// onEditorChange(html) {
			// 	this.form.contentStr = html;
			// },
			//打开编辑
			// openEdit(event, row, index) {
			// 	event.stopPropagation();
			// 	this.openDioalog(row, this.$t('FormMsg.Edit'));
			// 	this.getPageDetData(row.id);
			// },
			//明细
			//请求分页数据
			// getPageDetData(id) {
			// 	// let _this = this;
			// 	this.loading = true;

			// 	this.$http
			// 		.get(this.$urlConfig.WhWorkOrderEditDet + '/' + id, {})
			// 		.then(({
			// 			data
			// 		}) => {
			// 			// console.log(this.$store.getters.getUserInfo);
			// 			console.log('分页，请求成功');
			// 			console.log(data);
			// 			
			// 			this.loading = false;
			// 			//是否可编辑
			// 			// if('0'==data.data.status){
			// 			// 	this.hasEdit = true;
			// 			// }
			// 			//表格显示数据
			// 			this.formBase.id = data.data.id;
			// 			this.form.whNo = data.data.whNo;
			// 			// this.fileList = data.data.attachments.map(item => {
			// 			// 	return {
			// 			// 		id: item.id,
			// 			// 		name: item.fileName,
			// 			// 		url: item.url
			// 			// 	};
			// 			// });
			// 			// this.fileWatchKey = new Date().getTime();

			// 			this.form.transferOrders = data.data.sendDtos;
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('分页，请求失败');
			// 			this.loading = false;
			// 		});
			// },

			//查看图片
			// openBigImg(event, imgsrc) {
			// 	event.stopPropagation();
			// 	console.log('openBigImg', imgsrc);
			// 	this.dialogImgVisible = true;
			// 	this.imgUrlBigShow = imgsrc;
			// },

			//上传数据回调
			uploadSuccess(data) {
				this.$nextTick(() => {
					// this.form.transferOrders = data;
					// this.$forceUpdate();
					this.$refs.HotTable.loadData(data);
				});
			},
			// fileChange(e) {
			// 	// this.loading = true;
			// 	console.log(e);

			// 	console.log(this.$t('i18nn_f156a9536049f461'), this.$refs.file);
			// 	// this.excelData = [];
			// 	this.form.transferOrders = [];
			// 	(this.fileExcel = null), (this.excelFileName = '');
			// 	if (!this.$refs.file || !this.$refs.file.files) {
			// 		this.$alert(this.$t('module.upload.Unknown_file'), this.$t('tips.tipsTitle'), {
			// 			// //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			// 		});
			// 		return;
			// 	}
			// 	if (this.$refs.file.files[0]) {
			// 		// if (this.fileAccept.indexOf(this.$refs.file.files[0].type) > -1) {
			// 		if (this.$refs.file.files[0].size < 1024 * 1024 * 10) {
			// 			this.fileExcel = this.$refs.file.files[0];
			// 			this.excelFileName = this.fileExcel.name;
			// 			// console.log(this.$t('i18nn_f156a9536049f461'),this.$refs.file);
			// 			// this.uploadExcel();
			// 		} else {
			// 			this.$alert(this.$t('module.upload.uploaded_over') + '10M', this.$t('tips.tipsTitle'), {});
			// 		}
			// 		// } else {
			// 		//   this.$alert(this.$t(this.$t('i18nn_4dadf13ebce2167d')), this.$t('tips.tipsTitle'), {
			// 		//   });
			// 		// }
			// 	} else {
			// 		// this.$alert(this.$t('i18nn_c8e99e36cefd743a'), this.$t('tips.tipsTitle'), {
			// 		// });
			// 		console.log(this.$t('i18nn_c8e99e36cefd743a'), this.$refs.file);
			// 	}
			// },
			// clearFile() {
			// 	this.fileExcel = null;
			// 	this.excelFileName = '';
			// 	// this.excelFileName = "";
			// 	this.$nextTick(() => {
			// 		try {
			// 			this.$refs.file.value = '';
			// 		} catch (e) {
			// 			console.log(e);
			// 			//TODO handle the exception
			// 		}
			// 	});
			// },
			// openExcel() {
			// 	try {
			// 		this.$refs.file.value = '';
			// 	} catch (e) {
			// 		console.log(e);
			// 		//TODO handle the exception
			// 	}
			// 	// this.$refs.file.value = '';
			// 	// this.$refs.file.dispatchEvent(new MouseEvent('click'));
			// 	var comment = this.$refs.file;
			// 	if (document.all) {
			// 		// For IE
			// 		comment.click();
			// 	} else if (document.createEvent) {
			// 		// 在ff中要为a标签添加click事件，并且侦听该事件
			// 		var ev = document.createEvent('MouseEvents');
			// 		ev.initEvent('click', false, true);
			// 		comment.dispatchEvent(ev);
			// 	} else {
			// 		// this.$alert('打开上传文件有误，请联系客服', this.$t('tips.tipsTitle'), {
			// 		this.$alert(this.$t('module.upload.open_error_img'), this.$t('tips.tipsTitle'), {
			// 			// //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			// 		});
			// 	}
			// },
			// //导入excel
			// uploadExcel() {
			// 	if (!this.fileExcel) {
			// 		this.$alert(this.$t('module.upload.Please_sel_file'), this.$t('tips.tipsTitle'), {});
			// 		return;
			// 	}

			// 	this.loadingExcel = true;

			// 	let file = this.fileExcel;
			// 	var formdata = new FormData();
			// 	formdata.append('file', file);

			// 	this.$http
			// 		.post(this.$urlConfig.WhTransferOrderExcelRead, formdata, {
			// 			headers: {
			// 				'Content-Type': 'multipart/form-data'
			// 			}
			// 		})
			// 		.then(({ data }) => {
			// 			console.log(this.$t('i18nn_bc868e024b80d2e3'));
			// 			console.log(data);
			// 			this.loadingExcel = false;
			// 			if (200 == data.code && data.rows) {
			// 				// this.excelData = data.rows;

			// 				this.form.transferOrders = data.rows;

			// 				this.$forceUpdate();
			// 				this.clearFile();
			// 				// if('1'==data.data.flag){
			// 				//   this.excelData = data.data.data;
			// 				// } else {
			// 				//   this.$alert(data.data.errorMsg, this.$t('tips.tipsTitle'), {
			// 				//     type: 'warning',
			// 				//     //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			// 				//   });
			// 				// }
			// 			} else {
			// 				this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
			// 					type: 'warning'
			// 					//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			// 				});
			// 			}
			// 			// if (200 == data.code && data.data) {
			// 			//   if('1'==data.data.flag){
			// 			//     this.excelData = data.data.data;
			// 			//   } else {
			// 			//     this.$alert(data.data.errorMsg, this.$t('tips.tipsTitle'), {
			// 			//       type: 'warning',
			// 			//       //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			// 			//     });
			// 			//   }

			// 			// } else {
			// 			//   this.$alert(data.msg?data.msg:this.$t("tips.submitError"), this.$t('tips.tipsTitle'), {
			// 			//     type: 'warning',
			// 			//     //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			// 			//   });
			// 			// }
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log(this.$t('tips.submitError'));
			// 			this.loadingExcel = false;
			// 			this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
			// 				type: 'warning'
			// 				//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			// 			});
			// 		});
			// },

			//上传回调
			// changeFileUrl(val) {
			// 	console.log('changeFileUrl', val);
			// 	let listUrl = val.map(item => {
			// 		// "filePath":"","fileName"：“”
			// 		return {
			// 			url: item.url,
			// 			fileName: item.name
			// 		};
			// 	});

			// 	this.form.attachments = listUrl;
			// },

			//提交信息
			submitForm(formName) {
				// this.$nextTick(() => {
				// 	try {
				// 		this.$refs.HyElWhUpLoad.submitUpload();
				// 	} catch (err) {
				// 		console.log(err);
				// 	}
				// });
				this.$nextTick(() => {
					this.form.transferOrders = this.$refs.HotTable.getData();
					// console.log('formBase', this.formBase);
					console.log('form', this.form);

					this.$refs[formName].validate(valid => {
						if (valid) {
							let formData = Object.assign({}, this.form);
							formData.whNo = this.form.whNo;
							formData.transferOrders = this.form.transferOrders;
							//浅拷贝、对象属性的合并
							// if (0 === this.dialogFormStatus) {
							// formData.id = null;
							// formData.userId = this.UserInfo.id;

							this.postData(this.$urlConfig.WhShipmentsOrderBatchUpdate, formData, () => {
								this.$emit('submitSuccess');
							});
							// } else {
							// 	formData.userId = this.UserInfo.id;
							// 	this.postData(this.$urlConfig.WhWorkOrderAdd, formData);
							// }
						} else {
							console.log('error submit!!');
							this.$alert(this.$t('tips.checkSubmitData'), this.$t('tips.tipsTitle'), {
								type: 'warning'
								//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
							});
							return false;
						}
					});
				});
			},
			//重置输入框
			// resetForm(formName) {
			// 	console.log(formName);
			// 	// console.log(this.$refs[formName]);

			// 	this.$nextTick(() => {
			// 		if (this.$refs[formName]) {
			// 			this.$refs[formName].resetFields();
			// 			// if ('form' === formName) {
			// 			// 	this[formName]['packRecordList'] = [];
			// 			// }
			// 		} else {
			// 			console.log('this.$refs[formName]', this.$refs[formName]);
			// 		}
			// 	});
			// 	this.$nextTick(() => {
			// 		try {
			// 			this.$refs.HyElWhUpLoad.initData();
			// 			this.$refs.HyElWhUpLoad.clearFileList();
			// 		} catch (err) {
			// 			console.log(err);
			// 		}
			// 	});
			// 	this.fileList = [];
			// },

			//提交信息
			postData(url, formData, callback) {
				// HttpTypelet _this = this;
				this.loading = true;
				// this.loading_load = true;
				let HttpType = {};
				// if ('delete' == type) {
				// 	HttpType = this.$http.delete(url, formData);
				// } else {
				HttpType = this.$http.put(url, formData);
				// }
				HttpType.then(({
					data
				}) => {
					console.log(this.$t('i18nn_bc868e024b80d2e3'));
					console.log(data);
					
					this.loading = false;
					// this.loading_load = false;
					if (200 == data.code) {
						// this.dialogFormVisible = false;
						// this.getPageData();

						// if ('delete' == type) {
						// 	this.$message({
						// 		type: 'success',
						// 		message: this.$t('tips.successfullyDelete')
						// 	});
						// } else {
						this.$alert(this.$t('tips.submitSuccess'), this.$t('tips.tipsTitle'), {
							type: 'success'
						});
						callback();
						// }
					} else {
						// if (!data.msg) {
						//   data.msg = this.$t('tips.submitError');
						// }
						this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
							type: 'warning'
							//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
						});
					}
				}).catch(error => {
					console.log(error);
					console.log(this.$t('tips.submitError'));
					this.loading = false;
					// this.loading_load = false;
					this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
						type: 'warning'
						//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
					});
				});
			},
			
			//清空地址
			clearAddrCode() {
				this.form.toWhNo = "";
				this.form.trAddrInfo = "";
			},
			//打开地址选择
			openSelAddr(index) {
				// this.TransportOpenIndex = index;
				this.TransportAddressOpenTime = new Date().getTime();
			},
			addrSelSuccess(data) {
				this.form.toWhNo = data.code;
				this.form.trAddrInfo = [data.address, data.city, data.state, data.country, data.zipCode].join(',');
			},
			
			//查询FBA地址
			searchFbaAddress() {
				// console.log('searchFbaAddress');
				if ('' == this.form.toWhNo) {
					this.$message.warning("请输入FBA仓库号");
					return;
				}
				this.form.trAddrInfo = "";
				this.loading = true;
				this.$http
					.get(this.$urlConfig.WhTransferQueryFba + '?fbaCode=' + this.form.toWhNo, {})
					.then(({
						data
					}) => {
						// console.log(this.$t('i18nn_776496ec446f21f9'));
						// console.log(data);
						this.loading = false;
						if (200 == data.code && data.data) {
							let addrData = data.data;
							// this.form.country = addrData.country;
							// this.form.state = addrData.state;
							// this.form.city = addrData.city;
							// this.form.postalCode = addrData.zipCode;
							// this.form.addr = addrData.address;
							// this.form.phone = addrData.phone;
							
							// this.form.trAddrInfo = [addrData.address,addrData.phone, addrData.city, addrData.state, addrData.country, addrData.zipCode].join(',');
							this.form.trAddrInfo = addrData.name;
						}
					})
					.catch(error => {
						console.log(error);
						console.log(this.$t('tips.errorData'));
						this.loading = false;
					});
			},

			//查询数据字典
			// getDicData() {
			// 	// let _this = this;
			// 	// console.log(keyword);
			// 	// this.loading_load = true;
			// 	// 	this.$http
			// 	// 		.put(this.$urlConfig.HyDicQueryList, ['wh_work_order_type'])
			// 	// 		.then(({ data }) => {
			// 	// 			console.log('查询数据字典，请求成功');
			// 	// 			console.log(data);
			// 	// 			if (200 == data.code && data.data) {
			// 	// 				// this.selectOption.wh_weight_unit = data.data['wh_weight_unit'];
			// 	// 				// this.selectOption.wh_vol_unit = data.data['wh_vol_unit'];
			// 	// 				// this.selectOption.wh_goods_fee_type = data.data['wh_goods_fee_type'];
			// 	// 				this.selectOption.wh_work_order_type = data.data['wh_work_order_type'];
			// 	// 				// this.selectOption.wh_packing_type = data.data['wh_packing_type'];
			// 	// 			} else {
			// 	// 				if (!data.msg) {
			// 	// 					data.msg = this.$t('tips.submitError');
			// 	// 				}
			// 	// 				this.$message.warning(data.msg);
			// 	// 			}
			// 	// 		})
			// 	// 		.catch(error => {
			// 	// 			console.log(error);
			// 	// 			console.log('查询数据字典接口，请求失败');
			// 	// 			this.$message.error(this.$t('tips.submitRequestError'));
			// 	// 		});
			// },
			//查询数据字典
			// getDicData() {
			// 	// let _this = this;
			// 	// console.log(keyword);

			// 	// this.loading_load = true;
			// 	this.$http
			// 		.put(this.$urlConfig.HyDicQueryList, ['wh_car_tms_type', 'wh_size_unit'])
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log('查询数据字典，请求成功');
			// 			console.log(data);
			// 			if (200 == data.code && data.data) {
			// 				// this.selectOption.wh_weight_unit = data.data['wh_weight_unit'];
			// 				// this.selectOption.wh_vol_unit = data.data['wh_vol_unit'];
			// 				// this.selectOption.wh_goods_fee_type = data.data['wh_goods_fee_type'];
			// 				this.selectOption.wh_size_unit = data.data['wh_size_unit'];
			// 				this.selectOption.wh_car_tms_type = data.data['wh_car_tms_type'];
			// 				// this.selectOption.wh_no = data.data['wh_no'];
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = this.$t('tips.submitError');
			// 				}
			// 				this.$message.warning(data.msg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('查询数据字典接口，请求失败');
			// 			this.$message.error(this.$t('tips.submitRequestError'));
			// 		});
			// }
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less"></style>
