<template>
	<div class="mainTem" :style="{'height':$store.state.frameConHeight1+'px','overflow':'auto'}">
		<el-form ref="form" inline :disabled="hasOnlyView" :rules="formRules" :model="form" label-width="100px"
			style="min-width: 1100px;width: 100%;" v-loading="loading">
			<el-card>
				<div slot="header">
					<!-- form.status:{{form.status}} -->
					<!-- <h3>{{$t('i18nn_d7aef993e73ebf60')}}</h3> -->
					<h3>
						<span>{{$t('i18nn_d7aef993e73ebf60')}}</span>
						<span class="red">{{form.sysDefOrderNum}}</span>
						<el-tag type="primary" effect="dark" size="small" v-if="hasCopy">{{$t('i18nn_29f67f0644a78bf3')}}</el-tag>
					</h3>
				</div>
				<div>
					<el-form-item :label="$t('i18nn_a0f2924544ff3ad8')" required>
						<whNoSelect ref="whNoSelect" @changeData="changWhNo" size="" :width="217"></whNoSelect>
					</el-form-item>

					<el-form-item :label="$t('i18nn_3601b5a98803f4d7')" prop="" required>
						<el-select filterable clearable v-model="form.isSelfLabel" :placeholder="$t('i18nn_2ad108ab2c560530')"
							style="width: 217px;">
							<el-option v-for="item in selectOption.biz_wh_is_shelf_label" :key="item.code"
								:label="$Utils.i18nCodeText(item)" :value="item.code"></el-option>
						</el-select>
					</el-form-item>

					<el-form-item :label="$t('i18nn_abf9f4b8c93d641e')" prop="" required>
						<el-input type="text" clearable v-model.trim="form.orderNumber" :placeholder="$t('FormMsg.Please_Enter')">
						</el-input>
					</el-form-item>

					<el-form-item prop="">
						<span slot="label">
							<span>{{$t('i18nn_7a29cab1a4b8f231')}}</span>
						</span>
						<el-switch v-model="form.isPriority" :active-value="'1'" :inactive-value="'0'"
							:active-text="$t('i18nn_02ccd2cf723f9272')" :inactive-text="$t('i18nn_e76d885ae1c74d4f')">
						</el-switch>
					</el-form-item>
					<!-- <el-form-item :label="$t('i18nn_b19f7906dab47f9c')" prop="" required>
						<el-select filterable clearable v-model="form.carTmsType" :placeholder="$t('FormMsg.Please_select')"
							size="" style="width: 200px;">
							<el-option v-for="item in selectOption.wh_car_tms_type" :key="item.code" :label="$Utils.i18nCodeText(item)"
								:value="item.code"></el-option>
						</el-select>
					</el-form-item> -->

					<!-- <el-form-item :label="$t('Storage.tableColumn.remark')">
						<el-input type="textarea" :placeholder="$t('FormMsg.Please_Enter')" v-model="form.remark"
							maxlength="" show-word-limit style="width: 800px"></el-input>
					</el-form-item> -->
				</div>
			</el-card>
			<el-card v-if="'20'==form.isSelfLabel">
				<div slot="header">
					<!-- form.status:{{form.status}} -->
					<h3>{{$t('i18nn_4851293ecdd539ed')}}</h3>
				</div>
				<div>
					<div>
						<el-form-item :label="'智能填写'" prop="">
							<longUsAddressParse :openTime="addrParseOpenTime" @parse="matchAddress" :type="2"></longUsAddressParse>
						</el-form-item>
					</div>
					
					<el-form-item :label="$t('i18nn_e44e9a291d80c593')" prop="" required>
						<el-input type="text" clearable v-model="form.name" :placeholder="$t('FormMsg.Please_Enter')">
						</el-input>
					</el-form-item>
					<el-form-item :label="$t('i18nn_1df68c34677c2aae')" prop="">
						<el-input type="text" clearable v-model="form.company" :placeholder="$t('FormMsg.Please_Enter')">
						</el-input>
					</el-form-item>
					<el-form-item :label="$t('i18nn_c2d9cc88561f8cdc')" prop="" required>
						<el-input type="text" clearable v-model="form.phone" :placeholder="$t('FormMsg.Please_Enter')">
						</el-input>
					</el-form-item>
					<el-form-item :label="$t('i18nn_8b9b30a5bc0eab3a')" prop="">
						<el-input type="text" clearable v-model.trim="form.email" :placeholder="$t('FormMsg.Please_Enter')">
						</el-input>
					</el-form-item>
					<el-form-item :label="$t('i18nn_8db45b615b72b7a8')" prop="" required>
						<el-input type="text" clearable v-model="form.street1" :placeholder="$t('FormMsg.Please_Enter')">
						</el-input>
					</el-form-item>
					<el-form-item :label="$t('i18nn_3e429a7c7cf3cab2')" prop="">
						<el-input type="text" clearable v-model="form.street2" :placeholder="$t('FormMsg.Please_Enter')">
						</el-input>
					</el-form-item>
					<el-form-item :label="$t('i18nn_e05b5d049b64b040')" prop="" required>
						<el-input type="text" clearable v-model="form.city" :placeholder="$t('FormMsg.Please_Enter')">
						</el-input>
					</el-form-item>
					<el-form-item :label="$t('i18nn_10c015e3241e842e')" prop="" required>
						<el-input type="text" clearable v-model.trim="form.state" :placeholder="$t('i18nn_8b66deca86e6d66d')" style="width: 217px;">
							<el-button slot="append" type="text" size="mini" @click="openProvStateRefer()">州简称参考</el-button>
						</el-input>
					</el-form-item>
					<el-form-item :label="$t('i18nn_c4913ab43009b365')" prop="" required>
						<el-input type="text" clearable v-model.trim="form.postalCode" :placeholder="$t('FormMsg.Please_Enter')">
						</el-input>
					</el-form-item>
					<el-form-item :label="$t('i18nn_b166e4e8fe9513fa')" prop="" required>
						<el-input type="text" clearable v-model.trim="form.country" :placeholder="$t('FormMsg.Please_Enter')">
						</el-input>
					</el-form-item>
				</div>
			</el-card>
			<el-card>
				<div slot="header" class="">
					<h3>SKU</h3>
				</div>
				<div>
					<div style="margin-bottom: 10px;">
						<el-button type="primary" size="small" icon="el-icon-plus"
							@click="addRow">{{$t('i18nn_24c8f46012a25c89')}}</el-button>
						<el-button type="danger" size="small" icon="el-icon-delete"
							@click="clearRow">{{$t('i18nn_4c4e546aa3683ff5')}}</el-button>
					</div>

					<div>

						<el-table border ref="expressSkuList" :data="form.expressSkuList" size="small" style=" width: 1100px;">
							<el-table-column type="index" fixed="left" width="50" align="center"
								:label="$t('Storage.tableColumn.no')"></el-table-column>

							<el-table-column prop="goodsSku" label="SKU" min-width="200" label-class-name="red-required-table">
								<template slot-scope="scope">
									<!-- <el-input size="mini" v-model="scope.row.goodsSku" maxlength=""></el-input> -->
									{{scope.row.goodsSku}}
								</template>
							</el-table-column>
							<el-table-column prop="quantity" :label="$t('i18nn_f9db93b87e08763b')" min-width="160"
								label-class-name="red-required-table">
								<template slot-scope="scope">
									<!-- <el-input-number size="mini" v-model="scope.row.quantity" maxlength=""></el-input-number> -->
									{{scope.row.quantity}}
								</template>
							</el-table-column>
							<el-table-column :label="$t('Storage.tableColumn.operation')" min-width="150" fixed="right">
								<template slot-scope="scope">
									<div>
										<el-button @click="delRow($event, scope.row,scope.$index)" type="danger" size="mini"
											icon="el-icon-minus">{{ $t('FormMsg.Delete') }}</el-button>
									</div>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</div>
			</el-card>
			<el-card>
				<div slot="header">
					<!-- form.status:{{form.status}} -->
					<h3><span>{{$t('i18nn_388b46f93fbfbf4d')}}</span>(tracking Info)Self provided label is required</h3>
				</div>
				<div>
					<el-form-item :label="$t('i18nn_245a46426329f070')" prop="" :required="'10'==form.isSelfLabel">
						<el-input type="text" clearable v-model.trim="form.tracking" :placeholder="$t('FormMsg.Please_Enter')">
						</el-input>
					</el-form-item>
					<el-form-item :label="''" prop="" :required="'10'==form.isSelfLabel">
						<template slot="label">
							<span>{{$t('i18nn_0f85a73e30bce3c5')}}</span>
						</template>
						<!-- <el-input type="text" clearable v-model.trim="form.carrier" :placeholder="$t('FormMsg.Please_Enter')">
						</el-input> -->
						<!-- <el-autocomplete v-model="form.carrier" clearable :fetch-suggestions="querySearchCarrier"
							:placeholder="$t('FormMsg.Please_Enter')" @select="handleSelectCarrier"></el-autocomplete> -->
						<el-select filterable clearable v-model="form.carrier" :placeholder="$t('i18nn_2ad108ab2c560530')"
							style="width: 217px;">
							<el-option v-for="item in selectOption.wh_carrier_code" :key="item.code"
								:label="$Utils.i18nCodeText(item)" :value="item.code"></el-option>
						</el-select>
						
					</el-form-item>
				</div>
			</el-card>
			<el-card>
				<div slot="header">
					<h3><span>{{$t('i18nn_b2b1ac4f554665b1')}}</span>(<span>{{$t('i18nn_0ffca7433738453f')}}</span>)No need to
						fill in the self provided label</h3>
				</div>
				<div>
					<el-form-item :label="$t('i18nn_7b16a04efec1728b')" prop="">
						<el-input type="text" clearable v-model.trim="form.insuranceValue" :placeholder="'USD'">
						</el-input>
					</el-form-item>
					<el-form-item :label="$t('i18nn_488e817afcd3b95e')" prop="">
						<!-- <el-input type="text" v-model="form.signatureService" :placeholder="$t('FormMsg.Please_Enter')">
						</el-input> -->

						<el-select filterable clearable v-model="form.signatureService" :placeholder="$t('i18nn_2ad108ab2c560530')"
							style="width: 217px;">
							<el-option v-for="item in selectOption.wh_signature_service" :key="item.code"
								:label="$Utils.i18nCodeText(item)" :value="item.code"></el-option>
						</el-select>
					</el-form-item>
				</div>
			</el-card>
			<!-- <el-card>
				<div slot="header">
					<h3><span>{{$t('i18nn_3e8b33480ff7e0f7')}}</span><span>(CM/KG)</span></h3>
				</div>
				<div>
					<el-form-item :label="$t('i18nn_eb8af1b7e16e2069')" prop="" required>
						<el-input-number size="" v-model="form.ctnLength" controls-position="right" style="width: 200px;">
						</el-input-number>
					</el-form-item>
					<el-form-item :label="$t('i18nn_64e6eb209a8a2cff')" prop="" required>
						<el-input-number size="" v-model="form.ctnWidth" controls-position="right" style="width: 200px;">
						</el-input-number>
					</el-form-item>
					<el-form-item :label="$t('i18nn_2a02dbcab12c9ee8')" prop="" required>
						<el-input-number size="" v-model="form.ctnHeight" controls-position="right" style="width: 200px;">
						</el-input-number>
					</el-form-item>
					<el-form-item :label="$t('i18nn_55ed30102ca735c1')" prop="" required>
						<el-input-number size="" v-model="form.ctnWeight" controls-position="right" style="width: 200px;">
						</el-input-number>
					</el-form-item>
				</div>
			</el-card> -->
			<!-- <el-card>
				<div slot="header">
					<h3>FBA</h3>
				</div>
				<div>
					<el-form-item :label="'FBA仓库'" prop="">
						<el-input type="text" v-model="form.toWhNo" :placeholder="$t('FormMsg.Please_Enter')">
						</el-input>
					</el-form-item>
					<el-form-item :label="'FBA NO.'" prop="">
						<el-input type="text" v-model="form.fbaNo" :placeholder="$t('FormMsg.Please_Enter')"></el-input>
					</el-form-item>
				</div>
			</el-card> -->
			<!-- <el-card>
				<div slot="header">
					<h3>{{$t('i18nn_97e49d00bcc4c17c')}}</h3>
				</div>
				<div>
					<el-form-item :label="$t('i18nn_fa6ae0aebfee7efa')" prop="">
						<el-input type="text" v-model="form.shipmentCode" :placeholder="$t('FormMsg.Please_Enter')">
						</el-input>
					</el-form-item>
					<el-form-item :label="$t('i18nn_32df9cb3d1681477')" prop="">
						<el-input type="text" v-model="form.markNo" :placeholder="$t('FormMsg.Please_Enter')">
						</el-input>
					</el-form-item>
				</div>
			</el-card> -->
			<!-- <el-card>
				<div slot="header">
					<h3>{{$t('i18nn_e366dc49c78c8e3d')}}</h3>
				</div>
				<div>
					<el-form-item :label="$t('i18nn_b166e4e8fe9513fa')" prop="">
						<el-input type="text" v-model="form.country" :placeholder="$t('FormMsg.Please_Enter')">
						</el-input>
					</el-form-item>
					<el-form-item :label="'州/省'" prop="">
						<el-input type="text" v-model="form.state" :placeholder="$t('FormMsg.Please_Enter')">
						</el-input>
					</el-form-item>
					<el-form-item :label="$t('i18nn_e05b5d049b64b040')" prop="">
						<el-input type="text" v-model="form.city" :placeholder="$t('FormMsg.Please_Enter')">
						</el-input>
					</el-form-item>
					<el-form-item :label="$t('i18nn_c4913ab43009b365')" prop="">
						<el-input type="text" v-model="form.postalCode" :placeholder="$t('FormMsg.Please_Enter')">
						</el-input>
					</el-form-item>
					<el-form-item :label="$t('i18nn_8758fd50c87d6c9c')" prop="">
						<el-input type="text" v-model="form.addr" :placeholder="$t('FormMsg.Please_Enter')">
						</el-input>
					</el-form-item>
					<el-form-item :label="$t('i18nn_2d13b6f878f30be3')" prop="">
						<el-input type="text" v-model="form.phone" :placeholder="$t('FormMsg.Please_Enter')">
						</el-input>
					</el-form-item>
					<el-form-item :label="$t('i18nn_e44e9a291d80c593')" prop="">
						<el-input type="text" v-model="form.name" :placeholder="$t('FormMsg.Please_Enter')">
						</el-input>
					</el-form-item>
				</div>
			</el-card> -->
			<!-- <el-form-item :label="$t('i18nn_3e358d140504ab46')" prop="detail" class="">
						<HyQuillEditor ref="myTextEditor" :value="form.contentStr" @editorChange="onEditorChange" class=""></HyQuillEditor>
					</el-form-item> -->



			<el-card>
				<div slot="header">
					<h3>{{$t('i18nn_15b3627faddccb1d')}}</h3>
				</div>
				<div>
					<el-form-item :label="$t('Storage.tableColumn.remark')">
						<el-input type="textarea" :placeholder="$t('FormMsg.Please_Enter')" v-model="form.remark"  :maxlength="1000" show-word-limit  style="width: 800px"></el-input>
					</el-form-item>
				</div>
			</el-card>

			<el-card>
				<div slot="header">
					<h3>{{$t('i18nn_f5d43732e3f6cf4d')}}</h3>
				</div>
				<div v-loading="loadingUploadFile">
					<!-- <el-form-item :label="$t('i18nn_f5d43732e3f6cf4d')" prop=""> -->
					<el-button type="primary" size="small" icon="el-icon-paperclip"
						@click="openAddFile()">{{$t('i18nn_ad36057ff6a7ce6b')}}</el-button>

					<ul>
						<li v-for="(item,index) in form.attachments" :key="index">
							<el-image style="width: 50px; height: 50px;vertical-align: middle;" :z-index="9999" :fit="'contain'"
								:src="item.url" :preview-src-list="form.attachments.map(itemPre=> { return itemPre.url})">
								<div slot="error" class="image-slot">
									<i class="el-icon-document"></i>
								</div>
							</el-image>
							<a :href="item.url" :title="item.url" target="_blank">{{ item.fileName }}</a>

							<!-- <el-button @click="delFile($event, item)" type="text" size="mini" icon="el-icon-delete"
								style="padding: 0 10px;">{{$t('i18nn_e33c9b93c36fd250')}}</el-button> -->

							<el-button @click="delFileAction($event, item,index)" type="text" size="mini" icon="el-icon-delete"
								style="padding: 0 10px;" v-if="item.id">{{$t('i18nn_e33c9b93c36fd250')}}</el-button>
							<el-button @click="delFile($event, index)" type="text" size="mini" icon="el-icon-delete"
								style="padding: 0 10px;" v-else>{{$t('i18nn_e33c9b93c36fd250')}}</el-button>

						</li>
					</ul>
					<!-- </el-form-item> -->
				</div>
			</el-card>

		</el-form>
		<div v-if="!hasOnlyView" class="submit-footer">
			<!-- <div  class="submit-footer" v-if="hasEdit"> -->
			<el-button type="warning" icon="el-icon-tickets" @click="submitForm('form')"
				style="width: 200px;">{{$t('i18nn_e186babc2e9cadf4')}}</el-button>
			<!-- </div> -->
			<!-- <div  class="submit-footer" v-else>
				<el-button type="warning" @click="submitForm('form')" style="width: 200px;">{{$t('i18nn_e186babc2e9cadf4')}}</el-button>
			</div> -->
		</div>
		<!-- 选择已有库存的SKU -->
		<dialogWSkuInventoriesChange :openTime="skuInvOpenTime" :whNo="form.senderAddr" @sure="sureSel"
			@changeNum="changeInvNum"></dialogWSkuInventoriesChange>

		<!-- 附件 -->
		<dialogFileUpload ref="dialogFileUpload" :folder="'storage/SingleAddAttaFile/'" :fileKey="'dropShipping'"
			:openTime="FileUploadOpenTime" @success="FileUploadSuccess"></dialogFileUpload>
		
		<!-- 州参考 -->
		<dialogProvStateRefer :openTime="ProvStateRefOpenTime"></dialogProvStateRefer>
		
	</div>
</template>
<script>
	import {
		deepClone
	} from '@/utils/common.js';
	import {
		getDicData
	} from '@/axios/common.js';
	// import HyQuillEditor from '@/components/Common/HyQuillEditor.vue';
	import dialogWSkuInventoriesChange from '@/components/StorageCenter/WSkuProduct/dialogWSkuInventoriesChange.vue';
	import whNoSelect from '@/components/StorageCenter/components/whNoSelect.vue';
	import dialogFileUpload from '@/components/StorageCenter/components/dialogFileUpload.vue';
	
	import longUsAddressParse from '@/components/StorageCenter/components/longUsAddressParse.vue';
	
	import dialogProvStateRefer from '@/components/StorageCenter/components/dialogProvStateRefer.vue';
	
	export default {
		// name: 'BuyerOrder',
		//meta信息seo用
		// metaInfo: {
		//   title: '互易天下-厂家共享平台-买家中心-收货账单', // set a title
		// },

		// props: {
		//   // mobile:"",
		//   isSel: {
		//     default: function() {
		//       return false;
		//     },
		//     type: Boolean
		//   },
		//   status: {
		//     default: function() {
		//       return '';
		//     },
		//     type: String
		//   }
		// },
		components: {
			whNoSelect,
			dialogWSkuInventoriesChange,
			dialogFileUpload,
			longUsAddressParse,
			dialogProvStateRefer
			// HyQuillEditor
		},
		data() {
			return {
				// UserInfo: this.$store.getters.getUserInfo,
				// dialogFormVisible: false,
				dialogFormStatus: 0, //0-新增，1-修改

				loading: false,

				loading_det: false,
				//是否可编辑
				hasEdit: false,
				//是否仅预览
				hasOnlyView: false,
				//是否复制数据
				hasCopy: false,
				
				//州二字码参考
				ProvStateRefOpenTime:'',
				
				//附件
				// fileWatchKey: '1',
				// fileList: [],
				// fileId: this.$store.getters.getUserInfo.id,
				skuInvOpenTime: '',
				//附件
				FileUploadOpenTime: '',

				loadingUploadFile: false,
				
				addrParseOpenTime:"",

				// skuInvWhNo:'',
				form: {
					// id: '', //"数据ID",
					senderAddr: '',
					isSelfLabel: "",
					orderNumber: "",
					isPriority: '0',
					// goodsSku: "",
					// sendAcount: undefined,
					// sendCTN: undefined,
					// ctnPCS: undefined,
					name: "",
					company: "",
					phone: "",
					email: "",
					street1: "",
					street2: "",

					city: "",
					state: "",
					postalCode: "",
					country: "US",

					tracking: "",
					carrier: "",
					insuranceValue: "",
					signatureService: "",
					// name: "",
					remark: "",
					expressSkuList: [
						// {
						// 	goodsSku: "",
						// 	quantity: undefined,
						// }
					],
					attachments: [],
				},

				formRules: {
					// title: [{
					// 	required: true,
					// 	message: this.$t('FormMsg.Please_Enter'),
					// 	trigger: 'blur'
					// }],
					// contentStr: [{
					// 	required: true,
					// 	message: this.$t('FormMsg.Please_Enter'),
					// 	trigger: 'blur'
					// }],
					// orderType: [{
					// 	required: true,
					// 	message: this.$t('FormMsg.Please_Enter'),
					// 	trigger: 'change'
					// }]
				},
				// loading_load: false,



				selectOption: {
					wh_signature_service: [],
					biz_wh_is_shelf_label: [],
					wh_carrier_code:[],
				}
			};
		},

		//创建时
		created() {
			// this.getPageData();
			this.initData();

		},
		//编译挂载前
		mounted() {
			// this.getDicData();
			//数据字典
			getDicData(['wh_signature_service', 'biz_wh_is_shelf_label','wh_carrier_code'],
				(data)=>{
					this.selectOption.wh_signature_service = data['wh_signature_service'];
					this.selectOption.biz_wh_is_shelf_label = data['biz_wh_is_shelf_label'];
					this.selectOption.wh_carrier_code = data['wh_carrier_code'];
			});

		},
		methods: {
			initData() {
				this.hasCopy = false;
				this.hasEdit = false;
				this.resetForm('form');
				
				if (this.$route.query.id) {
					//编辑
					// this.hasEdit = true;
					if('1'==this.$route.query.copy){
						this.hasCopy = true;
					} else {
						//编辑
						this.hasEdit = true;
					}
					this.getPageDetData(this.$route.query.id);
				} else {
					//新增
					this.hasEdit = false;
				}
			},
			changWhNo(data) {
				console.log('changWhNo', data);
				this.form.senderAddr = data.code;
				// this.filterData.userId = data.userId;
				// this.initData();
				this.form.expressSkuList = [];
			},
			//渠道参考
			// querySearchCarrier(queryString, cb) {
			// 	var restaurants = [{
			// 		"value": "Fedex",
			// 		"label": "Fedex"
			// 	}, {
			// 		"value": "UPS",
			// 		"label": "UPS"
			// 	}, {
			// 		"value": "USPS",
			// 		"label": "USPS"
			// 	}, {
			// 		"value": "DHL",
			// 		"label": "DHL"
			// 	}];
			// 	var results = queryString ? restaurants.filter(this.createFilterCarrier(queryString)) : restaurants;
			// 	// 调用 callback 返回建议列表的数据
			// 	cb(results);
			// },
			// createFilterCarrier(queryString) {
			// 	return (restaurant) => {
			// 		return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
			// 	};
			// },
			// handleSelectCarrier(item) {
			// 	console.log(item);
			// },
			// 新建-表格
			addRow() {
				if (!this.form.senderAddr) {
					this.$message.warning(this.$t('FormMsg.Select_long_wh'))
					return;
				}
				// this.skuInvWhNo = this.form.senderAddr;
				this.skuInvOpenTime = new Date().getTime();
			},
			// selSkuInv(val){
			// 	val.forEach(item=>{
			// 		let hasSku = this.form.expressSkuList.some(item2=> item.goodsSku == item2.goodsSku);
			// 		//是否已经存在此SKU
			// 		if(!hasSku){//不存在
			// 			this.form.expressSkuList.push({
			// 				goodsSku: item.goodsSku,
			// 				quantity: undefined,
			// 			});
			// 		}
			// 	})
			// },
			sureSel(val) {
				val.forEach(item => {
					// let hasSku = this.form.expressSkuList.some(item2=> item.goodsSku == item2.goodsSku);
					//是否已经存在此SKU
					// if(!hasSku){//不存在
					this.form.expressSkuList.push({
						goodsSku: item.goodsSku,
						quantity: undefined,
					});
					// }
				})
			},
			changeInvNum(list) {

				//新增
				let HasRepeat = false; //是否存在重复
				let new_recordList = list.filter(item => {
					return item.outQuantity > 0;
				});
				console.log('new_recordList', new_recordList);
				// let new_recordList_ids = new_recordList.map(item=>{return item.id})
				let old_recordList = deepClone(this.form.expressSkuList);

				new_recordList.map(item => {
					let hasIndex = old_recordList.findIndex(item2 => {
						return item2.goodsSku == item.goodsSku;
					});
					if (hasIndex > -1) { //存在
						let newObj = this.form.expressSkuList[hasIndex];
						newObj.quantity = item.outQuantity;
						this.$set(this.form.expressSkuList, hasIndex, newObj);
					} else { //不存在
						this.form.expressSkuList.push({
							// "ctnMark": item.ctnMark, //this.$t('i18nn_fa4d50bc26e39b26'),
							// "inWhCode": item.inWhCode, //this.$t('i18nn_559fed47b0e95407'),
							// "inWhRecord": item.id, //"入库明细ID",
							// "trCtnCount": item.trCtnCount, //this.$t('i18nn_bdc361ba04506136'),
							// "trStockSour": this.form.trStockSour, //货物来源
							"goodsSku": item.goodsSku,
							quantity: item.outQuantity,
							// "remark": null, //备注
							// "remark":item.remark,//备注
							// "trPallte": item.inWhPallte, //this.$t('i18nn_c8773d8d74202801')
						});
					}
				});

				// val.forEach(item=>{
				// 	// let hasSku = this.form.expressSkuList.some(item2=> item.goodsSku == item2.goodsSku);
				// 	//是否已经存在此SKU
				// 	// if(!hasSku){//不存在
				// 		this.form.expressSkuList.push({
				// 			goodsSku: item.goodsSku,
				// 			quantity: undefined,
				// 		});
				// 	// }
				// })
			},
			clearRow() {
				this.form.expressSkuList = [];
			},
			//删除-表格
			delRow(event, row, index) {
				this.form.expressSkuList.splice(index, 1);
			},

			//添加附件
			openAddFile() {
				// this.FileUploadOpenIndex = index;
				this.FileUploadOpenTime = new Date().getTime();
			},
			FileUploadSuccess(data) {
				console.log('FileUploadSuccess', data);
				let fileList = data.map(item => {
					return {
						fileName: item.fileName, //this.$t('i18nn_89221ad15d2ec113'),
						url: item.filePath, //this.$t('i18nn_8758fd50c87d6c9c')
						// type: "90"
					}
				});
				if (!this.form.attachments) {
					this.form.attachments = [];
				}
				this.form.attachments = this.form.attachments.concat(fileList);
				console.log('attachments', this.form.attachments);
				this.$forceUpdate();
			},
			//删除附件
			delFile(event, index) {
				event.stopPropagation();
				this.form.attachments.splice(index, 1);
				this.$forceUpdate();
			},
			//后端删除
			delFileAction(event, row, index) {
				event.stopPropagation();
				this.loadingUploadFile = true;
				this.$http
					.delete(this.$urlConfig.WhFileUploadDel + '/' + row.id, {})
					.then(({
						data
					}) => {
						this.loadingUploadFile = false;
						if (200 == data.code) {
							this.form.attachments.splice(index, 1);
							this.$forceUpdate();
							this.$message.success(this.$t('i18nn_de017dafc266aa03'));
						} else {
							this.$message.warning(data.msg ? data.msg : this.$t('i18nn_54dc58d9d0377bb5'));
						}
					})
					.catch(error => {
						console.log(error);
						this.loadingUploadFile = false;
						this.$message.warning(data.msg ? data.msg : '删除失败!');
					});
			},
			// changeAgentUser(val){
			// 	// this.filterData.agentUser = val;
			// 	// this.initData();
			// },
			//跳转页面
			// toPageUrl(name){
			//   this.$router.push({'name':name});
			// },
			//打开新增编辑，弹窗
			// openDioalog(formParm, msg) {
			// 	// console.log(formParm);
			// 	this.dialogFormVisible = true;
			// 	let form = Object.assign({}, formParm);
			// 	console.log('form', form);
			// 	this.dialogFormMsg = msg;
			// 	// // 重置
			// 	this.resetForm('form');
			// 	if (null === formParm) {
			// 		//新增

			// 		// this.form.weightUnit = '1';
			// 		// this.form.volumeUnit = '1';
			// 		// this.form.whFeeType = '1';

			// 		this.dialogFormStatus = 0;
			// 		// form.subUserId = null;
			// 		// form.userSubUserId = null;
			// 		// form.state = true;
			// 		//浅拷贝、对象属性的合并
			// 		this.form = form;
			// 	} else {
			// 		//修改
			// 		this.dialogFormStatus = 1;
			// 		// form.state = form.state === '0' ? true : false;

			// 		//浅拷贝、对象属性的合并
			// 		this.form = form;
			// 	}

			// 	this.$forceUpdate();
			// 	// this.$alert('确定要确认通过该笔账单！', this.$t('tips.tipsTitle'), {
			// 	//       type: 'warning',
			// 	//       //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			// 	//     });
			// },
			// 富文本
			// onEditorChange(html) {
			// 	this.form.contentStr = html;
			// },
			//智能匹配
			matchAddress(addrAll) {
				this.form.name = addrAll.recipient;
				this.form.company = "";
				this.form.phone = addrAll.phone;
				this.form.email = "";
				this.form.street1 = addrAll.street1;
				this.form.street2 = "";
				this.form.city = addrAll.city;
				this.form.state = addrAll.stateOrProvinceCode;
				this.form.postalCode = addrAll.postalCode;
				this.form.country = addrAll.countryCode;
				// this.form = {
				// 	"street1": addrAll.street1,
				// 	"street2": "",
				// 	"streetLines": [],
				// 	"city": addrAll.city,
				// 	"stateOrProvinceCode": addrAll.stateOrProvinceCode,
				// 	"postalCode": addrAll.postalCode,
				// 	"countryCode": addrAll.countryCode,
				// }
			},
			
			//州参考
			openProvStateRefer(){
				this.ProvStateRefOpenTime = new Date().getTime();
			},
			
			//打开编辑
			// openEdit(event, row, index) {
			// 	event.stopPropagation();
			// 	this.openDioalog(row, this.$t('FormMsg.Edit'));
			// 	this.getPageDetData(row.id);
			// },
			//明细
			//请求分页数据
			getPageDetData(id) {
				// let _this = this;
				this.loading = true;

				this.$http
					.get(this.$urlConfig.WhDropShippingDetById + '/' + id, {})
					.then(({
						data
					}) => {
						// console.log(this.$store.getters.getUserInfo);
						console.log('分页，请求成功');
						console.log(data);

						this.loading = false;
						//是否可编辑
						// if ('0' == data.data.status) {
						// 	this.hasEdit = true;
						// }

						//表格显示数据
						// this.form = data.data;
						let form = data.data;
						this.form = form;
						
						//复制数据
						if(this.hasCopy){
							// this.form.id = null;
							
							this.form = {
								// id: '', //"数据ID",
								senderAddr: form.senderAddr,
								isSelfLabel:form.isSelfLabel,
								orderNumber: form.orderNumber,
								// goodsSku: "",
								// sendAcount: undefined,
								// sendCTN: undefined,
								// ctnPCS: undefined,
								name: form.name,
								company: form.company,
								phone: form.phone,
								email: form.email,
								street1:form.street1,
								street2:form.street2,
								
								city: form.city,
								state: form.state,
								postalCode: form.postalCode,
								country: form.country,
								
								tracking:form.tracking,
								carrier:form.carrier,
								insuranceValue:form.insuranceValue,
								signatureService:form.signatureService,
								// name: "",
								remark: form.remark,
								expressSkuList:[],
								attachments: [],
							}
							if(form.expressSkuList && form.expressSkuList.length>0){
								this.form.expressSkuList = form.expressSkuList.map(item=>{
									item.id = null;
									return item;
								});
							}
							if(form.attachments && form.attachments.length>0){
								this.form.attachments = form.attachments.map(item=>{
									item.id = null;
									return item;
								});
							}
						}
						//是否仅仅预览
						// if (null != this.form.status && '' != this.form.status && '0' != this.form.status) {
						// 	this.hasOnlyView = true;
						// }

						this.$nextTick(() => {
							this.$refs.whNoSelect.init(this.form.senderAddr);
						})

						// this.fileList = data.data.attachments.map(item => {
						// 	return {
						// 		id: item.id,
						// 		name: item.fileName,
						// 		url: item.url
						// 	};
						// });
						// this.fileWatchKey = new Date().getTime();

					})
					.catch(error => {
						console.log(error);
						console.log('分页，请求失败');
						this.loading = false;
					});
			},

			//上传回调
			// changeFileUrl(val) {
			// 	console.log('changeFileUrl', val);
			// 	let listUrl = val.map(item => {
			// 		// "filePath":"","fileName"：“”
			// 		return {
			// 			url: item.url,
			// 			fileName: item.name
			// 		};
			// 	});

			// 	this.form.attachments = listUrl;
			// },

			//提交信息
			submitForm(formName) {
				// this.$nextTick(() => {
				// 	try {
				// 		this.$refs.HyElWhUpLoad.submitUpload();
				// 	} catch (err) {
				// 		console.log(err);
				// 	}
				// });

				this.$refs[formName].validate(valid => {
					if (valid) {
						let formData = Object.assign({}, this.form);

						console.log(formData.id);

						if (!formData.id) {
							// formData.id = null;
							// formData.userId = this.UserInfo.id;


							this.postData(this.$urlConfig.WhDropShippingSingleInsert, formData, () => {
								console.log('submitSuccess');
								this.$emit('submitSuccess');
							});
						} else {
							// formData.userId = this.UserInfo.id;
							this.postData(this.$urlConfig.WhDropShippingSingleEdit, formData, () => {
								this.$emit('submitSuccess');
							});
						}
					} else {
						console.log('error submit!!');
						this.$alert(this.$t('tips.checkSubmitData'), this.$t('tips.tipsTitle'), {
							type: 'warning'
							//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
						});
						return false;
					}
				});
			},
			//重置输入框
			resetForm(formName) {
				console.log(formName);
				// console.log(this.$refs[formName]);

				this.$nextTick(() => {
					if (this.$refs[formName]) {
						this.$refs[formName].resetFields();
						// if ('form' === formName) {
						// 	this[formName]['packRecordList'] = [];
						// }
					} else {
						console.log('this.$refs[formName]', this.$refs[formName]);
					}
				});
				
				//智能解析地址
				this.addrParseOpenTime  = new Date().getTime();
				
				// this.$nextTick(() => {
				// 	try {
				// 		this.$refs.HyElWhUpLoad.initData();
				// 		this.$refs.HyElWhUpLoad.clearFileList();
				// 	} catch (err) {
				// 		console.log(err);
				// 	}
				// });
				// this.fileList = [];
			},

			//提交信息
			postData(url, formData, callback) {
				// HttpTypelet _this = this;
				this.loading = true;
				// this.loading_load = true;
				let HttpType = {};
				// if ('delete' == type) {
				// 	HttpType = this.$http.delete(url, formData);
				// } else {
				HttpType = this.$http.put(url, formData);
				// }
				HttpType.then(({
					data
				}) => {
					console.log(this.$t('i18nn_bc868e024b80d2e3'));
					console.log(data);

					this.loading = false;
					// this.loading_load = false;
					if (200 == data.code) {
						// this.dialogFormVisible = false;
						// this.getPageData();

						// if ('delete' == type) {
						// 	this.$message({
						// 		type: 'success',
						// 		message: this.$t('tips.successfullyDelete')
						// 	});
						// } else {
						this.$alert(this.$t('tips.submitSuccess'), this.$t('tips.tipsTitle'), {
							type: 'success'
						});
						callback();
						// }
					} else {
						// if (!data.msg) {
						//   data.msg = this.$t('tips.submitError');
						// }
						this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
							type: 'warning'
							//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
						});
					}
				}).catch(error => {
					console.log(error);
					console.log(this.$t('tips.submitError'));
					this.loading = false;
					// this.loading_load = false;
					this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
						type: 'warning'
						//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
					});
				});
			},

			//查询数据字典
			// getDicData() {
			// 	// let _this = this;
			// 	// console.log(keyword);
			// 	this.loading_load = true;
			// 	this.$http
			// 		.put(this.$urlConfig.HyDicQueryList, ['wh_signature_service', 'biz_wh_is_shelf_label','wh_carrier_code'])
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log('查询数据字典，请求成功');
			// 			console.log(data);
			// 			if (200 == data.code && data.data) {
			// 				// this.selectOption.wh_weight_unit = data.data['wh_weight_unit'];
			// 				// this.selectOption.wh_vol_unit = data.data['wh_vol_unit'];
			// 				// this.selectOption.wh_goods_fee_type = data.data['wh_goods_fee_type'];
			// 				this.selectOption.wh_signature_service = data.data['wh_signature_service'];
			// 				this.selectOption.biz_wh_is_shelf_label = data.data['biz_wh_is_shelf_label'];
			// 				this.selectOption.wh_carrier_code = data.data['wh_carrier_code'];
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = this.$t('tips.submitError');
			// 				}
			// 				this.$message.warning(data.msg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('查询数据字典接口，请求失败');
			// 			this.$message.error(this.$t('tips.submitRequestError'));
			// 		});
			// }
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>